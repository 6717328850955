import * as React from "react";
import * as ReactDOM from "react-dom";

import { UIView } from "@uirouter/react";

export default class View extends React.Component {
  render() {
    return (
      <div id="wrapper" className="container MainContent view">
        <UIView></UIView>
      </div>
    );
  }
}
