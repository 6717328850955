import { $sce, $rootScope } from "ngimport";
import { $uibModal } from "Bootstrap/angular";

class WindowUtilities {
  /**
   * Opens a new popup window
   * @param url URL
   * @param name Name of popup window
   * @param width Width of window
   * @param height Height of window
   */
  openPopup = (
    url: string,
    name?: string,
    width?: number,
    height?: number
  ): void => {
    const popupWidth = width || 900;
    const popupHeight = height || 900;
    const popupName = name || "Popup";

    const opts = `width=${popupWidth},height=${popupHeight},top=0,toolbar=0,menubar=0,scrollbars=1,rel=noopener`;

    window.open(url, popupName, opts);
  };

  /**
   * Opens a new tab/window
   * @param url URL
   * @param width Width of window
   * @param height Height of window
   */
  openWindow = (url: string): void => {
    window.open(url, "_blank");
  };

  openIframe = (url: string): void => {
    const scope: any = $rootScope.$new();
    scope.src = $sce.trustAsResourceUrl(url);

    $uibModal.open({
      scope,
      windowClass: "sam-modal sam-modal-iframe",
      template: `
                <div class="modal-body" style="height:100%">
                    <div class="rightControls IframeModal-iframeClose">
                        <a type="button" class="rightControls-link close" ng-click="$dismiss();">&times;</a>
                    </div>
                    <i class="fa-li fa fa-circle-o-notch fa-spin"
                        style="font-size: 100px;  position: absolute; left: 37%; top: 44%;z-index:0;">
                    </i>
                    <iframe ng-src="{{src}}" style="width:100%;height:100%;z-index:1;position:relative;"></iframe>
                </div>`,
      size: "lg"
    });
  };

  /**
   * Finds the first element with the given name-attribute and sets focus
   * on that element.
   * @param  {string} elementName     A name attribute of the element that
   *                                  should receive focus.
   * @return {Boolean}                Returns true if the element was found
   *                                  (and received focus), otherwise false.
   */
  focusByName = (elementName: string): HTMLElement | undefined => {
    const elem = window.document.getElementsByName(elementName);
    if (elem != null && elem.length) {
      elem[0].focus();
      return elem[0];
    }
  };

  /**
   * Get current document window (viewport) size
   */
  getWindowSize = (): { width: number; height: number } => {
    const w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    const h = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );

    return {
      width: w,
      height: h
    };
  };

  /**
   * Check if an element is seen anywhere in the viewport.
   * @param  {string} element     Element to interact with
   */
  isElementSeenInTheViewport = (element: Element): boolean => {
    const bounding = element.getBoundingClientRect();
    return (
      bounding.top <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.left <=
        (window.innerWidth || document.documentElement.clientWidth) &&
      bounding.bottom >= 0 &&
      bounding.right >= 0
    );
  };
}

export default new WindowUtilities();
