import SamskipResource from "SamskipResource";

class SentryService {
  captureException = (ex: any): SamskipPromise<any> | undefined => {
    if (location.hostname !== "localhost") {
      if (!ex) throw new Error('"ex" must be set');
      return SamskipResource.post("AuthAPI", "sentry", "", {
        Message: ex.message,
        StackTrace: ex.stack
      });
    }
  };
}

export default new SentryService();
