import * as React from "react";
import { TextArea, Form } from "semantic-ui-react";
import { languages } from "Services";

export default class extends React.Component<any, any> {
  state = {
    currentLength: 0
  };

  componentDidUpdate = (prevProps: any) => {
    if (prevProps.value !== "" && this.props.value === "") {
      this.setState({ currentLength: 0 });
    }
  };

  render() {
    const { value, placeholder, onChange, maxLength } = this.props;
    const { currentLength } = this.state;
    return (
      <React.Fragment>
        <Form.Field
          control={TextArea}
          placeholder={placeholder}
          onChange={(e: any, data: any) => {
            onChange(data.value);
            this.setState({ currentLength: data.value.length });
          }}
          maxLength={maxLength}
          value={value === undefined ? "" : value}
        />
        <p>
          {maxLength - currentLength} {languages("LABEL_CHAR_LEFT")}
        </p>
      </React.Fragment>
    );
  }
}
