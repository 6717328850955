import { observable, action, toJS, computed } from "mobx";

class NotificationStore {
  private notificationUnreadCount = observable.box(0);
  @observable private notificationList: any = [];
  @observable private notificationLatest: any = {};

  @computed get notifications() {
    return this.notificationList;
  }

  @computed get latestNotifications() {
    return this.notificationLatest;
  }

  @computed get totalNotifications() {
    return this.notificationList.length;
  }

  @computed get unreadNotifications() {
    return this.notificationUnreadCount;
  }

  @action
  setNotifications = (notifications: any): void => {
    this.notificationList.replace(notifications);
  };

  @action
  setLatestNotifications = (latestNotifications: any): void => {
    this.notificationLatest = latestNotifications;
  };

  @action
  setUnreadCount = (unreadCount: number): void => {
    this.notificationUnreadCount.set(unreadCount);
  };

  @action
  markNotificationRead = (notificationID: number): void => {
    const newUnreadValue =
      this.notificationUnreadCount.get() <= 0
        ? 0
        : this.notificationUnreadCount.get() - 1;
    this.setUnreadCount(newUnreadValue);
    this.markNotificationReadInLists(notificationID);
  };

  private markNotificationReadInLists = (notificationID: number) => {
    const notificationListIndex = this.notificationList.findIndex(
      (n: any) => n.NotificationID === notificationID
    );

    if (notificationListIndex !== -1) {
      this.notificationList[notificationListIndex].ReadWeb = new Date();
    }

    if (this.notificationLatest.Notifications) {
      const latestNotificationListIndex = this.notificationLatest.Notifications.findIndex(
        (n: any) => n.NotificationID === notificationID
      );

      if (latestNotificationListIndex === -1) return;

      this.notificationLatest.Notifications[
        latestNotificationListIndex
      ].ReadWeb = new Date();
    }
  };
}

export default new NotificationStore();
