import * as React from "react";
import * as ReactDOM from "react-dom";

import styles from "./index.modules.less";
import Icon from "./icon.svg";
import Clear from "./clear.svg";

const onRemove = (labels: any, key: string) => {
  if (key) {
    return labels.filter((l: any) => l.key !== key);
  }

  return [];
};

const onAdd = (labels: any, value: any, key: string, text: string) => {
  const isDuplicate = labels.find((l: any) => l.key === key);
  const isEmpty = !(value && value.length !== 0);

  if (isEmpty) {
    return onRemove(labels, key);
  }

  if (isDuplicate) {
    return labels;
  }

  return [...labels, { key, text }];
};

export default (props: LabelsProps) => (
  <div className={styles.labels}>
    <span>{props.title}</span>

    <span>
      {props.data.map(label => (
        <span className={styles.label} key={label.key}>
          <span>{label.text}</span>
          <i
            style={{ background: `url(${Icon}) no-repeat 0 0` }}
            onClick={() => props.onChange && props.onChange(label.key)}
          ></i>
        </span>
      ))}
    </span>

    {props.data.length ? (
      <a
        className={styles.clear}
        style={{ background: `url(${Clear}) no-repeat 0 0` }}
        onClick={() => props.onChange && props.onChange()}
      >
        <span>{props.clearText}</span>
      </a>
    ) : (
      ""
    )}
  </div>
);

export { onAdd, onRemove };
