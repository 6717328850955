import AngularModalUtilities from "Utilities/modals/AngularModalUtilities";

class ModalUtilities {
  /**
   * Show normal/medium modal with current 'componentName' and 'resolve'.
   * @param componentName     Name of the component
   * @param resolve           Resolve object for the component modal
   * @param options           Options
   */
  showModal = (
    componentName: string,
    resolve?: object,
    options?: ng.ui.bootstrap.IModalSettings
  ) => {
    return AngularModalUtilities.showDefaultModal(
      componentName,
      resolve,
      options
    ).promise;
  };

  /**
   * Show normal/medium modal with the instance and the promise.
   * @param componentName     Name of the component
   * @param resolve           Resolve object for the component modal
   * @param options           Options
   */
  showModalWithInstance = (
    componentName: string,
    resolve?: object,
    options?: ng.ui.bootstrap.IModalSettings
  ) => {
    return AngularModalUtilities.showDefaultModal(
      componentName,
      resolve,
      options
    );
  };

  /**
   * Create modal data with current 'componentName' and 'resolve'.
   * @param componentName     Name of the component
   * @param resolve           Resolve object for the component modal
   * @param options           Options
   */
  showLargeModal = (
    componentName: string,
    resolve?: object,
    options?: ng.ui.bootstrap.IModalSettings
  ) => {
    const modalData = AngularModalUtilities.createDefaultModalData(
      componentName,
      resolve,
      options
    );
    modalData.size = "lg";
    return AngularModalUtilities.showModal(modalData).promise;
  };

  showSimpleModal = (
    template: string,
    options?: ng.ui.bootstrap.IModalSettings
  ) => {
    return AngularModalUtilities.showSimpleModal(template, options).promise;
  };

  /**
   * Open extra large modal
   * @param componentName     Name of the component
   * @param resolve           Resolve object for the component modal
   * @param errorKey          Optional error message that will be displayed
   *                          if any error occurs.
   */
  showExtraLargeModal = (
    componentName: string,
    resolve?: object,
    options?: ng.ui.bootstrap.IModalSettings
  ) => {
    const modalData = AngularModalUtilities.createDefaultModalData(
      componentName,
      resolve,
      options
    );
    modalData.size = "xlg";
    return AngularModalUtilities.showModal(modalData).promise;
  };
}

export default new ModalUtilities();
