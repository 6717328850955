import * as React from "react";
import * as ReactDOM from "react-dom";

import { UIView } from "@uirouter/react";

import MasterPageView from "./MasterPageView";

export default class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="PopUp-header">
          <div className="PopUp-headerContainer">
            <a className="PopUp-link" href="/">
              <img
                className="PopUp-image u-floatLeft"
                src={require("../assets/svg/samskip-logo.svg")}
                alt="Forsíða"
              />
            </a>
          </div>
        </header>

        <div id="wrapper" className="PopUp-content">
          <div className="PopUp-contentContainer">
            <UIView></UIView>
          </div>
        </div>
      </div>
    );
  }
}
