import { TranslationService } from "Services";
import { default as toastify, toast } from "react-toastify";
import React from "react";
import ReactHtmlParser from "react-html-parser";

class SamskipNotify {
  private options: toastify.ToastOptions = {
    autoClose: 5000,
  };

  private displayToast = (
    type: string,
    message: string | JSX.Element,
    title?: string,
    customTarget?: string,
    additionalOptions?: toastify.ToastOptions
  ): void => {
    let displayedMessage = message;
    let displayedTitle = title;

    if (typeof title === "string" && title.indexOf("_") > -1) {
      displayedTitle = TranslationService.translate(title);
    }
    // Check if we're dealing with a translation key
    if (typeof message === "string" && message.indexOf("_") > -1) {
      displayedMessage = TranslationService.translate(message);
    }

    this.options = {
      ...this.options,
      ...additionalOptions,
    };

    const createContent = () => {
      const children = [
        React.createElement("span", undefined, displayedMessage),
      ];
      if (displayedTitle) {
        children.unshift(React.createElement("h2", undefined, displayedTitle));
      }
      return React.createElement("div", undefined, children);
    };

    // Call toastify with parameters
    toast(createContent(), {
      type: toast.TYPE[type],
      position: toast.POSITION.TOP_RIGHT,
      ...this.options,
    });
  };

  renderHTMLtoToast(title: {} | null | undefined, message: string) {
    const elem = React.createElement(
      "span",
      {},
      React.createElement("h2", { className: "dynamicToastHeader" }, title),
      React.createElement(
        "span",
        { className: "dynamicToastBody" },
        ReactHtmlParser(message)
      )
    );

    return elem;
  }

  dynamicInfoToastId = "dt-InfoId";
  dynamicWarningToastID = "dt-WarningId";

  displayDynamic = (
    type: string,
    message: string,
    title?: string,
    targetElem?: string,
    additionalOptions?: toastify.ToastOptions
  ): void => {
    let displayedMessage = message;
    let displayedTitle = title;
    const checkIDs = type;

    if (typeof title === "string" && title.indexOf("_") > -1) {
      displayedTitle = TranslationService.translate(title);
    }
    // Check if we're dealing with a translation key
    if (typeof message === "string" && message.indexOf("_") > -1) {
      displayedMessage = TranslationService.translate(message);
    }
    if (!toast.isActive(checkIDs)) {
      if (type === "alert") {
        toast(this.renderHTMLtoToast(displayedTitle, displayedMessage), {
          type: toast.TYPE.INFO,
          toastId: this.dynamicInfoToastId,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 8000,
          progress: undefined,
        });
      } else {
        toast(this.renderHTMLtoToast(displayedTitle, displayedMessage), {
          type: toast.TYPE.WARNING,
          toastId: this.dynamicWarningToastID,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 8000,
          progress: undefined,
        });
      }
    }
  };

  displaySuccess = (
    message: string | JSX.Element,
    title?: string,
    targetElem?: string,
    additionalOptions?: toastify.ToastOptions
  ): void => {
    this.displayToast("SUCCESS", message, title, targetElem, additionalOptions);
  };

  displayInfo = (
    message: string | JSX.Element,
    title?: string,
    targetElem?: string,
    additionalOptions?: toastify.ToastOptions
  ): void => {
    this.displayToast("INFO", message, title, targetElem, additionalOptions);
  };

  displayError = (
    message: string | JSX.Element,
    title?: string,
    targetElem?: string,
    additionalOptions?: toastify.ToastOptions
  ): void => {
    this.displayToast("ERROR", message, title, targetElem, additionalOptions);
  };

  displayWarning = (
    message: string | JSX.Element,
    title?: string,
    targetElem?: string,
    additionalOptions?: toastify.ToastOptions
  ): void => {
    this.displayToast("WARNING", message, title, targetElem, additionalOptions);
  };
}

export default new SamskipNotify();
