import * as React from "react";
import * as ReactDOM from "react-dom";
import { FormGroup, FormControl, InputGroup } from "react-bootstrap";

import styles from "./index.modules.less";
import Icon from "./icon.svg";

export default class extends React.Component<InputProps, InputState> {
  onChange(e: any) {
    this.props.onChange && this.props.onChange(e.target.value);
  }

  render() {
    return (
      <InputGroup className={styles.input}>
        <FormControl
          type="text"
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={(e: any) => this.onChange(e)}
        />
        <InputGroup.Addon
          className={styles.icon}
          style={{
            background: `url(${Icon}) no-repeat 0 0`,
            backgroundSize: "14px 28px"
          }}
        />
      </InputGroup>
    );
  }
}
