import SamskipResource from "SamskipResource";

class EmailService {
  /**
   *
   */
  sendEmail(jobReference: string, email: EmailVM) {
    return SamskipResource.post<EmailVM>(
      "ShippingAPI",
      `bookings/doris/${jobReference}/customsrequest`,
      {},
      email
    );
  }
}

export default new EmailService();
