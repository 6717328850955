import * as angular from "angular";
import { USER_ROLES } from "Constants/UserRoles";
import {
  CompanyService,
  UserService,
  FinanceService,
  TruckingService,
} from "Services";
import * as companyProfileModalTemplate from "Masters/header/companyModal/CompanyModal.html";
import { react2angular } from "react2angular";
import SystemsConnections from "Components/react/SystemConnections";

const companyProfileModal: ng.IComponentOptions = {
  template: `${companyProfileModalTemplate}`,
  bindings: {
    modalInstance: "<",
    close: "&",
    dismiss: "&",
    resolve: "<",
  },
  controller: class CompanyProfileModalController {
    private resolve: any;
    private companyInformation: Company;
    private companyAddresses: PartnerAddress[];
    private companyRepresentatives: PartnerAgent[];
    private customerStatuses: FinanceSAPCustomerStatus[];
    private userHasFinanceRole: boolean;
    private userIsEmployee: boolean;
    private companyHasSAPID: boolean;
    private serverCall: boolean;
    private connectionsResolved: boolean;
    private companySystemConnections: CompanySystemConnections[];

    resolveGetSystemConnections = () => {
      this.companySystemConnections = this.resolve.companyGetSystemConnections;
      this.connectionsResolved = true;
    };

    $onInit = () => {
      const currentUser = UserService.getUserProfile();
      const selectedCompany = UserService.getSelectedCompany();
      if (!currentUser) return;
      this.userIsEmployee = false;
      this.companyInformation = this.resolve.companyInformation;
      this.companyAddresses = this.resolve.companyAddresses;
      this.companyRepresentatives = this.resolve.companyRepresentatives;
      this.userHasFinanceRole = UserService.isAuthorized(USER_ROLES.FINANCE);
      this.companyHasSAPID = selectedCompany.SapID != null;
      this.userIsEmployee = UserService.isEmployee();
      this.connectionsResolved = false;

      // If the user is Employee, resolve
      if (this.userIsEmployee === true) {
        this.resolveGetSystemConnections();
        if (this.companySystemConnections === undefined) {
          this.connectionsResolved = false;
        }
      }

      // If the user has access to finance
      // and there is some SAP ID for that company
      if (this.userHasFinanceRole && this.companyHasSAPID) {
        this.serverCall = true;
        FinanceService.getCustomerStatus(this.companyInformation.SapID).then(
          (data: FinanceSAPCustomerStatus[]) => {
            this.customerStatuses = data;
            this.serverCall = false;
          }
        );
      }
    };
  },
};
angular
  .module("serviceWebApp")
  .component("companyModal", companyProfileModal)
  .component(
    "systemsConnections",
    react2angular(SystemsConnections, [
      "fullName",
      "companyRegNo",
      "sapId",
      "partnerCode",
      "companySystemsConnections",
    ])
  );
