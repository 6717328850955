exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index-modules__download___1vSJc i {\n  margin-right: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"download": "index-modules__download___1vSJc"
};