import { $uiRouter, $translate } from "Bootstrap/angular";
import { Object } from "es6-shim";
import { UserService, SentryService, TranslationService } from "Services";
import { UserStore, RouteStore } from "Stores";
import { ArrayUtilities, UtilityFunctions } from "Utilities";
import LoadingTextModalService from "Services/LoadingTextModalService";
import FeatureToggleRouter from "Components/samFeatureToggle/FeatureToggleRouter";
import { MAJOR_STATES } from "Constants/RouteConstants";
import { reaction } from "mobx";
import {
  StateDeclaration,
  HookMatchCriteria,
  HookRegOptions,
  TransitionHookFn,
  Transition,
  Rejection
} from "@uirouter/core";

declare var GlobalConfig: GlobalConfig;
declare var window: any;

const debug = process.env.DEBUG;

interface StateTransition {
  description: string;
  hook: string;
  match: HookMatchCriteria;
  matchOptions: HookRegOptions;
  hookFn: any;
}

const setPageTitle = (trans?: Transition): void => {
  if (!trans) {
    if (
      !document.title.endsWith(GlobalConfig.page.default["en"]) &&
      !document.title.endsWith(GlobalConfig.page.default["is"])
    ) {
      return;
    }
  }

  const getDefaultTitle = () => {
    const selectedLanguage = TranslationService.getSelectedLanguage();
    return GlobalConfig.page.default[selectedLanguage];
  };

  let pageTitle: any = (trans && trans.to().data.title) || getDefaultTitle();
  if (pageTitle) {
    if (pageTitle instanceof Function && trans) {
      pageTitle = pageTitle.call(trans.to(), trans.params());
    }
    document.title = `${GlobalConfig.page.prefix}${pageTitle}`;
  }
};

reaction(() => TranslationService.getSelectedLanguage(), () => setPageTitle());

// Major states we want different behaviour for

const transitions: StateTransition[] = [
  {
    description: "Update routing state when a transition starts",
    hook: "onBefore",
    match: {},
    matchOptions: {},
    hookFn: (trans: any) => {
      RouteStore.inStateTransition(true);
    }
  },
  {
    description: "Translations loaded before everything else",
    hook: "onBefore",
    match: {
      to: (state: any) => {
        return state.data && state.data.master === true;
      }
    },
    matchOptions: {},
    hookFn: (trans: any) => {
      return $translate.onReady();
    }
  },
  {
    description: "onStart",
    hook: "onStart",
    match: {
      to: (state: any) => {
        return state.data && !state.data.noLogin;
      }
    },
    matchOptions: {},
    hookFn: (trans: Transition) => {
      return UserService.getUserProfileFromAPI()
        .then(UserService.postLogin)
        .catch((e: any) => {
          return Promise.reject(e);
        });
    }
  },
  {
    description: "Success when transitioning to a state",
    hook: "onSuccess",
    match: {},
    matchOptions: {},
    hookFn: (trans: any) => {
      RouteStore.setCurrentState(trans.to().name);
      RouteStore.setPreviousState(trans.from().name);
      RouteStore.inStateTransition(false);

      setGoogleAnalyticsMetrics();
      setPageTitle(trans);

      function setGoogleAnalyticsMetrics(): void {
        if (!window.ga) {
          return;
        }

        window.ga("send", "pageview", {
          page: window.location.pathname
        });

        const currentUser = UserService.getUserProfile();
        const selectedCompany = UserService.getSelectedCompany();
        if (currentUser) {
          window.ga("set", "&uid", currentUser.User.ID); // Set the user ID using signed-in user_id.
          window.ga("set", "dimension1", currentUser.User.ID);
          window.ga("set", "dimension2", currentUser.Access.UserType);
          if (selectedCompany) {
            window.ga("set", "dimension3", selectedCompany.PartnerCode);
          }
        }
      }
    }
  },
  {
    description: "onError",
    hook: "onError",
    match: {},
    matchOptions: {},
    hookFn: (trans: Transition) => {
      setTimeout(() => {
        const profile = UserService.getUserProfile();
        if (profile) {
          UserService.setUserProfile(profile);
        }
      });
    }
  },
  {
    description:
      "When transitioning to login page we want to do a clean up of authentication and profile state",
    hook: "onStart",
    match: { to: MAJOR_STATES.login },
    matchOptions: {},
    hookFn: (trans: any) => {
      UserService.clearAuthentication();
    }
  },
  {
    description:
      "When transitioning to logout page we want to do a whole page refresh to login and reload index",
    hook: "onStart",
    match: { to: MAJOR_STATES.logout },
    matchOptions: {},
    hookFn: (trans: any) => {
      LoadingTextModalService.modalON("TEXT_LOGOUTTEXT", "fa fa-lock");
      UserService.logout().finally(() => {
        window.location.href = `/${MAJOR_STATES.login}`;
      });
    }
  }
];

/**
 * Checks if a state's data.feature is set and is a non-zero length
 * string.
 * @param  {Object}  state  A state object to check for a feature-flag
 * @return {Boolean}        Returns true if the state is feature-flagged
 */
function stateIsFeatureFlagged(state: any): boolean {
  const feature = state.data.feature;
  return Boolean(feature && typeof feature === "string");
}

/**
 * ui-router transition error handler
 * If the transition errors, this is the handler.
 * With the Rejection object we get a 'type' property, with
 * various error types. See: https://ui-router.github.io/ng1/docs/latest/enums/transition.rejecttype.html
 * ABORTED = 3
 * ERROR = 6
 * IGNORED = 5
 * INVALID = 4
 * SUPERSEDED = 2
 */
function defaultErrorHandler(rejection: Rejection) {
  if (rejection.type !== 6) return;
  const detail: any = rejection.detail;
  if (!detail) return;

  SentryService.captureException(detail);

  if (Number.isInteger(detail.status)) {
    const response: Response = detail as Response;

    if (response.status === 401) {
      $uiRouter.stateService.go(MAJOR_STATES.login);
    } else if (response.status === 403) {
      $uiRouter.stateService.go(MAJOR_STATES.home);
    }
  }
}

const transitionObj = {
  transitions,
  defaultErrorHandler
};

export default transitionObj;
