import SamskipResource from "SamskipResource";

class ShipmentDocumentService {
  getDocuments = (
    reference: string,
    docType?: string[]
  ): SamskipPromise<SDCDocument[]> => {
    if (docType) {
      return SamskipResource.get<SDCDocument[]>(
        "ShippingAPI",
        `proxy/documents/${reference}?docType=${docType.join()}`
      );
    }
    return SamskipResource.get<SDCDocument[]>(
      "ShippingAPI",
      `proxy/documents/${reference}`
    );
  };

  getDocumentsBatch = (
    references: string[]
  ): SamskipPromise<SDCReferenceDocument[]> => {
    return SamskipResource.post<SDCReferenceDocument[]>(
      "ShippingAPI",
      "proxy/documents/batchGet",
      undefined,
      references
    );
  };

  postDocuments = (
    jobRef: string,
    docTypes: string[],
    files: File[]
  ): SamskipPromise<{}> => {
    return SamskipResource.postFile(
      "ShippingAPI",
      `proxy/documents/${jobRef}?docType=${docTypes.join(",")}`,
      files
    );
  };

  getDocument = (id: string): SamskipPromise<Blob> => {
    return SamskipResource.get<Blob>(
      "ShippingAPI",
      `proxy/documents/file/${id}`
    );
  };
}

export default new ShipmentDocumentService();
