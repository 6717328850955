import * as React from "react";
import * as ReactDOM from "react-dom";

import styles from "./index.modules.less";

export default class extends React.Component<CollapseProps, CollapseState> {
  collapse: HTMLDivElement;
  state: CollapseState = { open: this.props.open, iconOpen: this.props.open };

  open = () =>
    this.setState({ iconOpen: !this.state.iconOpen }, () =>
      $(this.collapse).slideToggle("slow", () =>
        this.setState({ open: !this.state.open })
      )
    );

  render() {
    return (
      <div
        className={`${styles.collapse} ${
          this.props.className ? this.props.className : ""
        }`}
        onChange={(e: any) => this.props.onChange && this.props.onChange(e)}
      >
        <div className={styles.head} onClick={() => this.open()}>
          <span className={styles.title}>{this.props.title}</span>
          <div className={this.state.iconOpen ? styles.minus : ""}>
            <span></span>
          </div>
        </div>
        <div
          className={styles.fields}
          style={{ display: this.state.open ? "block" : "none" }}
          ref={(e: HTMLDivElement) => (this.collapse = e)}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
