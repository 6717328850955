exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index-modules__toggle___2Mbd1 {\n  display: inline;\n}\n.index-modules__toggle___2Mbd1 .index-modules__label___3a5im {\n  font-size: 1em;\n  background-color: transparent;\n  text-transform: uppercase;\n  color: #74899899;\n  font-weight: 600;\n  padding: 0;\n  margin-right: 0;\n  cursor: pointer;\n}\n.index-modules__toggle___2Mbd1 .index-modules__label___3a5im:last-child {\n  padding-left: 20px;\n}\n.index-modules__toggle___2Mbd1 .index-modules__label___3a5im.index-modules__darker___3RYdu {\n  color: black;\n}\n.index-modules__toggle___2Mbd1 .index-modules__checkbox___2DkG9 {\n  vertical-align: baseline;\n  width: 5.5rem !important;\n  margin: 0;\n  top: 2px;\n}\n", ""]);

// exports
exports.locals = {
	"toggle": "index-modules__toggle___2Mbd1",
	"label": "index-modules__label___3a5im",
	"darker": "index-modules__darker___3RYdu",
	"checkbox": "index-modules__checkbox___2DkG9"
};