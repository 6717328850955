import * as angular from "angular";
import { USER_ROLES } from "Constants/UserRoles";
import { CompanyService, UserService } from "Services";
import "Masters/header/companyModal/CompanyModalComponent";
import { $uibModal } from "Bootstrap/angular";
import "Components/react/SystemConnections";
import { Component } from "react";

class CompanyProfileModal {
  openModal = (selectedCompany: Company) => {
    // TODO: Use ModalService that wraps $uibModal
    return $uibModal.open({
      component: "companyModal" || "systems-connections",
      windowClass: "sam-modal",
      size: "lg",
      resolve: {
        companyInformation: () => {
          return CompanyService.companyInformation(selectedCompany.PartnerCode);
        },
        companyAddresses: () => {
          return CompanyService.companyAddresses(selectedCompany.PartnerCode);
        },
        companyRepresentatives: () => {
          return CompanyService.companyRepresentatives(
            selectedCompany.PartnerCode
          );
        },
        companyGetSystemConnections: () => {
          const userIsEmployee = UserService.isEmployee();
          if (userIsEmployee === true) {
            return CompanyService.companyGetSystemConnections(
              selectedCompany.PartnerCode
            );
          }
        },
      },
    });
  };
}

export default new CompanyProfileModal();
