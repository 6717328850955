import * as angular from "angular";
import { $uiRouter } from "Bootstrap/angular";
import GeneralLoader from "Utilities/GeneralLoader";

import MasterPage from "./MasterPage";
import MasterPageSimple from "./MasterPageSimple";

class Master {
  constructor() {
    const routes: any[] = [
      {
        name: "masterPage",
        abstract: true,
        component: MasterPage,
        data: {
          master: true,
          roles: []
        }
      },
      {
        name: "masterPageSimple",
        abstract: true,
        component: MasterPageSimple,
        data: {
          master: true,
          roles: []
        }
      }
    ];

    routes.forEach(item => $uiRouter.stateRegistry.register(item));
  }
}

var swApp = angular.module("serviceWebApp").run(Master);

swApp.run([
  "$rootScope",
  function($rootScope: any) {
    sessionStorage.setItem("generalLoaderCounter", "0");
  }
]);
