import SamskipResource from "SamskipResource";

class LocationService {
  /**
   * Fetches locations for a specified port code
   */
  getLocationsByPortCode(portCode: string) {
    return SamskipResource.get<LocationVM[]>(
      "AuthAPI",
      `locations/${portCode}`
    );
  }

  /**
   * Fetches if a given portcode has location info
   * @param portCode PortCode
   */
  hasLocationsInfo(portCode: string) {
    return SamskipResource.get<Boolean>(
      "AuthAPI",
      `locations/${portCode}/exists`
    );
  }
}

export default new LocationService();
