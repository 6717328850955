import { reaction, toJS } from "mobx";
import ReactionFactory from "./reactions/ReactionFactory";

class ObservableUtilities {
  reactionWithoutObservables = (observableGetter: any, callback: any) => {
    return reaction(() => {
      const observableValue = observableGetter();
      return toJS(observableValue);
    }, callback);
  };

  createReactionFactory = (): ReactionFactory => {
    return new ReactionFactory();
  };
}

export default new ObservableUtilities();
