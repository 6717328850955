exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*#4f575e;*/\n.button-modules__button___BKCd9 {\n  background-color: #ffffff;\n  border: 1px solid #002378;\n  border-radius: 4px;\n  color: #002378;\n  font-weight: 500;\n  font-size: 14px;\n  font-style: normal;\n  line-height: 20px;\n  letter-spacing: 0px;\n  padding: 0.4em 1em;\n  text-align: center;\n}\n.button-modules__button___BKCd9:hover {\n  background-color: #f2f4f8;\n}\n.button-modules__button___BKCd9:focus {\n  background-color: #f2f4f8;\n}\n.button-modules__button___BKCd9.button-modules__primary___2weLK {\n  color: #ffffff;\n  background-color: #002378;\n}\n.button-modules__button___BKCd9.button-modules__primary___2weLK:hover {\n  background-color: #001c60;\n}\n.button-modules__button___BKCd9.button-modules__primary___2weLK:focus {\n  background-color: #001548;\n}\n.button-modules__button___BKCd9.button-modules__warning___at7W5 {\n  color: #ffffff;\n  background-color: #e9a100;\n  border-color: #e9a100;\n  margin-left: 5px;\n}\n.button-modules__button___BKCd9.button-modules__warning___at7W5:hover {\n  box-shadow: inset 0px 250px 0px rgba(16, 18, 19, 0.1);\n}\n.button-modules__button___BKCd9.button-modules__warning___at7W5:focus {\n  box-shadow: inset 0px 250px 0px rgba(16, 18, 19, 0.22);\n}\n.button-modules__button___BKCd9[disabled],\n.button-modules__button___BKCd9[disabled]:hover,\n.button-modules__button___BKCd9[disabled]:focus,\n.button-modules__button___BKCd9[disabled]:active {\n  background-color: #f1f3f5;\n  color: #adb5bd;\n  border-color: #ced4da;\n}\n.button-modules__float-right___3QWFq {\n  float: right;\n}\n.button-modules__float-left___1SeJC {\n  float: left;\n}\n", ""]);

// exports
exports.locals = {
	"button": "button-modules__button___BKCd9",
	"primary": "button-modules__primary___2weLK",
	"warning": "button-modules__warning___at7W5",
	"float-right": "button-modules__float-right___3QWFq",
	"float-left": "button-modules__float-left___1SeJC"
};