exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*#4f575e;*/\n.index-modules__modal___1q14w {\n  height: fit-content;\n  width: fit-content;\n  overflow: hidden;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n.index-modules__popupmodal___1fvm5 {\n  font-size: 1em !important;\n  padding: 0 !important;\n}\n.index-modules__shared___1-AJ2 .header {\n  padding: 1.5rem !important;\n  color: #000000 !important;\n  font-size: 1.4em !important;\n  font-weight: bold !important;\n}\n.index-modules__shared___1-AJ2 .content {\n  padding: 1.5rem !important;\n  background: #f6f7f9 !important;\n}\n.index-modules__shared___1-AJ2 .footer {\n  margin: 1.5rem -1.5rem -1.5rem -1.5rem;\n  padding: 1.5rem;\n  background-color: white;\n  display: flex;\n  justify-content: center;\n  border-top: 1px solid rgba(34, 36, 38, 0.15);\n  border-bottom-width: 1px;\n  border-bottom-style: solid;\n  border-bottom-color: rgba(34, 36, 38, 0.15);\n}\n.index-modules__shared___1-AJ2 .footer .right,\n.index-modules__shared___1-AJ2 .footer .left {\n  display: flex;\n}\n.index-modules__shared___1-AJ2 .footer .right {\n  margin-left: auto;\n}\n.index-modules__shared___1-AJ2 .footer .left {\n  margin-right: auto;\n}\n.index-modules__shared___1-AJ2 .footer button {\n  margin-right: 0.5em;\n}\n.index-modules__shared___1-AJ2 .footer button:last-child {\n  margin: 0;\n}\n", ""]);

// exports
exports.locals = {
	"modal": "index-modules__modal___1q14w",
	"popupmodal": "index-modules__popupmodal___1fvm5",
	"shared": "index-modules__shared___1-AJ2"
};