export const USERWEBSETTINGS = {
  SelectedLanguage: "SelectedLanguage",
  SelectedListViewSize: "SelectedListViewSize",
  Tutorial: "Tutorial",
  ShipmentsListPage: "ListPage",
  ItemsPerListPage: "ItemsPerListPage",
  DomesticListPage: "DomesticListPage",
  FinanceListPage: "FinanceListPage",
  ItemsPerTablePage: "ItemsPerTablePage",
  MyPageCardRotation: "MyPageCardRotation",
  BookingCreation_DefaultShipperConsignee:
    "BookingCreation_DefaultShipperConsignee",
  SelectedCompanies: "SelectedCompanies",
  RequestListPage: "RequestListPage",
  ReceptionListPage: "ReceptionListPage"
};

export const SELECTED_LIST_VIEW_SIZE = {
  MEDIUM: "0",
  LARGE: "1",
  SMALL: "2"
};
