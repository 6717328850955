import * as Push from "pusher-js";
import { UserService, NotificationService } from "Services";
import { NOTIFICATION_EVENTS } from "Constants/NotificationConstants";
import { $pusher } from "Bootstrap/angular";

declare var GlobalConfig: GlobalConfig;

class PusherService {
  apiKey: string = GlobalConfig.external.pusher.API_KEY;
  cluster: string = GlobalConfig.external.pusher.APP_CLUSTER;
  channelName: string = "samskip-serviceweb";
  pusherClient: any;
  pusher: any;
  channel: any;

  /**
   * Sets up a connection to Pusher and subscribe to the samskip-serviceweb
   * channel (currently we just need the one).
   */
  connect = (): void => {
    if (!this.apiKey) {
      throw new Error("No API_KEY provided for Pusher");
    }

    this.pusherClient = new Push(this.apiKey, {
      cluster: this.cluster
    });

    this.pusher = $pusher(this.pusherClient);
    this.channel = this.pusher.subscribe(this.channelName);

    this.bind(NOTIFICATION_EVENTS.newNotifications, () => {
      NotificationService.getLatestNotificationsFromServer();
    });
  };

  /**
   * Wrapper for Pusher's bind method. Allows us to prevent Pusher events
   * from propagating to listeners, e.g. when an event is received from Pusher
   * that should update notifications and the user is not logged in (which
   * would result in an error). Also has the added benefit of simplifying
   * Pusher usage elsewhere in our app.
   * but an ev
   * @param  {string}   eventName Event to bind callback to.
   * @param  {Function} callback  Callback to call when event is received.
   */
  bind = (eventName: string, callback: Function): void => {
    if (!this.channel) return;

    this.channel.bind(eventName, (data: any) => {
      callback(data);
    });
  };
}

export default new PusherService();
