const makePage = (n: number, text: any, active: boolean) => {
  return { text, active, number: n };
};

function getPages(currentPage: number, totalPages: number) {
  const pages = [];

  // Default page limits
  const maxSize = 3;
  const boundaryLinkNumbers = true;

  // Visible pages are paginated with maxSize
  // TODO: Use this if not using rotating paginator
  // const startPage = (Math.ceil(currentPage / maxSize) - 1) * maxSize + 1;
  const startPage = Math.max(currentPage - 1, 1);

  // Adjust last page if limit is exceeded
  const endPage = Math.min(startPage + maxSize - 1, totalPages);

  // Add page number links
  for (let n = startPage; n <= endPage; n += 1) {
    pages.push(makePage(n, n, n === currentPage));
  }

  if (startPage > 1) {
    pages.unshift(makePage(startPage - 1, "...", false));
  }

  if (endPage < totalPages) {
    pages.push(makePage(endPage + 1, "...", false));
  }
  return pages;
}

export { getPages };
