import { NavStore } from "Stores";
import { UserService } from "Services";
import FeatureToggleRouter from "Components/samFeatureToggle/FeatureToggleRouter";
import { ArrayUtilities } from "Utilities";
import {
  USERWEBSETTINGS,
  SELECTED_LIST_VIEW_SIZE,
} from "Constants/UserWebSettingsConstants";
import { USER_ROLES } from "Constants/UserRoles";
import RedirectModal from "Components/react/RedirectModal";
import { languages } from "./TranslationService";

declare var GlobalConfig: GlobalConfig;

class NavService {
  getMenu = (raw: boolean) => {
    if (raw) return NavStore.menuRaw;

    return NavStore.menu;
  };

  setMenu = (menu: MenuItem[]) => {
    // Set up the user's menu
    const newMenu = Object.assign({}, this.setUpMenu(menu));
    NavStore.setMenu(newMenu);
  };

  private setUpMenu = (oldMenu: MenuItem[]): MenuItem[] => {
    const menu = this.addExtraMenuItems(oldMenu);

    const menuTree: MenuItem[] = [];

    // create a name: node map
    const dataMap = menu.reduce((map, node) => {
      map[node.MenuID] = node;
      return map;
    }, {});

    menu.forEach((node: MenuItem) => {
      // add to parent
      const parent = node.MenuParent ? dataMap[node.MenuParent] : null;
      if (parent) {
        // create child array if it doesn't exist
        const nodes = parent.children || (parent.children = []);
        // add node to child array
        node.OrderID ? nodes.splice(node.OrderID, 0, node) : nodes.push(node);
      } else {
        // parent is null or missing
        menuTree.push(node);
      }
    });

    if (!FeatureToggleRouter.isEnabled("warehouseDashboard")) {
      const warehouseItemName = "Warehouse";
      menuTree.forEach((mainMenu: any) => {
        if (!mainMenu || !mainMenu.children) return;
        mainMenu.children.forEach((child: MenuItem) => {
          if (child.MenuName === warehouseItemName) {
            child.Site = "#";
          }
        });
      });
    }

    return menuTree;

    /**
     * RECURSIVELY removes all menuitems that have specific MenuIDs.
     * @param  {Object} item         A menuitem whose children to inspect.
     * @param  {Array}  removedItems A list of menutIds that should be removed
     */
    function removeItems(item: any, removedItems: number[]) {
      if (!item.children) {
        return;
      }
      item.children = item.children.filter((child: any) => {
        return !ArrayUtilities.includes(removedItems, child.MenuID);
      });
      item.children.forEach((child: MenuItem) => {
        return removeItems(child, removedItems);
      });
    }
  };

  private addExtraMenuItems = (menu: MenuItem[]): MenuItem[] => {
    const size = UserService.getLocalWebSettingValue(
      USERWEBSETTINGS.SelectedListViewSize
    );

    menu.unshift({
      MenuID: 201,
      MenuLevel: 1,
      MenuName: " ",
      MenuParent: 200,
      OrderID: 0,
      Site: "/",
      SystemID: GlobalConfig.subSys,
      Tooltip: null,
      Translate: "MENU_MYPAGE",
      children: [],
    });

    menu.push({
      MenuID: 208,
      MenuLevel: 1,
      MenuName: " ",
      MenuParent: 200,
      OrderID: 6,
      Site: "",
      SystemID: GlobalConfig.subSys,
      Tooltip: null,
      Translate: null,
      cssClass: "icon icon-icon_settings",
      children: [],
    });

    menu.push({
      MenuID: 1000,
      MenuLevel: 2,
      MenuName: "Large",
      MenuParent: 208,
      OrderID: 3,
      Site: "#",
      SystemID: GlobalConfig.subSys,
      Tooltip: null,
      Translate: "large",
      cssClass: `combined ${
        SELECTED_LIST_VIEW_SIZE.LARGE === size ? "selected" : ""
      }`,
      onClick: () => {
        this.saveListViewSize(SELECTED_LIST_VIEW_SIZE.LARGE);
      },
      children: [],
    });

    menu.push({
      MenuID: 1001,
      MenuLevel: 2,
      MenuName: "Medium",
      MenuParent: 208,
      OrderID: 4,
      Site: "#",
      SystemID: GlobalConfig.subSys,
      Tooltip: null,
      Translate: "medium",
      cssClass: `combined ${
        SELECTED_LIST_VIEW_SIZE.MEDIUM === size ? "selected" : ""
      }`,
      onClick: () => {
        this.saveListViewSize(SELECTED_LIST_VIEW_SIZE.MEDIUM);
      },
      children: [],
    });

    menu.push({
      MenuID: 1002,
      MenuLevel: 2,
      MenuName: "Small",
      MenuParent: 208,
      OrderID: 5,
      Site: "#",
      SystemID: GlobalConfig.subSys,
      Tooltip: null,
      Translate: "small",
      cssClass: `combined ${
        SELECTED_LIST_VIEW_SIZE.SMALL === size ? "selected" : ""
      }`,
      onClick: () => {
        this.saveListViewSize(SELECTED_LIST_VIEW_SIZE.SMALL);
      },
      children: [],
    });

    return menu;
  };

  private saveListViewSize = (size: string) => {
    UserService.setWebSetting(USERWEBSETTINGS.SelectedListViewSize, size);
  };
}

export default new NavService();
