class EventUtilities {
  onetime(node: HTMLElement, type: string, callback: (e: Event) => void) {
    node.addEventListener(type, function cb(event) {
      callback(event);
      event.currentTarget!.removeEventListener(event.type, cb);
    });
  }
}

export default new EventUtilities();
