import * as React from "react";
import * as ReactDOM from "react-dom";

import { List } from "semantic-ui-react";

import styles from "./index.less";

import { languages } from "Services";

export default class extends React.Component<
  { items: number[]; value: number; onValue: Function; disabled: boolean },
  any
> {
  render() {
    const { items, disabled } = this.props;
    return (
      <div className={`${styles.itemsPerPage}`}>
        <span>{`${languages("TEXT_RECORDS_ON_PAGE")}: `}</span>
        <List horizontal divided link size="large">
          {items.map((item: number, i: number) => {
            return (
              <List.Item
                key={i}
                as="a"
                active={this.props.value === item}
                onClick={() => this.props.onValue(item)}
                disabled={disabled}
              >
                {item}
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  }
}
