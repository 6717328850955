import React from "react";
import styles from "./button.modules.less";

type ButtonProps = {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  content?: React.ReactNode;
  disabled?: boolean;
  warning?: boolean;
  float?: "right" | "left";
  iconClass?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  primary?: boolean;
  tabIndex?: number;
  type?: "submit" | "reset" | "button";
  style?: React.CSSProperties;
};

const Button: React.FC<ButtonProps> = ({
  id,
  children,
  className,
  content,
  disabled,
  warning,
  float,
  iconClass,
  onClick,
  primary,
  tabIndex,
  type = "button",
  style,
}) => {
  return (
    <button
      id={id}
      className={`${styles.button} ${className} ${warning && styles.warning} ${
        primary && styles.primary
      } ${float && styles[`float-${float} `]}`}
      disabled={disabled}
      onClick={onClick}
      tabIndex={tabIndex}
      type={type}
      style={style}
    >
      {iconClass && <i className={`${iconClass} u-paddingRight5`}></i>}
      {children ? children : content}
    </button>
  );
};

export default Button;
