import * as angular from "angular";
import { API_REQUEST_STATUSES } from "Constants/APIResponseConstants";
import SamskipResource from "SamskipResource";
import { RequestItemList } from "src/requests/interfaces.d";
import {
  ReceptionDetailListItem,
  ReceptionListItem
} from "Warehouse/receptions/interfaces";

class WarehouseService {
  current = (userID: number): SamskipPromise<any> => {
    return SamskipResource.get("WarehouseAPI", `users/${userID}/current`);
  };

  getOrders = (
    dateFrom: string,
    dateTo: string,
    cdMasterIds: string[],
    status: string,
    orderby: string,
    reverse: boolean,
    pageSize: number,
    page: number
  ): SamskipPromise<any> => {
    return SamskipResource.get("WarehouseAPI", "warehouse/orders", {
      dateFrom,
      dateTo,
      page,
      pageSize,
      cdMasterIds: cdMasterIds || null,
      status: status || null,
      orderBy: orderby,
      order: reverse ? "desc" : "asc"
    });
  };

  getOrderDetails = (orderID: string): SamskipPromise<any> => {
    return SamskipResource.get("WarehouseAPI", `warehouse/orders/${orderID}`);
  };

  /**
   * Request excel report containing order details for each of the
   * given order numbers.
   * @param  {array} orderNumbers     Array of order numbers (string)
   */
  downloadOrderDetailsExcel = (
    orderNumbers: string[]
  ): SamskipPromise<Blob> => {
    let parms = orderNumbers;
    // If a single order number is passed, put that into a an array
    if (!angular.isArray(orderNumbers) && angular.isString(orderNumbers)) {
      parms = [orderNumbers];
    }
    return SamskipResource.postBlob(
      "WarehouseAPI",
      "warehouse/orders/excel",
      undefined,
      parms
    );
  };
  containerSearch = (
    company: string,
    searchString: string
  ): Promise<Container[]> => {
    return new Promise<Container[]>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<Container[]>>(
        "SamskipAPI",
        `partners/${company}/containers/${searchString}`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  getReceptions = (
    FromDate: Date,
    ToDate: Date,
    SearchString: string,
    SearchTypes: string[],
    Statuses: number[],
    SortBy: string,
    IsDesc: boolean
  ): Promise<ReceptionListItem[]> => {
    return new Promise<ReceptionListItem[]>((resolve, reject) => {
      SamskipResource.post<APIResponse<ReceptionListItem[]>>(
        "SamskipAPI",
        "warehouse/receptions",
        {},
        {
          FromDate,
          SearchString,
          SearchTypes,
          ToDate,
          Statuses,
          SortBy,
          IsDesc
        }
      )
        .then((res: APIResponse<ReceptionListItem[]>) => {
          if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
            resolve(res.ReturnItem.Data);
          } else {
            reject();
          }
        })
        .catch(err => {
          reject(err.data);
        });
    });
  };

  getReceptionDetails = (
    receptionNumber: string
  ): Promise<ReceptionDetailListItem[]> => {
    return new Promise<ReceptionDetailListItem[]>(async (resolve, reject) => {
      const res = await SamskipResource.post<
        APIResponse<ReceptionDetailListItem[]>
      >(
        "SamskipAPI",
        `warehouse/receptions/details`,
        {},
        { ReceptionID: receptionNumber }
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  getReceptionStatuses = (): Promise<any[]> => {
    return new Promise<any[]>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<any[]>>(
        "SamskipAPI",
        `warehouse/receptions/statuses`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  getExcelForReceptions = (
    Receptions: ReceptionListItem[]
  ): SamskipPromise<any> => {
    return SamskipResource.postBlob(
      "SamskipAPI",
      `warehouse/receptions/excel`,
      {},
      { Receptions }
    );
  };

  warehouseProgress = (userid: number, sapid: string): SamskipPromise<any> => {
    return SamskipResource.get("WarehouseAPI", `warehouse/${userid}/info`, {
      sapID: sapid
    });
  };

  /**
   * Request excel report containing reception details for each of the
   * given reception numbers.
   * @param  {array} receptionNumbers     Array of reception numbers (string)
   */
  downloadReceptionDetailsExcel = (
    receptionNumbers: string[]
  ): SamskipPromise<Blob> => {
    let parms = receptionNumbers;
    // If a single reception number is passed, put that into a an array
    if (
      !angular.isArray(receptionNumbers) &&
      angular.isString(receptionNumbers)
    ) {
      parms = [receptionNumbers];
    }
    return SamskipResource.postBlob(
      "WarehouseAPI",
      "warehouse/receptions/excel",
      undefined,
      parms
    );
  };

  /**
   * Get a list of stock items
   * @param {string} sapId  Sap ID of customer
   * @return {array}        List of Nekovri stock items
   */
  getStockList = (sapId: string): SamskipPromise<any[]> => {
    return SamskipResource.get("WarehouseAPI", `warehouse/stock/${sapId}`);
  };

  /**
   * Get list of stock item detail lines
   * @param {string} sapId  Sap ID of customer
   * @param {string} itemno ID of stock item
   * @return                List of Nekovri stock item detail
   */
  getStockDetailList = (
    sapId: string,
    itemNo: string
  ): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "WarehouseAPI",
      `warehouse/stock/${sapId}/${itemNo}`
    );
  };

  /**
   * Request excel report containing stock details for each given filter
   * @param {array} filter Filtered stock list
   * @returns {promise}    Promise with an Excel file
   */
  stockInExcel = (
    partnerCode: string,
    stockList: {}[]
  ): SamskipPromise<Blob> => {
    return SamskipResource.postBlob(
      "WarehouseAPI",
      `warehouse/partners/${partnerCode}/excel`,
      {},
      stockList
    );
  };
}

export default new WarehouseService();
