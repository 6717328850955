exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index-modules__sort___3EIAJ label {\n  font-weight: normal;\n}\n.index-modules__sort___3EIAJ div {\n  margin-left: 5px;\n}\n.index-modules__sort___3EIAJ #index-modules__dropdown-basic___23QCE {\n  margin-right: 5px;\n  font-size: 12px;\n  line-height: 1.5;\n  border-radius: 3px;\n  font-weight: 400;\n}\n.index-modules__sort___3EIAJ button {\n  font-weight: 400;\n  font-size: 12px;\n}\n.index-modules__sort___3EIAJ .index-modules__icon___2hFsJ {\n  margin-left: 6px;\n  padding: 5px;\n  background-color: #f6f7f9;\n  position: absolute;\n}\n.index-modules__sort___3EIAJ .index-modules__icon___2hFsJ i {\n  color: #006784;\n}\n", ""]);

// exports
exports.locals = {
	"sort": "index-modules__sort___3EIAJ",
	"dropdown-basic": "index-modules__dropdown-basic___23QCE",
	"icon": "index-modules__icon___2hFsJ"
};