import { API_REQUEST_STATUSES } from "Constants/APIResponseConstants";
import SamskipResource from "SamskipResource";

declare var GlobalConfig: GlobalConfig;

class VoyageService {
  getCurrentVoyages = (): Promise<Voyage[]> => {
    return new Promise<Voyage[]>(async (resolve: any, reject: any) => {
      const res = await SamskipResource.get<APIResponse<Voyage[]>>(
        "SamskipAPI",
        `voyages/current/info`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };
}

export default new VoyageService();
