import * as React from "react";
import * as ReactDOM from "react-dom";

import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";
import { TranslationService, UserService } from "Services";
import { ModalUtilities } from "Utilities";
import { languages } from "Services/TranslationService.ts";
import "Masters/header/userModal/UserModalComponent";

import Notifications from "./Notifications";
import CompanyPicker from "./CompanyPicker";
import GeneralLoader from "Utilities/GeneralLoader";

export default class UserInfo extends React.Component<UserInfoProps, {}> {
  langs: string[] = ["is", "en"];
  updateLang(lang: string) {
    UserService.setWebSetting(USERWEBSETTINGS.SelectedLanguage, lang);
    TranslationService.switchLanguage(lang.toLowerCase());
  }
  openUserModal() {
    ModalUtilities.showLargeModal("userProfileModal", {
      user: UserService.getUserProfile,
      languages: TranslationService.getAvailableTranslations
    });
  }
  getLang() {
    const cssClass = "MainHeader-UserInfo-link";

    return this.langs.map(lang => {
      return (
        <a
          key={lang}
          href="#"
          className={cssClass}
          onClick={() => this.updateLang(lang)}
        >
          {lang}
        </a>
      );
    });
  }
  getLoggedIn() {
    if (this.props.user) {
      return (
        <div className="u-floatRight u-posRelative">
          <Notifications />
          <a
            href="#"
            className="MainHeader-UserInfo-link name"
            onClick={this.openUserModal}
          >
            {this.props.user.User.Username}
          </a>
          <CompanyPicker company={this.props.company} user={this.props.user} />
          <a href="/logout" className="MainHeader-UserInfo-link">
            {languages("TEXT_LOGOUT")}
          </a>
          {this.getLang()}
        </div>
      );
    }
  }
  getLoggedOut() {
    return <div className="MainHeader-UserInfo-logout">{this.getLang()}</div>;
  }
  render() {
    return (
      <div className="MainHeader-UserInfo">
        {this.props.user && this.props.company && this.getLoggedIn()}
        {(!this.props.user || !this.props.company) && this.getLoggedOut()}
      </div>
    );
  }
}
