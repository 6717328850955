import { observable, action, toJS } from "mobx";

class TranslationStore {
  @observable private languageValue: string;
  @observable public changed: boolean;
  @observable public loaded: boolean;

  constructor() {
    this.changed = false;
    this.loaded = false;
  }

  @action language = (language: string): void => {
    this.languageValue = language;
  };

  @action translationsChanged = (newState: boolean): void => {
    this.changed = newState;
  };

  @action translationsLoaded = (newState: boolean): void => {
    this.loaded = newState;
  };

  getSelectedLanguage = (): string => {
    return this.languageValue;
  };

  isChanged = (): boolean => {
    return this.changed;
  };

  isLoaded = (): boolean => {
    return this.loaded;
  };
}

export default new TranslationStore();
