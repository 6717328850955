import * as React from "react";

export default class Card extends React.Component<any, {}> {
  state = { flipped: false };
  flip() {
    this.setState({ flipped: !this.state.flipped });
  }
  render() {
    const { children, className } = this.props;
    const { flipped } = this.state;

    return (
      <div className={`sam-card ${className} ${flipped ? "rotate" : ""}`}>
        <div className="sam-card-front">
          <i
            className="sam-card-icon icon-icon_flip"
            onClick={() => this.flip()}
          ></i>
          {children && children[0]}
        </div>
        <div className="sam-card-back">
          <div className="sam-card-back-content">
            <i
              className="sam-card-icon icon-icon_flip"
              onClick={() => this.flip()}
            ></i>
            {children && children[1]}
          </div>
        </div>
      </div>
    );
  }
}
