export const USER_ROLES = {
  SHIP: 1001,
  DOM: 1002,
  WHOUSE: 1003,
  FINANCE: 1004,
  ADMIN: 1005,
  BUISN: 1011,
  USERMANAGER: 1012,
  TEST: 1013,
  PILOT: 1014,
  DOCUPLOAD: 1019, // Not used
  NOTIFICATIONMANAGER: 1020,
  TRUCKING: 1021 // Not used
};

export const USER_TYPES = {
  EMPLOYEE: "E",
  AGENT: "A",
  CUSTOMER: "C"
};
