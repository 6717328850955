exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index-modules__pagination___hkbUL {\n  cursor: pointer;\n  margin: 0;\n}\n.index-modules__pagination___hkbUL .index-modules__disabled___3eirO {\n  color: #777;\n  background-color: #fff;\n  border-color: #ddd;\n  cursor: not-allowed;\n}\n.index-modules__pagination___hkbUL .index-modules__disabled___3eirO span {\n  color: #777 !important;\n}\n.index-modules__pagination___hkbUL a {\n  color: #002c6c !important;\n  outline: none !important;\n}\n.index-modules__active___1cy-U a {\n  background-color: #002c6c !important;\n  color: white !important;\n  outline: none !important;\n}\n", ""]);

// exports
exports.locals = {
	"pagination": "index-modules__pagination___hkbUL",
	"disabled": "index-modules__disabled___3eirO",
	"active": "index-modules__active___1cy-U"
};