import * as React from "react";
import * as ReactDOM from "react-dom";
import { reaction } from "mobx";

import { UserService, TranslationService } from "Services";

import Header from "./header/Header";
import MasterPageView from "./MasterPageView";
import { Footer } from "./footer";
import RefreshSessionModal from "Components/react/RefreshSessionModal";

export default class Page extends React.Component<{}, PageState> {
  state = {
    company: UserService.getSelectedCompanyObservable(),
    lang: TranslationService.getSelectedLanguage(),
    user: UserService.getUserProfile(),
    isRefreshSessionModalOpen: UserService.getIsRefreshSessionModalOpen(),
  };

  componentWillMount() {
    reaction(
      () => UserService.getSelectedCompanyObservable(),
      () => this.updateState()
    );
    reaction(
      () => TranslationService.getSelectedLanguage(),
      () => this.updateState()
    );
    reaction(
      () => UserService.getIsRefreshSessionModalOpen(),
      () => this.updateState()
    );
    reaction(
      () => UserService.getUserProfile(),
      () => this.updateState()
    );
  }

  updateState() {
    this.setState({
      company: UserService.getSelectedCompanyObservable(),
      lang: TranslationService.getSelectedLanguage(),
      user: UserService.getUserProfile(),
      isRefreshSessionModalOpen: UserService.getIsRefreshSessionModalOpen(),
    });
  }

  render() {
    return (
      <div className="master-wrapper">
        <Header company={this.state.company} user={this.state.user} />

        <MasterPageView />

        <div id="image-wrapper"></div>
        <RefreshSessionModal
          open={
            this.state.isRefreshSessionModalOpen &&
            this.state.user !== undefined
          }
          close={() => UserService.setIsRefreshSessionModalOpen(false)}
          userName={
            this.state.user ? this.state.user.Access.LoginID : undefined
          }
        />
        <Footer lang={this.state.lang} />
      </div>
    );
  }
}
