import { UserStore, NavStore } from "Stores";
import {
  SamskipNotify,
  NavService,
  NotificationService,
  PusherService,
} from "Services";
import { ArrayUtilities } from "Utilities";
import SamskipResource from "SamskipResource";
import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";
import { USER_ROLES } from "Constants/UserRoles";
import { Array } from "es6-shim";
import GeneralLoader from "Utilities/GeneralLoader";
import TranslationService from "./TranslationService";

declare var $: any;
declare var window: any;
declare var GlobalConfig: GlobalConfig;

const debug = process.env.DEBUG;

const SAMSKIP_SUBSYS: number = GlobalConfig.subSys;

let timeOutFunction: number;
const _refreshSessionModalTimer = 60 * 60000;
class UserService {
  postLogin = (profile: UserProfileViewModel): UserProfileViewModel => {
    const {
      User,
      Access,
      Roles,
      Companies,
      WebSettings,
      SelectedCompany,
      Menu,
    } = profile;

    UserStore.setUserProfile({
      User,
      Access,
      Roles,
      Companies,
      Menu,
      WebSettings,
      SelectedCompany,
    });
    UserStore.setSelectedCompany(SelectedCompany);
    UserStore.setWebSettings(WebSettings);
    UserStore.loginInProgress(false);
    UserStore.logoutInProgress(false);
    UserStore.loggedIn(true);

    // Set language to websetting selected language
    const lang = UserStore.getWebSetting(USERWEBSETTINGS.SelectedLanguage);
    if (lang) {
      TranslationService.switchLanguage(lang.StringValue);
    }

    NavService.setMenu(profile.Menu);

    NotificationService.getLatestNotificationsFromServer();
    PusherService.connect();

    return profile;
  };

  login = (userLogin: UserLogin): SamskipPromise<any> => {
    UserStore.loginInProgress(true);

    GeneralLoader.increase();
    return SamskipResource.post<UserProfileViewModel>(
      "AuthAPI",
      "auth/login",
      "",
      {
        Username: userLogin.Username,
        Password: userLogin.Password,
        SubSys: GlobalConfig.subSys,
      }
    )
      .then(this.postLogin)
      .then((userProfile: UserProfileViewModel) => {
        this.setAccessTokenAndTimeout(new Date());
        this.setIsRefreshSessionModalOpen(false);
        // Login to old service web
        if (!debug) {
          let formData = new FormData();
          formData.append("Username", userLogin.Username);
          formData.append("Password", userLogin.Password);
          fetch(
            // tslint:disable-next-line
            `${GlobalConfig.ENV.OldServiceWeb}/Login.aspx`,
            {
              method: "post",
              credentials: "include",
              mode: "no-cors",
              body: formData,
            }
          )
            .then(() => {
              // Indicator he was logged in on old
              $("#Layer_1").css({
                fill: "#002c6c",
              });
            })
            .catch(() => {
              console.warn("Login to old failed");
            });
        }

        return userProfile;
      })
      .catch((err: any) => {
        $("#smallSpinner").fadeTo(300, 0);
        $(".login-btn").removeClass("hide-text");

        UserStore.loginInProgress(false);

        // Send login failed event
        SamskipNotify.displayError("ERROR_LOGIN_FAILED");
      })
      .finally(() => {
        GeneralLoader.decrease();
      });
  };

  // Makes sure that we call the me-endpoint at least once after we've asked
  // for a init (removes the access token time).
  initAccessToken() {
    localStorage.removeItem("accessTokenTime");
  }

  logout = (): SamskipPromise<any> => {
    if (!UserStore.isLoginInProgress && !UserStore.isLogoutInProgress) {
      // Get the user
      const user = UserStore.userProfile;

      // User is already logged out, redirect to login page
      if (!user) {
        return <SamskipPromise<any>>Promise.reject(null);
      }

      // User is being logged out.
      UserStore.logoutInProgress(true);

      // Call API
      return SamskipResource.post("AuthAPI", "auth/logout", "", {
        UserID: user.User.ID,
        SubSys: GlobalConfig.subSys,
      })
        .then(this.clearAuthentication)
        .catch(this.clearAuthentication);
    }
    return <SamskipPromise<any>>Promise.resolve(null);
  };

  // Check if user is authenticated (logged in)
  isAuthenticated = (): boolean => {
    return UserStore.isLoggedIn;
  };

  /**
   * Clear authentication state, user's profile, etc.
   * after logging out or before logging in.
   */
  clearAuthentication = (): void => {
    UserStore.logoutInProgress(false);
    UserStore.loggedIn(false);
    UserStore.clearUserProfile();
    NavStore.clearMenu();
    this.resetLocalStorage();
    clearTimeout(timeOutFunction);
  };

  resetLocalStorage = (): void => {
    // Get values for localStorage variables that should persist even when logged out
    const variables = GlobalConfig.page.persistentLocalStorage.map((key) => {
      return { key, value: localStorage.getItem(key) };
    });
    localStorage.clear();
    for (const variable of variables) {
      // Only add variable back to localstorage if it had a value to begin with
      if (variable.value) {
        localStorage.setItem(variable.key, variable.value);
      }
    }
  };

  /**
   * Checks if a user has authorization access based on defined roles.
   * If a user is a global admin, he has default access.
   * If a user isn't authenticated or has none of the required
   * roles specified in the input, he doesn't have access.
   * Input can either be a role ID/key or an array of role IDs/keys,
   * because we convert the array to an array of role IDs,
   * because we will use them to intersect with user's role IDs.
   * If the passed in element is in USER_ROLES object map,
   * we use that role ID, otherwise we use the role ID.
   *
   * @param {any} roles The roles user has to have access to
   *                    This can either be a role ID, a key,
   *                    or an array of role IDs/keys.
   * @returns If the user has authorized access to the roles
   */
  isAuthorized(roles: any) {
    const user = UserStore.userProfile;

    if (!user) return false;
    if (user.Roles.find((role: WebRole) => role.RoleID === 1005)) return true;

    // Get user's role IDs
    const userRoles = user.Roles.map((role: WebRole) => role.RoleID);

    const roleArray = [].concat(roles).map((item: any) => {
      return typeof item === "number" ? item : USER_ROLES[item];
    });

    return ArrayUtilities.intersect(userRoles, roleArray).length > 0;
  }

  isLoginInProgress = () => {
    return UserStore.isLoginInProgress;
  };

  isLogoutInProgress = () => {
    return UserStore.isLogoutInProgress;
  };

  resetPassEmail = (email: string): SamskipPromise<any> => {
    return SamskipResource.post("AuthAPI", `auth/forgottenpassword/${email}/`);
  };

  validateHash = (hash: string): SamskipPromise<any> => {
    return SamskipResource.get(
      "AuthAPI",
      `auth/forgottenpassword/validate/${hash}`
    );
  };

  resetPass = (
    hash: string,
    pass: string,
    passConfirm: string
  ): SamskipPromise<any> => {
    // Makes sure that we call the me-endpoint at least once after the update
    this.initAccessToken();

    return SamskipResource.post("AuthAPI", `auth/resetpassword/${hash}`, "", {
      Password: pass,
      Passwordconfirm: passConfirm,
    });
  };

  setRequestedUser = (requestedUserVM: any): SamskipPromise<string> => {
    // Makes sure that we call the me-endpoint at least once after the update
    this.initAccessToken();

    return SamskipResource.post(
      "AuthAPI",
      `auth/setrequesteduser/`,
      undefined,
      requestedUserVM
    );
  };

  //
  // User
  //

  getUserProfile(): UserProfile | undefined {
    return UserStore.getUserProfile();
  }

  /**
   * Get current user's profile (based on JWT authentication token)
   * from Auth API /me endpoint
   */
  getUserProfileFromAPI = (): SamskipPromise<UserProfile> => {
    // Here we define for how long we will wait until we call the
    // "me" endpoint again
    const _numberOfMinToWait = 5;

    const _user = UserStore.getUserProfile() as UserProfile;
    let _getNewAccessToken: boolean = true;
    const _currentTime = new Date();
    if (localStorage.getItem("accessTokenTime")) {
      const lastAccessTokenTime = new Date(
        localStorage.getItem("accessTokenTime") as string
      );
      const difference = lastAccessTokenTime.getTime() - _currentTime.getTime(); // This will give difference in milliseconds
      const resultInMinutes = Math.abs(Math.round(difference / 60000));
      if (resultInMinutes < _numberOfMinToWait) {
        _getNewAccessToken = false;

        if (!timeOutFunction) {
          timeOutFunction = <any>(
            setTimeout(
              () => this.checkIfModalShouldOpen(),
              _refreshSessionModalTimer - Math.abs(difference)
            )
          );
        }
      }
    }

    if (_getNewAccessToken) {
      this.setAccessTokenAndTimeout(_currentTime);
      return SamskipResource.get<UserProfile>("AuthAPI", "auth/me");
    }

    return new Promise((resolve, reject) => {
      resolve(_user);
    }) as SamskipPromise<UserProfile>;
  };

  private setAccessTokenAndTimeout = (accessTime: Date) => {
    localStorage.setItem("accessTokenTime", accessTime.toLocaleString());
    clearTimeout(timeOutFunction);
    timeOutFunction = <any>(
      setTimeout(() => this.checkIfModalShouldOpen(), _refreshSessionModalTimer)
    );
  };

  private checkIfModalShouldOpen = () => {
    const accessTokenTime = new Date(
      localStorage.getItem("accessTokenTime") as string
    ).getTime();
    const currentTime = new Date().getTime();
    const difference = currentTime - accessTokenTime;
    if (_refreshSessionModalTimer - difference < 0) {
      UserStore.shouldShowrefreshSessionModal(true);
    } else {
      clearTimeout(timeOutFunction);
      timeOutFunction = <any>(
        setTimeout(
          () => this.checkIfModalShouldOpen(),
          _refreshSessionModalTimer - difference
        )
      );
    }
  };

  resetPasswordModalTimer = (): void => {
    const accessTokenTime = new Date(
      localStorage.getItem("accessTokenTime") as string
    ).getTime();
    const currentTime = new Date().getTime();
    const difference = currentTime - accessTokenTime;
    clearTimeout(timeOutFunction);
    timeOutFunction = <any>(
      setTimeout(
        () => this.checkIfModalShouldOpen(),
        _refreshSessionModalTimer - difference
      )
    );
  };

  getUserProfileFromAPIHas = (): SamskipPromise<UserProfile> => {
    return SamskipResource.get<UserProfile>("AuthAPI", "auth/me");
  };

  setUserProfile = (user: UserProfile): UserProfile => {
    UserStore.setUserProfile(user);

    return user;
  };

  public getSelectedCompany = (): Company => {
    return UserStore.getCleanSelectedCompany();
  };

  public getCompanies = (): Company[] => {
    return UserStore.getCleanCompanies();
  };

  public getSelectedCompanyObservable = (): Company => {
    return UserStore.selectedCompany;
  };

  public getIsRefreshSessionModalOpen = (): boolean => {
    return UserStore.isRefreshSessionModalOpen;
  };

  public setIsRefreshSessionModalOpen = (show: boolean): void => {
    UserStore.shouldShowrefreshSessionModal(show);
  };

  isEmployee = (): boolean => {
    const user = this.getUserProfile();
    if (!user) return false;

    return user.Access.UserType === "E";
  };

  isEmployeeOrAgent = (): boolean => {
    const user = this.getUserProfile();
    if (!user) return false;

    return user.Access.UserType === "E" || user.Access.UserType === "A";
  };

  isGlobalAdmin = (): boolean => {
    const user = this.getUserProfile();
    if (!user) return false;

    const adminRoles = user.Roles.find((role) => role.RoleID === 1005);
    return adminRoles !== undefined;
  };

  updateUserPassword = (newPassword: string): SamskipPromise<any> => {
    // Makes sure that we call the me-endpoint at least once after the update
    this.initAccessToken();

    const user = this.getUserProfile();
    if (!user) {
      return <SamskipPromise<any>>Promise.reject("UserProfile not loaded");
    }

    return SamskipResource.put(
      "AuthAPI",
      `users/${user.Access.UserID}/access/${SAMSKIP_SUBSYS}/password`,
      "",
      newPassword
    );
  };

  updateUserPhonenumber = (newPhone: string): SamskipPromise<any> => {
    // Makes sure that we call the me-endpoint at least once after the update
    this.initAccessToken();

    const user = this.getUserProfile();
    if (!user) {
      return <SamskipPromise<any>>Promise.reject("UserProfile not loaded");
    }

    return SamskipResource.put(
      "AuthAPI",
      `users/${user.Access.UserID}/phone`,
      "",
      newPhone
    );
  };

  updateUserEmail = (newEmail: string): SamskipPromise<any> => {
    // Makes sure that we call the me-endpoint at least once after the update
    this.initAccessToken();

    const user = this.getUserProfile();
    if (!user) {
      return <SamskipPromise<any>>Promise.reject("UserProfile not loaded");
    }

    return SamskipResource.put(
      "AuthAPI",
      `users/${user.Access.UserID}/access/${SAMSKIP_SUBSYS}/email`,
      "",
      newEmail
    );
  };

  updateUserNotificationSettings = (
    sendMail: boolean,
    sendMessage: boolean,
    seeCompanyPickerNotification: boolean
  ): SamskipPromise<NotificationSettings | void> => {
    // Makes sure that we call the me-endpoint at least once after the update
    this.initAccessToken();

    const user = this.getUserProfile();
    if (!user) {
      return <SamskipPromise<any>>Promise.reject("UserProfile not loaded");
    }

    return SamskipResource.put(
      "AuthAPI",
      `users/${user.Access.UserID}/notifications`,
      "",
      {
        SendMail: sendMail,
        SendMessage: sendMessage,
        SeeCompanyPickerNotification: seeCompanyPickerNotification,
      }
    );
  };

  getWebSettingObservable(field: string): UserWebSettings | undefined {
    return UserStore.getWebSetting(field);
  }

  getLocalWebSettingValue(field: string): string | undefined {
    const webSettings = UserStore.webSettings;
    if (webSettings) {
      const value = webSettings.find(
        (item: UserWebSettings) => item.Field === field
      );
      if (value) {
        return value.StringValue;
      }
    }
  }

  /**
   *
   * @param field Settings field to update
   * @param value Value of the Setting
   * @param initAccessToken If we want to enforce a look-up from the "me" endpoint and get a new AccessToken.
   */
  setWebSetting = (
    field: string,
    value: string,
    initAccessToken = false
  ): SamskipPromise<UserWebSettings | void> => {
    // Makes sure that we call the me-endpoint at least once after the update
    if (field !== "ListPage" || initAccessToken) {
      this.initAccessToken();
    }

    const user = this.getUserProfile();
    if (!user) {
      return <SamskipPromise<any>>Promise.reject("UserProfile not loaded");
    }

    // Update settings value in store
    UserStore.setWebSetting(field, value);

    return SamskipResource.put(
      "AuthAPI",
      `users/${user.User.ID}/access/${SAMSKIP_SUBSYS}/settings/${field}`,
      "",
      value
    );
  };

  getUserCompanies = (): SamskipPromise<Company[] | void> => {
    const user = this.getUserProfile();
    if (!user) {
      return <SamskipPromise<any>>Promise.reject("UserProfile not loaded");
    }

    return SamskipResource.get(
      "AuthAPI",
      `users/${user.Access.UserID}/companies/${SAMSKIP_SUBSYS}`
    );
  };

  searchUserCompanies = (searchString: string): SamskipPromise<Company[]> => {
    const user = this.getUserProfile();
    if (!user) {
      return <SamskipPromise<any>>Promise.reject("UserProfile not loaded");
    }
    if (!searchString) {
      return <SamskipPromise<any>>Promise.reject("Empty search string");
    }

    return SamskipResource.get(
      "AuthAPI",
      `users/${user.Access.UserID}/companies/${SAMSKIP_SUBSYS}/search/${searchString}`
    );
  };

  switchCompany = (company: Company): SamskipPromise<Company> => {
    // Makes sure that we call the me-endpoint at least once after the update
    this.initAccessToken();

    const user = this.getUserProfile();
    if (!user) {
      return <SamskipPromise<any>>Promise.reject("UserProfile not loaded");
    }
    const oldCompany = UserStore.getSelectedCompany();
    UserStore.setSelectedCompany(company);

    return SamskipResource.put(
      "AuthAPI",
      `users/${user.Access.UserID}/companies/${SAMSKIP_SUBSYS}`,
      "",
      company.PartnerCode
    )
      .then((company: Company) => {
        return company;
      })
      .catch(() => {
        UserStore.setSelectedCompany(oldCompany);
        return oldCompany;
      });
  };

  getEmployeeData = (
    EmployeeAD: string
  ): SamskipPromise<EmployeeDirectoryRecord> => {
    return SamskipResource.get<EmployeeDirectoryRecord>(
      "AuthAPI",
      `users/${EmployeeAD}/employeeData`
    );
  };
}

export default new UserService();
