import * as moment from "moment";
import { Object } from "es6-shim";

/**
 * DateUtilities has helper-methods to work with dates, e.g. for parsing,
 * manipulating, calculating, etc.
 * @param {Object}  moment  Reference to the moment.js library.
 */
class DateUtilities {
  /**
   * Converts a dateString to a Unix-timestamp (seconds since Unix Epoch).
   * Useful when sortering by date.
   * @param  {String} dateString  The string to convert.
   * @return {Number}             Returns converted date if dateString is
   *                              a valid date.
   */
  toUnixDate = (dateString: string): number | undefined => {
    const m = moment(dateString);
    return m.isValid() ? m.unix() : undefined;
  };

  formatDate = (date: string, dateFormat?: string): string => {
    const format = dateFormat || "YYYY-MM-DD";
    return moment(date).isValid() ? moment(date).format(format) : "";
  };

  isSameDay = (first: Date, second: Date) => {
    return (
      first.getDate() === second.getDate() &&
      first.getMonth() === second.getMonth() &&
      first.getFullYear() === second.getFullYear()
    );
  };

  /**
   * Returns a Date object set to the current time
   * @return {Date}               Current date
   */
  getCurrentDate = () => {
    return new Date();
  };

  /**
   * Gets the nearest time from the provided date that fits the provided interval
   * E.g. if the date is at 12:38 and the interval is set to 15, this returns the same date at 12:45
   * @param  {Date}   date      Date to calculate from
   * @param  {Number} interval  Interval to use in minutes
   * @return {Date}             Nearest date that fits the provided interval
   */
  getNextIntervalFromDate = (date: Date, interval: number) => {
    const d = moment(date);
    return d
      .add(interval - (d.minute() % interval), "minutes")
      .second(0)
      .toDate();
  };
}

export default new DateUtilities();
