import * as React from "react";
import * as ReactDOM from "react-dom";
import { reaction } from "mobx";

import { WindowUtilities } from "Utilities";
import { NavService } from "Services";
import { languages } from "Services/TranslationService";

declare var GlobalConfig: GlobalConfig;

export default class Navigation extends React.Component<{}, NavigationState> {
  state = { menu: NavService.getMenu(true), modalOpen: null };
  componentWillMount() {
    reaction(
      () => NavService.getMenu(false),
      () =>
        this.setState({
          menu: NavService.getMenu(true),
        })
    );
  }
  isActive(menuItem: MenuItem) {
    const active = window.location.pathname;
    const cssClass = menuItem.cssClass;
    const path = menuItem.Site;
    const selected = `${cssClass || ""} selected`;
    if (!path) return cssClass;
    if (path === "/") return active === path ? selected : cssClass;
    if (path === "#") {
      for (const subItem of menuItem.children) {
        if (active.indexOf(subItem.Site) >= 0) {
          return selected;
        }
      }
      return cssClass;
    }
    return active.indexOf(path) ? cssClass : selected;
  }
  onMenu(item: MenuItem) {
    if (item.Modal) {
      this.openModal(item.MenuID);
      return;
    }

    const popupAppend = `${item.Site.indexOf("?") === -1 ? "?" : "&"}Popup=1`;
    const path =
      item.Site.startsWith("https://") || item.Site.startsWith("http://")
        ? item.Site
        : item.Site.endsWith(".aspx")
        ? `${GlobalConfig.ENV.OldServiceWeb}/${item.Site}${popupAppend}`
        : `${GlobalConfig.ENV.NewServiceWeb}/${item.Site}`;

    const option = ["", "openPopup", "openIframe", "openWindow"][
      item.ExternalLink || 0
    ];

    item.onClick ? item.onClick() : WindowUtilities[option](path);

    if (item.MenuID >= 1000) this.onOptions(item);
  }
  onOptions(item: MenuItem) {
    const options = this.state.menu[0].children.slice(-1)[0].children;

    options
      .filter((i) => i.cssClass)
      .map((i) => Object.assign(i, { cssClass: "combined " }));

    item.cssClass += "selected";
    this.setState({ menu: this.state.menu });
  }
  getMenuSub(subMenu: MenuItem[]) {
    const dropdown = subMenu.map((m) => {
      const liClass = m.MenuID >= 1000 ? "combined-parent" : "";
      const link = m.ExternalLink ? "#" : m.Site;
      const isClick = m.ExternalLink || m.onClick || m.Modal;

      return (
        <li key={m.MenuID} className={liClass}>
          <a
            href={link}
            className={m.cssClass}
            onClick={() => {
              isClick && this.onMenu(m);
            }}
          >
            {languages(m.Translate)}
            {m.IsNew && (
              <span className="NewTag">{languages("LABEL_NEW")}</span>
            )}
          </a>
          {m.Modal && m.Modal.Component && (
            <m.Modal.Component
              open={this.state.modalOpen === m.MenuID}
              onClose={() => this.closeModal(m.MenuID)}
              {...m.Modal.Props}
            />
          )}
        </li>
      );
    });

    return (
      <div className="MainNavigation">
        <ul role="menu" className="main-dropdown fadeIn">
          {dropdown}
        </ul>
      </div>
    );
  }
  getMenu() {
    const nav = this.state.menu[0].children;

    return nav.map((m) => {
      return (
        <li key={m.MenuID} className="main-dropdown-toggle">
          <div className="main-dropdown-hover">
            <a href={m.Site}>
              <span className={this.isActive(m)}>{languages(m.Translate)}</span>
            </a>
            {m.children && this.getMenuSub(m.children)}
          </div>
        </li>
      );
    });
  }
  render() {
    return (
      <div className="MainHeader-navigationWrapper">
        <div className="MainNavigation">
          <ul role="menu">{this.state.menu && this.getMenu()}</ul>
        </div>
      </div>
    );
  }

  openModal = (menuID: number) => {
    this.setState({ modalOpen: menuID });
  };

  closeModal = (menuID: number) => {
    this.setState({ modalOpen: null });
  };
}
