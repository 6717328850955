import * as React from "react";
import { Pagination } from "react-bootstrap";

import { getPages } from "./pagination";
import styles from "./index.modules.less";

const getLength = (length: number, size: number) =>
  Math.max(Math.ceil(length / size), 1);
const noPrev = (n: number) => n === 1;
const noNext = (n: number, length: number, size: number) =>
  n === getLength(length, size);

export default class Pager extends React.Component<PaginationProps, {}> {
  componentDidUpdate() {
    const { page, count, size, onPage } = this.props;
    if (page > getLength(count, size)) {
      onPage(1);
    }
  }

  render() {
    const {
      firstLabel,
      prevLabel,
      nextLabel,
      lastLabel,
      page,
      count,
      size,
      onPage
    } = this.props;
    const totalPages = getLength(count, size);

    return (
      <Pagination className={styles.pagination}>
        <Pagination.First
          disabled={noPrev(page)}
          onClick={() => !noPrev(page) && onPage(1)}
        >
          {firstLabel}
        </Pagination.First>
        <Pagination.Prev
          disabled={noPrev(page)}
          onClick={() => !noPrev(page) && onPage(page - 1)}
        >
          {prevLabel}
        </Pagination.Prev>

        {getPages(page, totalPages).map((n: any) => (
          <Pagination.Item
            key={n.number}
            className={n.active ? styles.active : ""}
            onClick={() => onPage(n.number)}
          >
            {n.text}
          </Pagination.Item>
        ))}

        <Pagination.Next
          disabled={noNext(page, count, size)}
          onClick={() => !noNext(page, count, size) && onPage(page + 1)}
        >
          {nextLabel}
        </Pagination.Next>
        <Pagination.Last
          disabled={noNext(page, count, size)}
          onClick={() => !noNext(page, count, size) && onPage(totalPages)}
        >
          {lastLabel}
        </Pagination.Last>
      </Pagination>
    );
  }
}
