exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index-modules__wrapper___zK2br .spinner {\n  transform: translate(-50%);\n  opacity: 0;\n  transition: 0.2s opacity ease-out;\n}\n.index-modules__active___2abUu .spinner {\n  opacity: 1;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "index-modules__wrapper___zK2br",
	"active": "index-modules__active___2abUu"
};