import * as React from "react";
import * as ReactDOM from "react-dom";
import { reaction } from "mobx";
import { Overlay, Popover } from "react-bootstrap";

import { NotificationService } from "Services";
import { default as Icon } from "-!svg-react-loader?name=Icon!Assets/svg/bubble.svg";
import { languages } from "Services/TranslationService.ts";

export default class Notifications extends React.Component<
  {},
  NotificationState
> {
  state = {
    unread: NotificationService.getUnreadNotifications(),
    messages: NotificationService.getLatestNotifications(),
    toggle: false,
    target: undefined
  };
  maxNotification: 5;
  componentWillMount() {
    reaction(
      () => NotificationService.getUnreadNotifications(),
      () => this.setNotifications()
    );
  }
  setNotifications() {
    this.setState({
      unread: NotificationService.getUnreadNotifications(),
      messages: NotificationService.getLatestNotifications()
    });
  }
  display(e?: React.FormEvent<HTMLSelectElement>) {
    this.setState({
      target: e ? e.target : this.state.target,
      toggle: !this.state.toggle
    });
  }
  getNotifications() {
    return (this.state.messages || [])
      .slice(0, this.maxNotification)
      .map((n: UserNotification) => {
        return (
          <li key={n.NotificationID}>
            <a href={`/notifications/${n.NotificationID}`}>
              <div className="row">
                <div className="col-sm-1">
                  <i
                    className={`svgIcon ${
                      n.ReadWeb ? "svgReadMsg" : "svgUnreadMsg"
                    }`}
                  ></i>
                </div>
                <div className="col-sm-11">
                  <div className={n.ReadWeb ? "" : "unreadNotification"}>
                    {n.Title}
                    <span></span>
                  </div>
                </div>
              </div>
            </a>
          </li>
        );
      });
  }
  render() {
    return (
      <div
        id="samNotificationBubbleHolder"
        onClick={(e: any) => this.display(e)}
      >
        <Icon className={this.state.unread > 0 ? "unread" : ""} />
        <span className="notifications" aria-hidden="true">
          <span>{this.state.unread}</span>
        </span>

        <Overlay
          show={this.state.toggle}
          target={this.state.target}
          placement="bottom"
          rootClose={true}
          onHide={(e: any) => this.state.toggle && this.display()}
        >
          <Popover id="samNotificationBubbleList">
            <ul>
              {this.getNotifications()}
              <li className="summary">
                <div className="row">
                  <div className="col-sm-6">
                    <span>{languages("LABEL_DISPLAYING")}</span> : 5 /{" "}
                    {this.state.unread}
                  </div>
                  <div className="col-sm-6 u-textRight">
                    <a href="/notifications" onClick={() => this.display()}>
                      {languages("LABEL_DISPLAY_ALL_NOTIFICATIONS")}
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </Popover>
        </Overlay>
      </div>
    );
  }
}
