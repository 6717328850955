import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { CompanyService } from "Services";
import "semantic-ui-less/semantic.less";
import { default as Icon } from "-!svg-react-loader?name=Icon!Assets/svg/check.svg";
import styles from "./index.less";

import { languages, TranslationService } from "Services";

enum SystemCodes {
  DORIS = 1045,
  CONCORDE = 1046,
  SAP = 1047,
  WAREHOUSE = 1051,
  NONE = 0,
}

interface CompanySystemsConnectionsProps {
  ReferenceId: string;
  SubSystemId: number;
  CustomerId: string;
}
interface SystemsConnectionsProps {
  fullName: string;
  companyRegNo: string;
  sapId: string;
  partnerCode: string;
  warehouseId: string;
  companySystemsConnections: CompanySystemsConnectionsProps[];
}
type SystemConnectionsStates = {
  isConcordeConnected: boolean;
  isDorisConnected: boolean;
  isSapConnected: boolean;
  isWarehouseConnected: boolean;
  isLoading: boolean;
  dorisError: boolean;
  sapError: boolean;
  concordeError: boolean;
  selectedEnum: SystemCodes;
};
export default class SystemsConnections extends Component<
  SystemsConnectionsProps,
  SystemConnectionsStates
> {
  constructor(props: SystemsConnectionsProps) {
    super(props);
    this.state = {
      isConcordeConnected: this.setSystemStates(props, SystemCodes.CONCORDE),
      isDorisConnected: this.setSystemStates(props, SystemCodes.DORIS),
      isSapConnected: this.setSystemStates(props, SystemCodes.SAP),
      isWarehouseConnected: this.setSystemStates(props, SystemCodes.WAREHOUSE),
      isLoading: false,
      dorisError: false,
      sapError: false,
      concordeError: false,
      selectedEnum: 0,
    };
  }

  setSystemStates(props: SystemsConnectionsProps, systemCode: SystemCodes) {
    let isValid = false;
    if (props.companySystemsConnections === null) {
      return isValid;
    }
    for (let i = 0; i < props.companySystemsConnections.length; i++) {
      let condCheck = this.props.companySystemsConnections[i].SubSystemId;
      if (condCheck === null) {
        return isValid;
        break;
      }
      if (condCheck === systemCode) {
        isValid = true;
        break;
      }
    }
    return isValid;
  }
  connectClick = async (event: any) => {
    this.setState({ selectedEnum: event });

    this.setState({ isLoading: true });
    // Inital enum = 0,
    this.setState({ selectedEnum: event });
    if (event === SystemCodes.SAP) {
      if (this.state.isSapConnected === false) {
        try {
          await CompanyService.companyConnectSystem(
            this.props.partnerCode,
            this.props.sapId,
            SystemCodes.SAP
          );
          this.setState({ isSapConnected: true });
          this.setState({ sapError: false });
          this.setState({ isLoading: false });
        } catch (error) {
          this.setState({ isSapConnected: false });
          this.setState({ sapError: true });
          this.setState({ isLoading: false });
        }
      }
    }
    if (event === SystemCodes.CONCORDE) {
      if (this.state.isConcordeConnected === false) {
        try {
          await CompanyService.companyConnectSystem(
            this.props.partnerCode,
            this.props.companyRegNo,
            SystemCodes.CONCORDE
          );
          this.setState({ isConcordeConnected: true });
          this.setState({ concordeError: false });
          this.setState({ isLoading: false });
        } catch (error) {
          this.setState({ isConcordeConnected: false });
          this.setState({ concordeError: true });
          this.setState({ isLoading: false });
        }
      }
    }
    if (event === SystemCodes.DORIS) {
      if (this.state.isDorisConnected === false) {
        try {
          await CompanyService.companyConnectSystem(
            this.props.partnerCode,
            this.props.partnerCode,
            SystemCodes.DORIS
          );
          this.setState({ isDorisConnected: true });
          this.setState({ dorisError: false });
          this.setState({ isLoading: false });
        } catch (error) {
          this.setState({ isDorisConnected: false });
          this.setState({ dorisError: true });
          this.setState({ isLoading: false });
        }
      }
    }
  };

  render() {
    const renderContent = () => {
      return (
        <table className={styles.table}>
          <tbody>
            <tr>
              <th>
                <strong>{languages("LABEL_SYSTEM")}</strong>
              </th>
              <th>
                <strong>{languages("LABEL_STATUS")}</strong>
              </th>
            </tr>
            {this.props.companyRegNo ? (
              <tr>
                <td>Concorde</td>
                <td>
                  {this.state.isConcordeConnected ? (
                    <span>
                      {" "}
                      <Icon /> {languages("LABEL_SYSTEMCONNECTIONSTATUS")}
                    </span>
                  ) : (
                    <Button
                      id={SystemCodes.CONCORDE}
                      style={
                        this.state.concordeError
                          ? { backgroundColor: "#FF7D8C" }
                          : { backgroundColor: "#002378" }
                      }
                      className="btn btn-primary btn-sm connecting-button"
                      disabled={this.state.isLoading}
                      onClick={() => this.connectClick(SystemCodes.CONCORDE)}
                    >
                      {this.state.isLoading &&
                      this.state.selectedEnum === SystemCodes.CONCORDE ? (
                        <i className="fa fa-spinner fa-pulse "></i>
                      ) : !this.state.concordeError ? (
                        <span style={{ color: "#FFFFFF" }}>
                          {languages("LABEL_CONNECT")}
                        </span>
                      ) : (
                        <span style={{ color: "#FFFFFF" }}>
                          {" "}
                          {languages("LABEL_ERROR")}
                        </span>
                      )}
                    </Button>
                  )}
                </td>
              </tr>
            ) : (
              <></>
            )}
            {this.props.sapId ? (
              <tr>
                <td>SAP</td>
                <td>
                  {this.state.isSapConnected ? (
                    <span>
                      {" "}
                      <Icon /> {languages("LABEL_SYSTEMCONNECTIONSTATUS")}
                    </span>
                  ) : (
                    <Button
                      id={SystemCodes.SAP}
                      style={
                        this.state.sapError
                          ? { backgroundColor: "#FF7D8C" }
                          : { backgroundColor: "#002378" }
                      }
                      className="btn btn-primary btn-sm connecting-button"
                      disabled={this.state.isLoading}
                      onClick={() => this.connectClick(SystemCodes.SAP)}
                    >
                      {this.state.isLoading &&
                      this.state.selectedEnum === SystemCodes.SAP ? (
                        <i className="fa fa-spinner fa-pulse "></i>
                      ) : !this.state.sapError ? (
                        <span style={{ color: "#FFFFFF" }}>
                          {languages("LABEL_CONNECT")}
                        </span>
                      ) : (
                        <span style={{ color: "#FFFFFF" }}>
                          {" "}
                          {languages("LABEL_ERROR")}
                        </span>
                      )}
                    </Button>
                  )}
                </td>
              </tr>
            ) : (
              <></>
            )}
            {this.props.partnerCode ? (
              <tr>
                <td>Doris</td>
                <td>
                  {this.state.isDorisConnected ? (
                    <span>
                      {" "}
                      <Icon /> {languages("LABEL_SYSTEMCONNECTIONSTATUS")}
                    </span>
                  ) : (
                    <Button
                      id={SystemCodes.DORIS}
                      style={
                        this.state.dorisError
                          ? { backgroundColor: "#FF7D8C" }
                          : { backgroundColor: "#002378" }
                      }
                      className="btn btn-primary btn-sm connecting-button"
                      disabled={this.state.isLoading}
                      onClick={() => this.connectClick(SystemCodes.DORIS)}
                    >
                      {this.state.isLoading &&
                      this.state.selectedEnum === SystemCodes.DORIS ? (
                        <i className="fa fa-spinner fa-pulse "></i>
                      ) : !this.state.dorisError ? (
                        <span style={{ color: "#FFFFFF" }}>
                          {languages("LABEL_CONNECT")}
                        </span>
                      ) : (
                        <span style={{ color: "#FFFFFF" }}>
                          {" "}
                          {languages("LABEL_ERROR")}
                        </span>
                      )}
                    </Button>
                  )}
                </td>
              </tr>
            ) : (
              <></>
            )}
            {this.props.warehouseId ? (
              <tr className="warehouse-row">
                <td className="u-borderBottom-lightGray">Warehouse</td>
                <td className="u-borderBottom-lightGray">
                  {this.state.isWarehouseConnected && (
                    <span>
                      {" "}
                      <Icon /> {languages("LABEL_SYSTEMCONNECTIONSTATUS")}
                    </span>
                  )}
                  {!this.state.isWarehouseConnected && (
                    <Button
                      className="btn btn-default btn-sm"
                      //onClick={() => this.connectClick()}
                    >
                      <span style={{ color: "#FFFFFF" }}>
                        {languages("LABEL_CONNECT")}
                      </span>
                    </Button>
                  )}
                </td>
              </tr>
            ) : (
              <></>
            )}
          </tbody>
        </table>
      );
    };

    return <div className="CompanyProfile-frameTable"> {renderContent()}</div>;
  }
}
