import SamskipResource from "SamskipResource";
import latenize from "latinize";
import allSettled from "promise.allsettled";
import _ from "lodash";
import { API_REQUEST_STATUSES } from "Constants/APIResponseConstants";

class ShipmentRegistryService {
  /**
   * Search hazardcodes from API
   * @param  {String}  Search string (hazard code description or UN number)
   * @param  {Number}  Limit of results from API
   * @return {Promise} Promise with a list of hazardcodes
   */
  searchHazardCodes = (
    searchString: string,
    limit?: number
  ): SamskipPromise<any> => {
    const searchLimit = limit || 10;

    return SamskipResource.get(
      "ShippingAPI",
      `registry/hazardcodes/${searchString}`,
      { limit: searchLimit }
    );
  };

  /**
   * Search countries from API
   * @param  {String} searchString Search string (country name)
   * @return {Promise}             Promise with a list of countries
   */
  searchCountries = (searchString: string): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `registry/countries/${searchString}`
    );
  };

  /**
   * Get all shipping ports from API
   * @return {Promise}  Promise with a list of shipping ports
   */
  getShippingPorts = (): Promise<any[]> => {
    return new Promise<any[]>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<any[]>>(
        "SamskipAPI",
        `ports`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  /**
   * Get shipping port for the provided port codes
   * @param   {String[]} portCode   Portcode
   * @returns {Promise}             Promise with a single shipping location
   */
  getShippingPort = (portCode: string): Promise<Port> => {
    return new Promise<Port>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<Port>>(
        "SamskipAPI",
        `ports/${latenize(portCode.toLowerCase())}`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  /**
   * Search shipping locations from API
   * @param  {String} searchString Search string (point code or name)
   * @param  {String} country      Country code  (Two character country code e.g. 'IS')
   * @return {Promise}             Promise with a list of shipping locations
   */
  searchShippingPorts(
    value: string | null = null,
    country: string | null = null
  ): Promise<Port[]> {
    const searchString = value ? latenize(value.toLowerCase()) : null;
    return new Promise<Port[]>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<Port[]>>(
        "SamskipAPI",
        `ports/search`,
        {
          searchString,
          country
        }
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  }

  /**
   * Get all shipping types
   * @return {Promise} Promise with a list of shipping types
   */
  getShippingTypes = (): SamskipPromise<any[]> => {
    return SamskipResource.get("ShippingAPI", "registry/types");
  };

  /**
   * Get all container types
   * @return {Promise} Promise with a list of container types
   */
  getContainerTypes = (): SamskipPromise<any[]> => {
    return SamskipResource.get("ShippingAPI", "registry/containertypes");
  };

  /**
   * Get all shipping types
   * @return {Promise} Promise with a list of container types
   */
  getPackageTypes = (): SamskipPromise<any[]> => {
    return SamskipResource.get("ShippingAPI", "registry/packagetypes");
  };

  /**
   * Get all inco terms
   * @return {Promise} Promise with a list of inco terms
   */
  getIncoTerms = (): SamskipPromise<any[]> => {
    return SamskipResource.get("ShippingAPI", "registry/incoterms");
  };

  /**
   * Get commodities by description.
   * @param  {string} searchString Desription. Minimum searchString size = 3
   * @param  {string} limit        Limits result by number
   * @return {object}              Commodities
   */
  searchCommodities(searchString: string, limit?: number) {
    const searchLimit = limit || 5;

    return SamskipResource.get(
      "ShippingAPI",
      `registry/commodities/${searchString}`,
      { limit: searchLimit }
    );
  }

  /**
   * [getAllCommodities description]
   * @return {[type]} [description]
   */
  getAllShippingCommodities = (): SamskipPromise<any[]> => {
    return SamskipResource.get("ShippingAPI", "registry/commodities");
  };

  getNextVoyages = (
    POL: string,
    POD: string,
    limitTo?: number,
    skipSize?: number,
    minCutoffDate?: string
  ): SamskipPromise<any[]> => {
    // Limit results to 5 voyages by default
    const searchLimitTo = limitTo || 5;

    return SamskipResource.get(
      "ShippingAPI",
      `registry/voyages/${POL}/${POD}`,
      {
        limit: searchLimitTo,
        skip: skipSize,
        afterDate: minCutoffDate
      }
    );
  };

  getCurrUTCDate = async (): Promise<Date> => {
    return await SamskipResource.get("ShippingAPI", `registry/date/current`);
  };
}

export default new ShipmentRegistryService();
