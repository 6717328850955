import { IReactionDisposer, IReactionPublic, reaction } from "mobx";

class ReactionFactory {
  private reactions: IReactionDisposer[] = [];

  createReaction = (disposer: IReactionDisposer) => {
    this.reactions.push(disposer);
    return disposer;
  };

  createReactions = (
    ...disposers: IReactionDisposer[]
  ): IReactionDisposer[] => {
    disposers.forEach((disposer: any) => {
      this.reactions.push(disposer);
    });
    return disposers;
  };

  disposeReactions = (): void => {
    this.reactions.forEach((fn: IReactionDisposer) => {
      fn();
    });
  };
}

export default ReactionFactory;
