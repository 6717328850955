import * as React from "react";
import styles from "./index.modules.less";

export default class extends React.Component<StatusBarProps, {}> {
  getCircle = (i: number, color?: string) =>
    Array.from(Array(Math.max(i, 0))).map((e, i: number) => (
      <span key={i} className={`${styles.circle} ${color && styles[color]}`}>
        <span />
      </span>
    ));
  getColor(
    current: StatusBarOptions,
    last: StatusBarOptions,
    isFinal: boolean
  ) {
    const color =
      current.value &&
      current.value.some(value => last.value.indexOf(value) !== -1)
        ? "green"
        : "blue";

    return isFinal ? "gray" : color;
  }
  isMatch = (status: string, option: StatusBarOptions) =>
    option.value.some(item => item === status);
  render() {
    const { options, label, status, className } = this.props;

    const last = options.length - 1;
    const index = options.findIndex(o => this.isMatch(status, o));

    const isFinal =
      (this.isMatch(status, options[last]) && options[last].final) || false;
    const coloredCircles = isFinal ? index - 1 : index;
    const barSize = options[last].final ? last - 1 : last;

    const emptyCircles = this.getCircle(barSize - coloredCircles);
    const filledCircles = this.getCircle(
      coloredCircles + 1,
      this.getColor(options[coloredCircles] || [], options[barSize], isFinal)
    );

    return (
      <div className={`${className} ${styles.status}`}>
        <span className={styles.labelSuccess}>{label}</span>
        <div className={styles.statusBar}>
          <div className={styles.bookingStatus}>
            <span className={styles.centerBorder} />
            {filledCircles}
            {emptyCircles}
          </div>
        </div>
      </div>
    );
  }
}
