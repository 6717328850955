import * as angular from "angular";
import { ArrayUtilities } from "Utilities";
import { Object } from "es6-shim";

import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";
import { SamskipNotify, TranslationService, UserService } from "Services";
import * as template from "Masters/header/userModal/UserModal.html";

const userProfileModalComponent: ng.IComponentOptions = {
  template: `${template}`,
  bindings: {
    resolve: "<"
  },
  controller: class UserProfileModalComponent {
    private resolve: {
      user: UserProfile;
      languages: Translation[];
    };
    private editable: UserProfile;
    private availableLanguages: string[];
    private selectedLanguage: string;
    private newPassword: string;

    private checkSendMail: boolean;
    private checkSendMessage: boolean;
    private checkCompanyPickerNotification: boolean;
    private toggles: object;

    $onInit = () => {
      this.editable = JSON.parse(JSON.stringify(this.resolve.user));
      this.availableLanguages = this.resolve.languages.map(
        (lang: Translation) => lang.Language
      );
      this.selectedLanguage =
        UserService.getLocalWebSettingValue(USERWEBSETTINGS.SelectedLanguage) ||
        "is";

      this.checkSendMail = this.editable.Access.SendMail === "Y";
      this.checkSendMessage = this.editable.Access.SendMessage === "Y";
      this.checkCompanyPickerNotification =
        this.editable.Access.CPNotify === "Y";

      this.toggles = {};
    };

    /**
     * Called when the user checks/unchecks the checkbox for email notifications.
     */
    toggleSendMail = (): void => {
      this.checkSendMail = this.editable.Access.SendMail !== "Y";

      this.updateNotificationPreferences();
    };

    /**
     * Called when the user checks/unchecks the checkbox for SMS notifications.
     */
    toggleSendMessage = (): void => {
      this.checkSendMessage = this.editable.Access.SendMessage !== "Y";

      this.updateNotificationPreferences();
    };

    /**
     * Called when the user checks/unchecks the checkbox for SMS notifications.
     */
    toggleCompanyPickerNotification = (): void => {
      this.checkCompanyPickerNotification =
        this.editable.Access.CPNotify !== "Y";

      this.updateNotificationPreferences();
    };

    toggleEditMode = (fieldName: string): void => {
      this.toggles[fieldName] = !this.toggles[fieldName];

      const off = this.toggles[fieldName] !== true;
      if (off) {
        switch (fieldName) {
          case "phone":
            this.updateUserPhonenumber();
            break;
          case "email":
            this.updateUserEmail();
            break;
          case "lang":
            this.updatePreferredLanguage();
            break;
          default:
            throw new Error("Option not available");
        }
      }
    };

    /**
     * Called when the user submits a changed password.
     */
    savePassword = (): void => {
      this.toggles["password"] = !this.toggles["password"];

      if (!this.newPassword) {
        return;
      }

      UserService.updateUserPassword(this.newPassword).then(
        () => {
          SamskipNotify.displaySuccess("LABEL_PASSWORDCHANGED");
        },
        () => {
          this.toggles["password"] = !this.toggles["password"];
          SamskipNotify.displayError("TEXT_FAILEDTORESETPASS");
        }
      );
    };

    /**
     * Updates the user's preferred language by calling TranslationService
     * to update the tranlations in the client and saves the preferred
     * language settings via server-call.
     */
    private updatePreferredLanguage = (): void => {
      if (
        this.selectedLanguage === this.editable.User.Language ||
        !ArrayUtilities.includes(this.availableLanguages, this.selectedLanguage)
      ) {
        return;
      }

      this.editable.User.Language = this.selectedLanguage;
      UserService.setWebSetting(
        USERWEBSETTINGS.SelectedLanguage,
        this.selectedLanguage
      );
      TranslationService.switchLanguage(this.selectedLanguage);
    };

    /**
     * Sends the user's updated email address to server.
     */
    private updateUserEmail = (): void => {
      const email = this.editable.Access.Email;
      const oldEmail = this.resolve.user.Access.Email || "";

      if (!email || email.trim() === oldEmail.trim()) {
        return;
      }

      UserService.updateUserEmail(email).then(
        () => {
          SamskipNotify.displaySuccess("TEXT_EMAIL_CHANGED");

          this.resolve.user.Access.Email = email;
          UserService.setUserProfile(this.editable);
        },
        () => {
          SamskipNotify.displayError("ERROR_FAILED_TO_CHANGE_EMAIL");
        }
      );
    };

    /**
     * Sends the user's updated phone number to server.
     */
    private updateUserPhonenumber = (): void => {
      const phone = this.editable.User.Phone;
      const oldPhone = this.resolve.user.User.Phone || "";

      if (!phone || phone.trim() === oldPhone.trim()) {
        return;
      }

      UserService.updateUserPhonenumber(phone).then(
        () => {
          SamskipNotify.displaySuccess("TEXT_PHONENUMBER_CHANGED");

          this.resolve.user.User.Phone = phone;
          UserService.setUserProfile(this.editable);
        },
        () => {
          SamskipNotify.displayError("ERROR_FAILED_TO_CHANGE_PHONENUMBER");
        }
      );
    };

    /**
     * Sends the user's updated notification preferences to server.
     */
    private updateNotificationPreferences = (): void => {
      this.editable.Access.SendMail = this.checkSendMail ? "Y" : "N";
      this.editable.Access.SendMessage = this.checkSendMessage ? "Y" : "N";
      this.editable.Access.CPNotify = this.checkCompanyPickerNotification
        ? "Y"
        : "N";

      UserService.updateUserNotificationSettings(
        this.checkSendMail,
        this.checkSendMessage,
        this.checkCompanyPickerNotification
      ).then(
        () => {
          UserService.setUserProfile(this.editable);
          SamskipNotify.displaySuccess("TEXT_SETTINGS_UPDATED");
        },
        () => {
          SamskipNotify.displayError("ERROR_UPDATING_SETTINGS");
        }
      );
    };
  }
};

angular
  .module("serviceWebApp")
  .component("userProfileModal", userProfileModalComponent);
