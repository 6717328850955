exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index-modules__radio___1AN5n {\n  min-height: 26px;\n  margin-top: 0;\n  margin-bottom: 0;\n  padding-left: 0;\n}\n.index-modules__radio___1AN5n label {\n  padding-left: 0;\n}\n.index-modules__radio___1AN5n label input {\n  position: absolute;\n  overflow: hidden;\n  clip: rect(0 0 0 0);\n  height: 1px;\n  width: 1px;\n  margin: -1px;\n  padding: 0;\n  border: 0;\n}\n.index-modules__radio___1AN5n label input:checked + label {\n  background-position: 0 -15px !important;\n}\n.index-modules__radio___1AN5n label label {\n  padding-left: 30px !important;\n  min-height: 15px   !important;\n  height: 15px;\n  display: inline-block;\n  line-height: 15px;\n  background-repeat: no-repeat;\n  background-position: 0 0;\n  font-size: 15px;\n  font-weight: 400;\n  vertical-align: middle;\n  letter-spacing: -0.02em;\n  cursor: pointer;\n  background-position: 0px 0px;\n}\n", ""]);

// exports
exports.locals = {
	"radio": "index-modules__radio___1AN5n"
};