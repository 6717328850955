exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index-modules__list___lZp6Z {\n  display: inline-block;\n  width: 100%;\n  text-align: right;\n  position: relative;\n  right: 0;\n  font-size: 0.8em;\n  z-index: 16;\n  line-height: 1.1;\n  padding: 0;\n}\n.index-modules__list___lZp6Z ul {\n  display: inline-block;\n  padding: 0;\n}\n.index-modules__list___lZp6Z ul li {\n  display: inline;\n  list-style-type: none;\n}\n.index-modules__list___lZp6Z ul li span {\n  font-weight: 600;\n  cursor: pointer;\n}\n.index-modules__list___lZp6Z ul li + li:before {\n  content: \" | \";\n  text-decoration: none;\n}\n.index-modules__list___lZp6Z .index-modules__selected___2SvTr span {\n  text-decoration: underline;\n}\n.index-modules__title___1rRGb {\n  margin-right: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"list": "index-modules__list___lZp6Z",
	"selected": "index-modules__selected___2SvTr",
	"title": "index-modules__title___1rRGb"
};