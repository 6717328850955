import * as React from "react";
import {
  DropdownButton,
  Button,
  MenuItem,
  ControlLabel
} from "react-bootstrap";
import * as moment from "moment";

import styles from "./index.modules.less";

/* Simple Sort Function */
const sort = (data: any[], key: string, order: boolean) =>
  data.sort((a: any, b: any) => {
    let value = 0;

    // Handle date
    const c = moment(a[key]).isValid() ? moment(a[key]).valueOf() : a[key];
    const d = moment(b[key]).isValid() ? moment(b[key]).valueOf() : b[key];

    if (c == null) value = 1;
    if (d == null) value = -1;
    if (c < d) value = -1;
    if (c > d) value = 1;

    return order ? value : value * -1;
  });

export default class extends React.Component<SortProps, SortState> {
  state: SortState = { selected: this.props.options[0], orderAsc: true };

  onSort = () =>
    this.props.onSort(
      sort(this.props.data, this.state.selected.key, this.state.orderAsc)
    );

  onReverse = (orderAsc: boolean) => this.setState({ orderAsc }, this.onSort);

  onSelect = (selected: any) => this.setState({ selected }, this.onSort);

  render() {
    const { options, label } = this.props;
    const { selected, orderAsc } = this.state;

    return (
      <div className={styles.sort}>
        <ControlLabel>{label}</ControlLabel>

        <DropdownButton
          id="dropdown-basic"
          title={selected.text}
          bsStyle="default"
          onSelect={(i: any) => this.onSelect(options[i])}
        >
          {options.map((value: SortOption, i: number) => (
            <MenuItem key={i} eventKey={i}>
              {value.text}
            </MenuItem>
          ))}
        </DropdownButton>

        <Button
          className={styles.icon}
          bsSize="xs"
          onClick={() => this.onReverse(!orderAsc)}
        >
          <i
            className={`fa fa-lg fa-sort-alpha-${orderAsc ? "asc" : "desc"}`}
          ></i>
        </Button>
      </div>
    );
  }
}
