import * as angular from "angular";
import { $http } from "ngimport";
import RestHelper from "../RestHelper";
import { forEach } from "@uirouter/core";

declare const GlobalConfig: GlobalConfig;

class Resource implements IResource {
  get<T>(api: string, path: string, params?: {}): SamskipPromise<T> {
    const url = RestHelper.getAPIUrl(api);
    const request = RestHelper.createRequest<any>(url, "get", path, params, "");
    return this.sendRequest<T>(request);
  }

  getBlob(api: string, path: string, params?: {}): SamskipPromise<Blob> {
    const url = RestHelper.getAPIUrl(api);
    const request = RestHelper.createRequest<any>(
      url,
      "get",
      path,
      params,
      "arraybuffer"
    );
    return this.sendRequest<any>(request);
  }

  post<T>(
    api: string,
    path: string,
    params?: {},
    data?: any
  ): SamskipPromise<T> {
    const url = RestHelper.getAPIUrl(api);
    const request = RestHelper.createRequest<any>(
      url,
      "post",
      path,
      params,
      "",
      data
    );
    return this.sendRequest<T>(request);
  }

  postBlob(
    api: string,
    path: string,
    params?: {},
    data?: any
  ): SamskipPromise<Blob> {
    const url = RestHelper.getAPIUrl(api);
    const request = RestHelper.createRequest<any>(
      url,
      "post",
      path,
      params,
      "arraybuffer",
      data
    );
    return this.sendRequest<any>(request);
  }

  postFile(api: string, path: string, data: File[]): SamskipPromise<any> {
    const url = RestHelper.getAPIUrl(api);

    return $http.post(`${url}/${path}`, data, {
      headers: {
        "Content-Type": undefined
      },
      transformRequest: (data: any, headersGetter: any) => {
        const formData = new FormData();

        data.forEach((d: any, i: number) => {
          formData.append(`file_${i}`, d);
        });

        return formData;
      }
    });
  }

  put<T>(
    api: string,
    path: string,
    params?: {},
    data?: any
  ): SamskipPromise<T> {
    const url = RestHelper.getAPIUrl(api);
    const request = RestHelper.createRequest<any>(
      url,
      "put",
      path,
      params,
      "",
      data
    );
    return this.sendRequest<T>(request);
  }

  delete<T>(
    api: string,
    path: string,
    params?: {},
    data?: any
  ): SamskipPromise<T> {
    const url = RestHelper.getAPIUrl(api);
    const request = RestHelper.createRequest<any>(
      url,
      "delete",
      path,
      params,
      "",
      data
    );
    return this.sendRequest<T>(request);
  }

  deleteJSON<T>(
    api: string,
    path: string,
    params?: {},
    data?: any
  ): SamskipPromise<T> {
    const url = RestHelper.getAPIUrl(api);
    const request = RestHelper.createRequest<any>(
      url,
      "delete",
      path,
      params,
      "",
      data
    );
    return this.sendJSONRequest<T>(request);
  }

  /**
   * Query graphql server
   * @param query GraphQL query
   * @param variables Object defining the query variables
   */
  graphql = (
    query: string,
    variables?: Object | undefined
  ): SamskipPromise<any> => {
    const data = { query } as GraphQLData;
    variables ? (data.variables = variables) : null;
    return this.post("GraphQLAPI", "", undefined, data);
  };

  private sendRequest<T>(request: any): SamskipPromise<T> {
    // In Angular request payload is named 'data' not 'body'
    request.data = request.body;

    const requestPromise = $http(request).then((res: any) => res.data as T);

    return requestPromise;
  }

  private sendJSONRequest<T>(request: any): SamskipPromise<T> {
    // In Angular request payload is named 'data' not 'body'
    request.data = request.body;
    request.headers = {
      "Content-Type": "application/json"
    };

    const requestPromise = $http(request).then((res: any) => res.data as T);

    return requestPromise;
  }
}

export default new Resource();
