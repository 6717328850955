import { observable, computed, action, toJS } from "mobx";
import { persist } from "Utilities/Store";

@persist("NavStore")
class NavStore {
  @observable private menuValue: MenuItem[];

  @computed get menuRaw(): MenuItem[] {
    return toJS(this.menuValue);
  }

  @computed get menu(): MenuItem[] {
    return this.menuValue;
  }

  @action setMenu = (menu: MenuItem[]): void => {
    this.menuValue = menu;
  };

  @action
  public clearMenu = (): void => {
    (this.menuValue as any) = undefined;
  };
}

export default new NavStore();
