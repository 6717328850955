import SamskipResource from "SamskipResource";
import { WindowUtilities } from "Utilities";

declare const GlobalConfig: GlobalConfig;

class FinanceService {
  allTransactions = (
    sapID: string,
    dateFrom: string,
    dateTo: string
  ): SamskipPromise<FinanceSAPTransactionWithOverdue[]> => {
    return SamskipResource.get<FinanceSAPTransactionWithOverdue[]>(
      "FinanceAPI",
      `${sapID}/transactions`,
      { dateFrom, dateTo }
    );
  };

  getCustomerOverdueTransactionsInfo = (
    sapID: string
  ): SamskipPromise<FinanceSAPTransactionsOverdueOverview> => {
    return SamskipResource.get<FinanceSAPTransactionsOverdueOverview>(
      "FinanceAPI",
      `${sapID}/transactions/overdue/info`
    );
  };

  searchTransactions = (
    sapID: string,
    searchString: string
  ): SamskipPromise<FinanceSAPTransactionWithOverdue[]> => {
    return SamskipResource.get(
      "FinanceAPI",
      `${sapID}/transactions/search/${searchString}`
    );
  };

  allTransactionsInExcel = (
    sapID: string,
    dateFrom: string,
    dateTo: string,
    filteredFinances: []
  ): SamskipPromise<Blob> => {
    return SamskipResource.postBlob(
      "FinanceAPI",
      `${sapID}/transactions/excel`,
      {
        dateFrom,
        dateTo
      },
      filteredFinances
    );
  };

  getInvoice = (sapID: string, invoiceNumber: string): SamskipPromise<Blob> => {
    return SamskipResource.getBlob(
      "FinanceAPI",
      `${sapID}/invoices/${invoiceNumber}`
    );
  };

  getMultipleInvoices = (
    sapID: string,
    invoiceNumbers: string
  ): SamskipPromise<Blob> => {
    return SamskipResource.getBlob(
      "FinanceAPI",
      `${sapID}/multiinvoices/${invoiceNumbers}`
    );
  };

  getInvoicesByReference = (
    referenceType: string,
    reference: string
  ): SamskipPromise<FinanceInvoiceHeader[]> => {
    return SamskipResource.get<FinanceInvoiceHeader[]>(
      "FinanceAPI",
      `references/${referenceType}/${reference}`
    );
  };

  getInvoiceExcel = (
    sapID: string,
    invoiceNumber: string
  ): SamskipPromise<Blob> => {
    return SamskipResource.getBlob(
      "FinanceAPI",
      `${sapID}/invoices/${invoiceNumber}/excel`
    );
  };

  getCustomerStatus = (
    sapID: string
  ): SamskipPromise<FinanceSAPCustomerStatus[]> => {
    return SamskipResource.get<FinanceSAPCustomerStatus[]>(
      "FinanceAPI",
      `${sapID}/status`
    );
  };

  getCustomerStatusOverPeriod = (
    sapID: string,
    dateFrom: Date,
    dateTo: Date
  ): SamskipPromise<FinanceSAPCustomerPeriodBalance[]> => {
    return SamskipResource.get<FinanceSAPCustomerPeriodBalance[]>(
      "FinanceAPI",
      `${sapID}/status/${dateFrom}/${dateTo}`
    );
  };

  getCompanyCodes = (sapID: string): SamskipPromise<any> => {
    return SamskipResource.get<any>("FinanceAPI", `${sapID}/companies`);
  };

  getBookingPaymentStatus = (
    jobReference: string,
    consignee: string
  ): SamskipPromise<BookingPaymentStatus> => {
    return SamskipResource.get<BookingPaymentStatus>(
      "FinanceAPI",
      `status/${consignee}/${jobReference}`
    );
  };

  getBookingPaymentStatuses = (
    references: BookingPaymentStatusVM[]
  ): SamskipPromise<BookingPaymentStatus[]> => {
    return SamskipResource.post<BookingPaymentStatus[]>(
      "FinanceAPI",
      `status/batchGet`,
      undefined,
      references
    );
  };

  openInvoiceUrl = (invoice: FinanceInvoiceHeader): void => {
    this.openPopupWindow(invoice.InvoiceHashUrl, invoice.SubsysInvoiceId);
  };

  openInvoice = (
    sapID: string,
    invoiceNumber: string,
    companyCode: number
  ): void => {
    const invoiceUrl = `${GlobalConfig.ENV.FinanceAPI}/${sapID}/${companyCode}/invoices/${invoiceNumber}`;

    this.openPopupWindow(invoiceUrl, invoiceNumber);
  };

  openMultipleInvoices = (
    sapID: string,
    invoiceNumbers: string,
    companyCodes: number
  ): void => {
    const invoiceUrl = `${GlobalConfig.ENV.FinanceAPI}/${sapID}/${companyCodes}/multiinvoices/${invoiceNumbers}`;

    this.openPopupWindow(invoiceUrl, invoiceNumbers);
  };

  private openPopupWindow = (url: string, description: string): void => {
    WindowUtilities.openPopup(url, description);
  };
}

export default new FinanceService();
