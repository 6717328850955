exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*#4f575e;*/\n.index-modules__label___dH0qG {\n  text-transform: uppercase;\n  color: #717171;\n  font-weight: 600;\n  font-size: 0.9em;\n}\n", ""]);

// exports
exports.locals = {
	"label": "index-modules__label___dH0qG"
};