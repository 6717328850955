import * as React from "react";
import * as ReactDOM from "react-dom";
import * as moment from "moment/moment.js";

import { TranslationService } from "Services";
import { languages } from "Services/TranslationService.ts";
import LiveChat from "react-livechat";

export default class Footer extends React.Component<FooterProps, {}> {
  links: Object = {
    is: {
      employess: "http://www.samskip.is/um-samskip/vinnustadurinn/starfsfolk",
      offices: "http://www.samskip.is/um-samskip/vinnustadurinn/skrifstofur/",
      terms: "http://www.samskip.is/um-samskip/skilmalar",
    },
    en: {
      employess: "http://www.samskip.com/contact/employees/",
      offices: "http://www.samskip.com/contact/offices-and-agents/",
      terms: "http://www.samskipnorthatlantic.com/terms",
    },
  };
  render() {
    return (
      <footer className="js-MainFooter">
        <div className="MainFooter-container u-posFit">
          <div className="MainFooter-content">
            <div className="MainFooter-info">
              <p>
                © {moment().format("YYYY")} Samskip hf | Kjalarvogur 7-15 | 104
                Reykjavik | {languages("LABEL_ICELAND")}
              </p>
              <p>
                Tel <span> +354 458 8000</span>
              </p>
              <p>
                E-mail
                <span>
                  <a href="mailto:customer.service@samskip.com">
                    {" "}
                    customer.service@samskip.com
                  </a>
                </span>
              </p>
            </div>
            <div className="MainFooter-links">
              <a href={this.links[this.props.lang || "is"].employess}>
                <span>{languages("FOOTER_EMPLOYEE")}</span>
              </a>
              <span>|</span>
              <a
                href={this.links[this.props.lang || "is"].offices}
                rel="noopener"
              >
                <span>{languages("FOOTER_OFFICEANDAGENTS")}</span>
              </a>
              <span>|</span>
              <a
                href={this.links[this.props.lang || "is"].terms}
                rel="noopener"
              >
                <span>{languages("FOOTER_TERMS")}</span>
              </a>
            </div>
          </div>
        </div>
        <LiveChat license={12450333} />
      </footer>
    );
  }
}
