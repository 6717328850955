import * as React from "react";
import * as ReactDOM from "react-dom";

import { SamskipNotify, languages } from "Services";
import { Button, Label, Icon, LabelProps } from "semantic-ui-react";
import { Download } from "Component";

import * as moment from "moment";

export default class Header extends React.Component<
  {
    site: string;
    count: number;
    openSideBar: any;
    labels: LabelProps[];
    removeFilter: Function;
    onExcel: Function;
  },
  {}
> {
  GetTranslationBySite = () => {
    if (this.props.site === "request") {
      return languages("LABEL_REQUESTS");
    } else if (this.props.site === "reception") {
      return languages("LABEL_RECEPTION", 2);
    }
    return "Unknown";
  };

  GetFileNameBySite = () => {
    if (this.props.site === "request") {
      return "RequestList";
    } else if (this.props.site === "reception") {
      return "ReceptionList";
    }
    return "file";
  };

  render() {
    const { count, openSideBar, labels, removeFilter } = this.props;
    return (
      <div className="ContentHeader">
        <h2 className="ContentHeader-headText">
          {this.GetTranslationBySite()}
        </h2>
        <div className="row">
          <div className="col-lg-12">
            <span className="ContentHeader-infoNumber">
              {`${count} ${this.GetTranslationBySite().toLowerCase()} fundust`}
            </span>
            <Button basic floated="right" onClick={openSideBar} icon="filter" />
            <Download
              title="Excel"
              onDownload={this.props.onExcel}
              onError={() =>
                SamskipNotify.displayWarning("ERROR_DOWNLOAD_FILE")
              }
              fileName={`${this.GetFileNameBySite()}-${moment().format(
                "DD-MM-YY"
              )}`}
              fileExtension="xlsx"
              mimeType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </div>
        </div>
        <div className="ContentHeader-filter row">
          <div className="col-lg-12">
            <div className="ContentHeader-filterFrame">
              <span>{languages("LABEL_FILTERBY")} </span>
              <Label basic as="a" size="large">
                {languages("LABEL_PERIOD")}
                <Icon name="delete" />
              </Label>
              {labels.map((label, i) => {
                return (
                  <Label key={i} basic as="a" size="large">
                    {label.content ? languages(label.content.toString()) : null}
                    <Icon
                      name="delete"
                      onClick={() => removeFilter(label.key)}
                    />
                  </Label>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
