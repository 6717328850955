import { Input, Modal } from "Component";
import { MAJOR_STATES } from "Constants/RouteConstants";
import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { languages, UserService } from "Services";
import LoadingTextModalService from "Services/LoadingTextModalService";
import { reaction } from "mobx";

export default class RefreshSessionModal extends Component<
  { open: any; close: Function; userName?: string },
  { password: string; isLoggingIn: boolean; lastAccessTokenTime: number }
> {
  state = { password: "", isLoggingIn: false, lastAccessTokenTime: 0 };
  renderHeader = () => {
    return (
      <>
        <span className="title">{languages("TEXT_LOGIN")}</span>
      </>
    );
  };

  listener = async (e: KeyboardEvent) => {
    if (e.key === "Enter" && !this.state.isLoggingIn && this.props.open) {
      await this.submitPassword();
    }
  };

  componentDidMount() {
    reaction(
      () => UserService.isLoginInProgress(),
      () => {
        this.setState({ isLoggingIn: true });
      }
    );

    reaction(
      () => UserService.getIsRefreshSessionModalOpen(),
      () => {
        if (!UserService.getIsRefreshSessionModalOpen()) {
          this.handleCloseModal();
        }
      }
    );

    document.addEventListener("keydown", this.listener);
  }

  async componentWillUnmount() {
    document.removeEventListener("keydown", this.listener);
  }

  componentDidUpdate = (prevProps: any) => {
    if (prevProps.open === false && this.props.open === true) {
      this.onOpen();
    }
  };

  renderContent = () => {
    return (
      <>
        <p>{languages("TEXT_SESSION_EXPIRED")}</p>
        <Input
          type="password"
          placeholder={languages("LABEL_PASSWORD")}
          onChange={(d: string) => this.setState({ password: d })}
          value={this.state.password}
        />
        <div className="footer">
          <Button floated="left" onClick={() => this.logoutClick()}>
            {languages("TEXT_LOGOUT")}
          </Button>
          <Button
            floated="right"
            primary
            loading={UserService.isLoginInProgress()}
            disabled={!this.state.password}
            onClick={() => {
              this.submitPassword();
            }}
          >
            {languages("LABEL_LOGIN")}
          </Button>
        </div>
      </>
    );
  };

  submitPassword = async () => {
    const userlogin: UserLogin = {
      Username: this.props.userName || "",
      Password: this.state.password,
      Email: "",
    };
    return await UserService.login(userlogin)
      .then((res: any) => {
        if (res) {
          this.handleCloseModal();
        }
      })
      .finally(() => {
        this.setState({ isLoggingIn: false });
      });
  };

  logoutClick = () => {
    UserService.logout()
      .then(() => {
        this.handleCloseModal();
        LoadingTextModalService.modalON("TEXT_LOGOUTTEXT", "fa fa-lock");
      })
      .finally(() => {
        window.location.href = `/${MAJOR_STATES.login}`;
      });
  };

  handleCloseModal = () => {
    this.props.close();
    this.setState({ password: "" });
  };

  onOpen = () => {
    this.setState(
      {
        lastAccessTokenTime: new Date(
          localStorage.getItem("accessTokenTime") as string
        ).getTime(),
      },
      () => {
        var interval = setInterval(() => {
          let accessTokenTime = new Date(
            localStorage.getItem("accessTokenTime") as string
          ).getTime();
          if (
            this.state.lastAccessTokenTime !== 0 &&
            accessTokenTime > this.state.lastAccessTokenTime
          ) {
            clearInterval(interval);
            this.handleCloseModal();
            UserService.resetPasswordModalTimer();
          }
        }, 10000);
      }
    );
  };

  render() {
    return (
      <Modal
        header={this.renderHeader()}
        open={this.props.open}
        content={this.renderContent()}
      />
    );
  }
}
