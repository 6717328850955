import * as React from "react";
import { Radio } from "react-bootstrap";

import styles from "./index.modules.less";
import Image from "./radio.png";

export default (props: any) => {
  const onClick = (e: any, value: string) => {
    e.parentNode.childNodes[0].click();

    props.onChange(value);
  };

  return (
    <>
      {props.data.map(({ value, text }: RadioButton, i: number) => (
        <Radio
          name={props.name ? props.name : "group"}
          key={i}
          className={styles.radio}
          checked={value === props.value}
          value={value}
          onChange={() => props.onChange(value)}
        >
          <label
            style={{ background: `url(${Image}) no-repeat` }}
            onClick={(e: any) => onClick(e.target, value)}
          >
            {text}
          </label>
        </Radio>
      ))}
    </>
  );
};
