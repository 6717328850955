import React, { useState, useEffect } from "react";

import styles from "./index.modules.less";

const Spinner = require("spin.js");
const opts: SpinnerOptions = {
  lines: 12,
  length: 18,
  width: 4,
  radius: 24,
  corners: 1,
  rotate: 0,
  direction: 1,
  color: "#002378",
  speed: 0.7,
  trail: 60,
  shadow: false,
  hwaccel: false,
  className: "spinner",
  zIndex: 2e9,
  top: "50%",
  left: "50%",
};

export default (props: SpinnerProps) => {
  const [target, setTarget] = useState(null as any);
  const [spinner, setSpinner] = useState(null as any);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setSpinner(new Spinner(opts));
  }, []);

  useEffect(() => {
    if (spinner && target) {
      if (!props.isHidden) {
        start();
      } else {
        stop();
      }
    }
  }, [props, target, spinner]);

  const start = () => {
    spinner.spin(target);
    setActive(true);
  };

  const stop = () => {
    // Stop spinner after fading out
    setTimeout(() => {
      spinner.stop();
    }, 300);
    setActive(false);
  };

  return (
    <div
      className={`${styles.wrapper} ${active ? styles.active : ""} ${
        props.className
      }`}
      ref={(spinner: HTMLDivElement) => {
        setTarget(spinner);
      }}
    />
  );
};
