import * as React from "react";
import { Button } from "semantic-ui-react";
import "semantic-ui-less/semantic.less";

import { saveAs } from "file-saver";

import styles from "./index.modules.less";

export default class extends React.Component<any, any> {
  state = { fetching: false };
  onDownload() {
    this.setState(
      { fetching: true },
      () =>
        this.props.onDownload &&
        this.props.onDownload().then(
          (data: ArrayBuffer) => {
            this.setState({ fetching: false });

            saveAs(
              new Blob([data], {
                type: this.props.mimeType
              }),
              `${this.props.fileName}.${this.props.fileExtension}`
            );
          },
          () => {
            this.setState({ fetching: false }, this.props.onError);
          }
        )
    );
  }
  render() {
    const { title } = this.props;

    return (
      <Button
        basic
        className={`${styles.download}`}
        floated="right"
        onClick={() => this.onDownload()}
      >
        {this.state.fetching && <i className="fa fa-spinner fa-pulse"></i>}
        {!this.state.fetching && <i className="fa fa-download"></i>}

        {title}
      </Button>
    );
  }
}
