import { observable, computed, action, toJS } from "mobx";

class ContainerStore {
  @observable private containerValue?: Container;

  @computed get container(): Container | undefined {
    return this.containerValue;
  }

  @action setContainer = (container: Container): void => {
    this.containerValue = container;
  };
}

export default new ContainerStore();
