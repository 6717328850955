import { Object } from "es6-shim";

class UserCacheStore {
  private readonly localStorageUserKey: string = "SamskipServiceWeb.user";

  public getUserProfile = (): UserProfile | null | undefined => {
    const user: any = localStorage.getItem(this.localStorageUserKey);

    return <UserProfile>JSON.parse(user);
  };

  public clearUserProfile = (): void => {
    localStorage.removeItem(this.localStorageUserKey);
  };

  public setUserProfile = (userProfile: UserProfile): void => {
    const newUserProfile = Object.assign({}, userProfile);
    localStorage.setItem(this.localStorageUserKey, JSON.stringify(userProfile));
  };
}

export default new UserCacheStore();
