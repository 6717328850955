import * as React from "react";
import * as ReactDOM from "react-dom";
import { FormGroup, FormControl } from "react-bootstrap";
import Select from "react-select";
import "react-select/dist/react-select.css";

import styles from "./index.modules.less";

export default class extends React.Component<SelectProps, {}> {
  onChange = (selected: any) => {
    const value = selected && (selected.value ? selected.value : selected);

    this.props.onChange && this.props.onChange(value);
  };

  render() {
    const {
      value,
      options,
      noResultsText,
      placeholder,
      multiples
    } = this.props;

    return (
      <FormGroup>
        <Select
          name="select"
          className={styles.select}
          value={value}
          options={options}
          placeholder={placeholder}
          multi={multiples}
          noResultsText={noResultsText}
          onChange={this.onChange}
        />
      </FormGroup>
    );
  }
}
