import SamskipResource from "SamskipResource";

class UserManagerService {
  getWebRoles = (userID: number, subsys: number): SamskipPromise<WebRole[]> => {
    return SamskipResource.get(
      "AuthAPI",
      `usermanager/users/${userID}/WebRoles`,
      { subsys }
    );
  };

  getUserList = (
    search: string,
    inCompany: boolean
  ): SamskipPromise<UserViewModel[]> => {
    return SamskipResource.get<UserViewModel[]>(
      "AuthAPI",
      "usermanager/users",
      {
        search,
        inCompany
      }
    );
  };

  getUserAccess = (
    userID: number,
    userSubSys: number
  ): SamskipPromise<UserManagerUserAccess> => {
    return SamskipResource.get<UserManagerUserAccess>(
      "AuthAPI",
      `usermanager/users/${userID}/access/${userSubSys}`
    );
  };

  createUser = (userVM: any): SamskipPromise<any> => {
    return SamskipResource.post("AuthAPI", "usermanager/users", "", {
      User: userVM.User,
      Access: userVM.Access,
      Roles: userVM.Roles,
      Companies: userVM.Companies,
      Departments: userVM.Departments,
      Hubs: userVM.Hubs
    });
  };

  updateUser = (userVM: any): SamskipPromise<any> => {
    return SamskipResource.put("AuthAPI", "usermanager/users", "", {
      User: userVM.User,
      Access: userVM.Access,
      Roles: userVM.Roles,
      Companies: userVM.Companies,
      Departments: userVM.Departments,
      Hubs: userVM.Hubs
    });
  };

  deleteUser = (userID: number): SamskipPromise<any> => {
    return SamskipResource.delete("AuthAPI", `usermanager/users/${userID}`);
  };

  userRequest = (userVM: any): SamskipPromise<any> => {
    return SamskipResource.post("AuthAPI", "usermanager/userRequest", "", {
      User: userVM.User,
      Access: userVM.Access,
      Roles: userVM.Roles,
      Companies: userVM.Companies,
      Departments: userVM.Departments,
      Hubs: userVM.Hubs
    });
  };

  sendUserRequestEmail = (userID: number): SamskipPromise<void> => {
    return SamskipResource.post(
      "AuthAPI",
      `usermanager/userrequestemail/${userID}`
    );
  };

  searchUserCompanies = (
    userID: number,
    searchString: string,
    subsys: number | null
  ): SamskipPromise<Company[]> => {
    return SamskipResource.get<Company[]>(
      "AuthAPI",
      `usermanager/users/${userID}/companies/search/${searchString}`,
      { subsys }
    );
  };

  loginIdIsTaken = (loginId: string): SamskipPromise<boolean> => {
    return SamskipResource.get<boolean>(
      "AuthAPI",
      `usermanager/users/${loginId}/`
    );
  };
}

export default new UserManagerService();
