import * as React from "react";
import {
  Dropdown,
  DropdownItemProps,
  DropdownProps as DropdownPropsSemantic,
} from "semantic-ui-react";

import styles from "./index.less";
import { TranslationService } from "Services";

// The props from the Semantic UI Dropdown except onChange function requires only one parameter
interface DropdownProps extends Omit<DropdownPropsSemantic, "onChange"> {
  onChange: (data: any) => void;
}

export default (props: DropdownProps) => {
  const { onChange, label, id, disabled, ...rest } = props;

  const renderLabel = () => {
    return (
      <label htmlFor={id} className="label">
        {label}
      </label>
    );
  };

  return (
    <>
      {label && renderLabel()}
      <Dropdown
        fluid
        selection
        noResultsMessage={TranslationService.translate("TEXT_NORESULTS")}
        upward={false}
        {...rest}
        onChange={(e: any, data: any) => {
          onChange(data.value);
        }}
        disabled={disabled}
        className={`${styles.dropdown} ${disabled && styles.disabled}`}
      />
    </>
  );
};
