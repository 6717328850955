import NotificationStore from "Stores/NotificationStore";
import { UserService } from "Services";
import { NOTIFICATION_EVENTS } from "Constants/NotificationConstants";
import SamskipResource from "SamskipResource";
import { toJS } from "mobx";

declare var Promise: any;

class NotificationService {
  getLocalNotifications = (): UserNotification[] => {
    return NotificationStore.notifications;
  };

  getUnreadNotifications = (): number => {
    return NotificationStore.unreadNotifications.get();
  };

  getTotalNotifications = (): number => {
    return NotificationStore.totalNotifications;
  };

  getLatestNotifications = (): UserNotification[] => {
    const { Notifications } = NotificationStore.latestNotifications;

    return Notifications;
  };

  // Get latest notifications from server
  async getLatestNotificationsFromServer(): Promise<UserLatestNotifications> {
    const currentUser = UserService.getUserProfile();
    if (!currentUser) return Promise.reject("UserProfile not loaded");

    const notifications = await SamskipResource.get<UserLatestNotifications>(
      "NotificationAPI",
      `users/${currentUser.Access.UserID}/notifications/latest`
    );

    NotificationStore.setLatestNotifications(notifications);
    NotificationStore.setUnreadCount(notifications.UnreadCount);

    return notifications;
  }

  // Get notifications from server and load into cache
  async getNotificationsFromServer(): Promise<UserNotification[]> {
    const currentUser = UserService.getUserProfile();
    if (!currentUser) return Promise.reject("UserProfile not loaded");

    const userID = currentUser.Access.UserID;
    const notifications = await SamskipResource.get<UserNotification[]>(
      "NotificationAPI",
      `users/${userID}/notifications`
    );

    NotificationStore.setNotifications(notifications);

    return notifications;
  }

  getNotification = (
    notificationID: number
  ): SamskipPromise<UserNotificationDetail> => {
    const currentUser = UserService.getUserProfile();
    if (!currentUser) return Promise.reject("UserProfile not loaded");

    const userID = currentUser.Access.UserID;

    return SamskipResource.get<UserNotificationDetail>(
      "NotificationAPI",
      `users/${userID}/notifications/${notificationID}`
    );
  };

  setNotifications = (notifications: Notification[]) => {
    NotificationStore.setNotifications(notifications);
  };

  setLatestNotifications = (latestNotifications: UserLatestNotifications) => {
    NotificationStore.setLatestNotifications(latestNotifications);
  };

  // Mark notification as read on server and in cache
  markNotificationAsRead = (
    notificationID: number
  ): SamskipPromise<boolean> => {
    const currentUser = UserService.getUserProfile();
    if (!currentUser) return Promise.reject("UserProfile not loaded");

    const userID = currentUser.Access.UserID;

    NotificationStore.markNotificationRead(notificationID);
    return SamskipResource.put<boolean>(
      "NotificationAPI",
      `users/${userID}/notifications/${notificationID}`
    );
  };
}

export default new NotificationService();
