import { API_REQUEST_STATUSES } from "Constants/APIResponseConstants";
import SamskipResource from "SamskipResource";

class TruckingService {
  /**
   * Get eta for containers in single booking by request ID
   * @param {string} requestId   Doris request id
   * @return {any}               Eta for single request
   */
  getContainerEtaByRequestId = (requestId: string): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<any>>(
        "SamskipAPI",
        `requests/${requestId}/ETA`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };
}

export default new TruckingService();
