import { UIRouter, Transition, Trace } from "@uirouter/core";
import "whatwg-fetch";
import { $templateCache, $exceptionHandler } from "ngimport";
import * as paginationTemplate from "Templates/vendor/overwrites/ui-bootstrap/uib-pagination.html";
import * as multiSelectTemplate from "Templates/vendor/overwrites/ui-bootstrap/ui-select-match-multiple.html";
import UserStore from "Stores/UserStore";
import NavStore from "Stores/NavStore";
import TranslationStore from "Stores/TranslationStore";
import FeatureToggleRouter from "Components/samFeatureToggle/FeatureToggleRouter";
import LoadingTextModalService from "Services/LoadingTextModalService";
import {
  UserService,
  NotificationService,
  TranslationService,
  SamskipNotify,
} from "Services";

import { UtilityFunctions } from "Utilities";
import transitions from "Routing";
import "./polyfill";
import GeneralLoader from "Utilities/GeneralLoader";
import AlertsService from "Services/AlertsService";

declare var GlobalConfig: GlobalConfig;
declare var window: any;

export let $translate: ng.translate.ITranslateService;
export let $uibModal: ng.ui.bootstrap.IModalService;
export let $uibPosition: any;
export let $uiRouter: UIRouter;
export let $translateDefaultInterpolation: any;
export let $pusher: any;

const debug = process.env.DEBUG;
const transitionTrace = true;

class Run {
  static $inject: string[] = [
    "$injector",
    "$rootScope",
    "$trace",
    "$q",
    "$http",
  ];

  constructor(
    $injector: ng.auto.IInjectorService,
    $rootScope: ng.IRootScopeService,
    $trace: Trace,
    $q: ng.IQService,
    $http: ng.IHttpService
  ) {
    $translate = <ng.translate.ITranslateService>$injector.get("$translate");
    $uibModal = <ng.ui.bootstrap.IModalService>$injector.get("$uibModal");
    $uibPosition = <ng.ui.bootstrap.IPositionService>(
      $injector.get("$uibPosition")
    );
    $uiRouter = <UIRouter>$injector.get("$uiRouter");
    $pusher = <any>$injector.get("$pusher");
    $translateDefaultInterpolation = <any>(
      $injector.get("$translateDefaultInterpolation")
    );

    sessionStorage.setItem("AlertBodyHtml", "");
    sessionStorage.setItem("AlertHeading", "");
    checkAlert();

    // Enable ui-router trace of transitions
    if (debug && transitionTrace) {
      $trace.enable("TRANSITION");
    }

    $uiRouter.urlService.rules.otherwise({ state: "home" });
    $uiRouter.urlService.config.strictMode(false);

    // Templates that can be used throughout the application
    $templateCache.put("uib-pagination.html", paginationTemplate);
    $templateCache.put(
      "bootstrap/match-multiple.tpl.html",
      multiSelectTemplate
    );

    transitions.transitions.forEach((trans: any) => {
      $uiRouter.transitionService[trans.hook](
        trans.match,
        trans.hookFn,
        trans.matchOptions
      );
    });

    let hasStarted = false;

    $uiRouter.transitionService.onBefore({}, function (transition) {
      if (!hasStarted) {
        hasStarted = true;
        GeneralLoader.increase();

        sessionStorage.setItem("AlertBodyHtml", "");
        sessionStorage.setItem("AlertHeading", "");
      }
    });

    function checkAlert() {
      AlertsService.getAlertRoute({
        Route: window.location.pathname,
        Company: 1000,
      }).then((data: any) => {
        if (data.ReturnItem.Data) {
          if (TranslationStore.getSelectedLanguage() === "en") {
            SamskipNotify.displayDynamic(
              data.ReturnItem.Data.Type,
              data.ReturnItem.Data.BodyHtmlEN,
              data.ReturnItem.Data.HeadingEN
            );
          } else {
            SamskipNotify.displayDynamic(
              data.ReturnItem.Data.Type,
              data.ReturnItem.Data.BodyHtmlIS,
              data.ReturnItem.Data.HeadingIS
            );
          }
        }
      });
    }

    $uiRouter.transitionService.onSuccess({}, function (transition) {
      GeneralLoader.decrease();
      hasStarted = false;
      transition.promise.then((x) => {
        if (x.url != "/") {
          checkAlert();
        }
      });
    });

    $uiRouter.transitionService.onError({}, function (transition) {
      GeneralLoader.decrease();
      hasStarted = false;
      transition.promise.then((x) => {
        if (x.url != "/") {
          checkAlert();
        }
      });
    });

    // Register a default error handler for transitions in ui-router
    $uiRouter.stateService.defaultErrorHandler(transitions.defaultErrorHandler);

    // window.Promise = $q;

    // Translation events
    $rootScope.$on("$translateChangeStart", () => {
      TranslationStore.translationsChanged(false);
    });
    $rootScope.$on("$translateChangeSuccess", (event, lang) => {
      TranslationStore.translationsChanged(true);
      TranslationStore.language(lang.language);
    });
    $rootScope.$on("$translateChangeError", () => {
      TranslationStore.translationsChanged(false);
    });
    $rootScope.$on("$translateLoadingStart", () => {
      TranslationStore.translationsLoaded(false);
    });
    $rootScope.$on("$translateLoadingSuccess", (event, lang) => {
      TranslationStore.translationsLoaded(true);
    });
    $rootScope.$on("$translateLoadingError", () => {
      TranslationStore.translationsLoaded(false);
    });
  }
}

export default Run;
