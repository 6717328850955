class StringUtilities {
  truncate(value: string, length: number, tail?: string | null) {
    if (value.length <= length) {
      return value;
    }
    let ret = value.substr(0, length);
    if (tail) {
      ret += tail;
    }
    return ret;
  }
}

export default new StringUtilities();
