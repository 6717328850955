exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index-modules__checkbox___cSzje {\n  min-height: 22px;\n  margin-top: 0;\n  margin-bottom: 0;\n  padding-left: 0;\n}\n.index-modules__checkbox___cSzje label {\n  padding-left: 0;\n}\n.index-modules__checkbox___cSzje label input {\n  position: absolute;\n  overflow: hidden;\n  clip: rect(0 0 0 0);\n  height: 1px;\n  width: 1px;\n  margin: -1px;\n  padding: 0;\n  border: 0;\n}\n.index-modules__checkbox___cSzje label input:checked + label {\n  background-position: 0 -15px !important;\n}\n.index-modules__checkbox___cSzje label input:focus + label:after {\n  content: \" \";\n  position: absolute;\n  top: 5px;\n  left: 0px;\n  height: 15px;\n  width: 15px;\n  border: 1px solid;\n  border-radius: 5px;\n  border-color: rgba(82, 168, 236, 0.8);\n  outline: 0;\n  outline: thin dotted \\9;\n  -moz-box-shadow: 0 0 8px rgba(82, 168, 236, 0.6);\n  box-shadow: 0 0 8px rgba(82, 168, 236, 0.6) !important;\n}\n.index-modules__checkbox___cSzje label label {\n  padding-left: 30px !important;\n  min-height: 15px !important;\n  height: 15px;\n  display: inline-block;\n  line-height: 15px;\n  background-repeat: no-repeat;\n  background-position: 0 0;\n  font-size: 15px;\n  font-weight: 400;\n  vertical-align: middle;\n  letter-spacing: -0.02em;\n  cursor: pointer;\n  background-position: 0px 0px;\n  background-size: 15px 30px;\n}\n", ""]);

// exports
exports.locals = {
	"checkbox": "index-modules__checkbox___cSzje"
};