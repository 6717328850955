import * as React from "react";
import * as ReactDOM from "react-dom";

import { Pagination } from "semantic-ui-react";

import styles from "./index.less";
import content from "*.svg";
import { languages } from "Services";

export default class extends React.Component<
  {
    totalPages: number;
    page: number;
    onPageChange: Function;
    disabled: boolean;
  },
  any
> {
  render() {
    const { totalPages, page, onPageChange, disabled } = this.props;
    return (
      <Pagination
        className={styles.paginator}
        size="massive"
        activePage={page}
        totalPages={totalPages}
        firstItem={{
          content: languages("LABEL_FIRSTPAGE"),
          disabled: page === 1
        }}
        prevItem={{
          content: languages("LABEL_PREVIOUSPAGE"),
          disabled: page === 1
        }}
        nextItem={{
          content: languages("LABEL_NEXTPAGE"),
          disabled: page === totalPages
        }}
        lastItem={{
          content: languages("LABEL_LASTPAGE"),
          disabled: page === totalPages
        }}
        onPageChange={(e, d) => onPageChange(d.activePage)}
        disabled={disabled}
      ></Pagination>
    );
  }
}
