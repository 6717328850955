import { API_REQUEST_STATUSES } from "Constants/APIResponseConstants";
import SamskipResource from "SamskipResource";
import { WindowUtilities } from "Utilities";

declare var GlobalConfig: GlobalConfig;

class DomesticService {
  companyTransports = (
    dateFrom: string,
    dateTo: string,
    company: string
  ): SamskipPromise<DomesticTransportsTileVM> => {
    if (!company) throw new Error("Company must be defined");
    return SamskipResource.get<APIResponse<DomesticTransportsTileVM>>(
      "SamskipAPI",
      `domestic/bookings/srvweb/${company}/total`,
      { dateFrom, dateTo }
    ).then((res) => {
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        return res.ReturnItem.Data;
      } else {
        throw new Error("API request failed");
      }
    });
  };

  getTransportsList = (
    dateFrom: string,
    dateTo: string
  ): SamskipPromise<DomesticTransport[]> => {
    return SamskipResource.get<DomesticTransport[]>(
      "DomesticAPI",
      "bookings/partners/all",
      {
        dateFrom,
        dateTo,
      }
    );
  };

  companyTransportsList = (
    dateFrom: string,
    dateTo: string,
    company: string
  ): Promise<any> => {
    if (!company) {
      return Promise.reject(new Error("Company must be defined"));
    }
    return SamskipResource.get<APIResponse<DomesticTransport[]>>(
      "SamskipAPI",
      `domestic/bookings/srvweb/${company}`,
      { dateFrom, dateTo }
    ).then((res) => {
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        return res.ReturnItem.Data;
      } else {
        throw new Error("API request failed");
      }
    });
  };

  // Get a list of hubs
  getHubs = (): SamskipPromise<Hub[]> => {
    return SamskipResource.get<Hub[]>("DomesticAPI", `registry/hubs`);
  };

  searchCompanyTransports = (
    searchString: string,
    ssn: string
  ): SamskipPromise<DomesticTransport[]> => {
    return SamskipResource.get<APIResponse<DomesticTransport[]>>(
      "SamskipAPI",
      `domestic/bookings/srvweb/search`,
      { ssn, searchString }
    ).then((res) => {
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        return res.ReturnItem.Data;
      } else {
        throw new Error("API request failed");
      }
    });
  };

  transportsInExcel = (
    SSN: string,
    dateFrom: string,
    dateTo: string,
    bookings: string[]
  ): SamskipPromise<File> => {
    return SamskipResource.get(
      "SamskipAPI",
      `domestic/bookings/srvweb/${SSN}/excel`,
      { dateFrom, dateTo }
    );
  };

  getTransport = (
    SSN: string,
    bookingNumber: string,
    bookingReference: string
  ): SamskipPromise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      const res = await SamskipResource.get<
        APIResponse<DomesticTransportWaybill>
      >("SamskipAPI", `domestic/bookings/srvweb/${SSN}/way-bill`, {
        bookingReference,
        bookingNumber,
      });
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    }) as SamskipPromise<any>;
  };

  getTransports = (
    SSN: string,
    bookingNumber: string,
    shipmentID: string
  ): SamskipPromise<any> => {
    const params = {
      bookingNumber: shipmentID,
      bookingReference: bookingNumber,
    };
    return new Promise<any>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<any>>(
        "SamskipAPI",
        `domestic/bookings/srvweb/${SSN}/`,
        params
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    }) as SamskipPromise<any>;
  };

  // NOT SURE IF THIS ONE IS

  getSignature = (
    bookingNumber: string
  ): SamskipPromise<DomesticTransportEpodSignature> => {
    return SamskipResource.get<DomesticTransportEpodSignature>(
      "SamskipAPI",
      `domestic/epod/${bookingNumber}/signatures`
    );
  };

  getUserHubIds = (
    userId: number,
    subsys: number
  ): SamskipPromise<string[]> => {
    return SamskipResource.get(
      "DomesticAPI",
      `registry/hubIds/${userId}/${subsys}`
    );
  };

  getUserHubs = (userHubsIds: string[]): SamskipPromise<string[]> => {
    return SamskipResource.get(
      "DomesticAPI",
      `registry/userHubs/${userHubsIds}`
    );
  };

  downloadSignaturesPDF = (shipmentIds: string[]): SamskipPromise<Blob> => {
    return SamskipResource.postBlob(
      "SamskipAPI",
      "domestic/epod/signatures/pdf",
      undefined,
      shipmentIds
    );
  };

  openSignature = (signatureId: string): void => {
    const docUrl = `${GlobalConfig.ENV.SamskipAPI}/domestic/epod/${signatureId}/signatures/pdf`;

    WindowUtilities.openPopup(docUrl, signatureId);
  };

  getShipmentCollectionForwarding = (shipmentIds: string[]): Promise<any[]> => {
    return new Promise<any>(async (resolve, reject) => {
      const res = await SamskipResource.post<APIResponse<any>>(
        "SamskipAPI",
        `domestic/forwarding/collections`,
        undefined,
        { shipmentIds }
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  // OldServiceWeb BarCode + DIMA
  openBarCodeDima = (shipmentID: string): void => {
    const docUrl = `${GlobalConfig.ENV.OldServiceWeb}/DomForwardReciept.aspx?collectionid=${shipmentID}`;
    WindowUtilities.openPopup(docUrl, shipmentID);
  };

  // new BarCode - SINNA

  openBarCodeSinna = (shipmentID: string): void => {
    const docUrl = `${GlobalConfig.ENV.NewServiceWeb}/domestic/labels/${shipmentID}?noLayout=1`;
    WindowUtilities.openPopup(docUrl, shipmentID);
  };

  openBarcode = (shipmentID: string, bookingType: string): void => {
    if (bookingType == "SINNA") {
      return this.openBarCodeSinna(shipmentID);
    }
    if (bookingType == "DIMA") {
      return this.openBarCodeDima(shipmentID);
    }
  };

  /**
   * Each booking contains TrackTraceStatus which can be any of the following:
   * 10       Skráð                     Flutningseiningar í forbókaðri sendingu.
   * 20       Sótt                      TBD
   * 30       Móttekið                  Pakki skannaður í hurð á móttökustað.
   * 35       Vigtað og mælt            Pakki/eining vigtuð og rúmmálsmæld
   * 40       Tilbúið til flutnings     Kominn í lestunarreit (einnig safnkassi)
   * 50       Í flutningi               Hleðslu lokað.(Load lokað í Díma)
   * 60       Losað á afhendingarstað   Skannaður úr bíl á endastað.
   * 70       Tilbúið til afhendingar   Skannað í afhendingarhólf
   * 80       Í heimsendingu            TBD
   * 90       Afhent                    Pakki skannaður í hurð á afhendingarstað.
   * 99       Hætt við                  Pakki/eining (strikamerki eytt út)
   *
   * The functions returns values from 0 - 5, compared to TrackTraceStatus.
   *
   * 1       Skráð                     Flutningseiningar í forbókaðri sendingu.
   *                                   Sending bókuð í móttöku.
   * 2       Móttekið                  Pakki skannaður í hurð á móttökustað.
   * 3       Í flutningi               Hleðslu lokað.(Load lokað í Díma)
   * 4       Tilbúin                   Skannað í afhendingarhólf
   * 5       Afhent                    Pakki skannaður í hurð á afhendingarstað.
   * 0       Hætt við                  Pakki/eining (strikamerki eytt út)
   */

  /* Added 25.09.2032 - From Sinna 
    99 - > Canceled 
    10 - 25 -> booked 
    30 - 48 -> Recieved
    50 - 58 -> In transport
    60 - 80 ->  Ready
    90 -> Delivered
            
         * ID       Name                      Org description                                Added                   Boltrics Status | Boltrics description
         * 0        ?                         ?                                              25.09.2023              00-STEPS    ->  Steps
         * 05       ?                         ?                                              25.09.2023              05-DELETE   ->  Delete order
         * 10       Skráð                     Flutningseiningar í forbókaðri sendingu.                               10-CREATED  ->  Sending hefur verið Skráð ( Order created?)
         * 14       ?                         ?                                              25.09.2023              14-RELEASED ->  Shipment is released for planning
         * 16       ?                         ?                                              25.09.2023              16-PLANNED  ->  Shipment is planned   
         * 20       Sótt                                                                                             20-PICKUP   ->  Sending hefur verið skönnuð í (Order scanned to vehicle?)
         * 22       ?                         ?                                              25.09.2023              22-DELIVERBYCUSTOMER -> Delivered by customer
         * 23       ?                         ?                                              25.09.2023              23-DELIVERBYPARTNER ->  Delivered by Partner
         * 24       ?                         ?                                              25.09.2023              24-MEASUREDBYPARTNER -> Measured by Partner
         * 25       ?                         ?                                              25.09.2023              25-PARTIALDELIVERY ->  Partial delivery split
         * 25       ?                         ?                                              25.09.2023              25-TEMP -> Temp
         * 30       Móttekið                  Pakki skannaður í hurð á móttökustað.                                  30-RECEIVED -> Sending hefur verið skönnuð í vöruhús (Delivery scanned into warehouse?)
         * 35       Vigtað og mælt            Pakki/eining vigtuð og rúmmálsmæld                                     35-MEASURED -> Sending hefur verið vigtuð og mæld (Order has been weighed and measured?)                          
         * 40       Tilbúið til flutnings     Kominn í lestunarreit (einnig safnkassi)                               40-RDYTRANSIT -> Sending skönnuð á lestunarreit (Order has been scanned at point of delivery)
         * 48       ?                         ?                                              25.09.2023              48-PARTINTRANSIT -> Partially in transit
         * 50       Í flutningi               Hleðslu lokað.(Load lokað í Díma)                                      50-INTRANSIT -> Sending er í flutningi ( Order is in transit?)   
         * 52       ?                         ?                                              25.09.2023              52-RELEASEPARTLOAD -> Release part loaded (not allowed lower status!)
         * 58       ?                         ?                                              25.09.2023              58-PARTFINALHUB -> Partially at final hub
         * 60       Losað á afhendingarstað   Skannaður úr bíl á endastað.                                           60-FINALHUB -> Sending er komin á áfangastað ( Order at final location ? / last place ?)
         * 65       ?                         ?                                              25.09.2023              65-FINALLOC -> Sending er final location ( Order at final location ? / last place ?)
         * 66       ?                         ?                                              25.09.2023              66-FINALLOCNOMAIL -> No Mail/SMS final location
         * 70       Tilbúið til afhendingar   Skannað í afhendingarhólf                                              70-RDYDELIVERY -> Sending er tilbúin til afhendingar ( Order ready for delivery?)
         * 75       ?                         ?                                              25.09.2023              75-PICKUP -> Pickup
         * 80       Í heimsendingu            TBD                                                                    80-INDELIVERY -> Sending er í afhendingu ( Order is in delivery?)
         * 90       Afhent                    Pakki skannaður í hurð á afhendingarstað.                              90-DELIVERED -> Sending hefur verið afhent (Order is delivered?)
         * 91       ?                         ?                                              25.09.2023              91-CORRECT -> Revenue lines corrected
         * 95       ?                         ?                                              25.09.2023              95-SPLITPARTIALORDER -> Split partial order status
         * 96       ?                         ?                                              25.09.2023              96-SETSOURCEORDERSTA -> Set source order status
         * 99       Hætt við                  Pakki/eining (strikamerki eytt út)                                     99-CANCELLED -> Hætt við sendingu" ( Canceled)
  */
  trackTraceStatusToTileStatus = (status: number): number => {
    let tileStatus = 0;
    if (inRange(status, 0, 29)) {
      tileStatus = 1;
    } else if (inRange(status, 30, 49)) {
      tileStatus = 2;
    } else if (inRange(status, 50, 58)) {
      tileStatus = 3;
    } else if (inRange(status, 60, 89)) {
      tileStatus = 4;
    } else if (inRange(status, 90, 96)) {
      tileStatus = 5;
    } else if (status === 99) {
      tileStatus = 9;
    }

    return tileStatus;

    // TODO: Move to some utility module
    function inRange(input: number, n1: number, n2: number) {
      return input >= n1 && input <= n2;
    }
  };

  /**
   * Text that will be displayed representing
   * the status of each booking
   */
  getTileStatusText = (tileStatus: number): string => {
    switch (tileStatus) {
      case 1:
        return "LABEL_BOOKED";
      case 2:
        return "LABEL_DATE_RECEIVED";
      case 3:
        return "LABEL_INTRANSPORT";
      case 4:
        return "LABEL_READY";
      case 5:
        return "LABEL_DELIVERED";
      case 9:
        return "LABEL_CANCELED";
      default:
        return "LABEL_NOTAPPLICABLE";
    }
  };

  getTileStatusClass = (tileStatus: number): string => {
    switch (tileStatus) {
      case 1:
        return "dom-registered";
      case 2:
        return "dom-received";
      case 3:
        return "dom-transporting";
      case 4:
        return "dom-ready";
      case 5:
        return "dom-delivered";
      default:
        return "";
    }
  };
}

export default new DomesticService();
