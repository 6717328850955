import * as React from "react";
import { Checkbox, Label } from "semantic-ui-react";
import styles from "./index.modules.less";

export default class extends React.Component<ToggleProps, ToggleState> {
  render() {
    const { onLabel, offLabel, onChange, value, bothOn } = this.props;
    return (
      <div className={styles.toggle}>
        {offLabel && (
          <Label
            className={`${styles.label} ${value ? "" : styles.darker}`}
            onClick={() => onChange(false)}
          >
            {offLabel}
          </Label>
        )}
        <Checkbox
          className={`${styles.checkbox} ${bothOn ? "both-on" : ""}`}
          toggle
          onChange={(event: any, data: any) => {
            onChange(data.checked);
          }}
          checked={value}
        ></Checkbox>
        {onLabel && (
          <Label
            className={`${styles.label} ${value ? styles.darker : ""}`}
            onClick={() => onChange(true)}
          >
            {onLabel}
          </Label>
        )}
      </div>
    );
  }
}
