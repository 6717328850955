import * as React from "react";

import ListWebSites from "./ListWebSites";
import UserInfo from "./UserInfo";
import Navigation from "./Navigation";

import QProgress from "qier-progress";
import { ToastContainer, Slide } from "react-toastify";
declare let Waypoint: any;
declare var GlobalConfig: GlobalConfig;

let loaderIncrease = 0.1;
let defaultAdder = 0.1;

export default class Header extends React.Component<
  HeaderProps,
  {
    generalLoaderCurrent: boolean;
    topScroll: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      generalLoaderCurrent: false,
      topScroll: false,
    };
  }

  componentDidMount() {
    const qprogress = new QProgress({
      height: 3,
      color: "#002c6c",
      colorful: false,
      speed: 500,
      trickleSpeed: 750,
    });
    let scroll = 0;
    let generalLoaderCurrent: boolean;

    new Waypoint({
      element: document.querySelector(".js-MainHeader-topLine"),
      handler: (direction: string) => {
        const action: string = { down: "add", up: "remove" }[direction];
        const header = document.querySelector(".MainHeader");
        const logo = document.querySelector(".js-MainHeader-Logo");
        const line = document.querySelector(".js-MainHeader-bottomLine");

        if (!header || !logo || !line) return;

        header.classList[action]("is-hidden");
        logo.classList[action]("is-responsive");
        line.classList[action]("is-hidden");
      },
      offset: -42,
    });

    var _this = this;

    window.addEventListener("scroll", () => {
      const newScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      const line = document.querySelector(".js-MainHeader-bottomLine");

      if (window.scrollY === 0) {
        _this.setState({ topScroll: false });
      } else {
        _this.setState({ topScroll: true });
      }

      if (!line) {
        return;
      }

      const isHidden =
        scroll > newScroll && line.classList.contains("is-hidden");
      const action = isHidden ? "add" : "remove";

      line.classList[action]("slide-down-on-up");
      scroll = newScroll;
    });

    // Interval that monitors the general loader and is responsible for displaying
    // the loader if the loader is not equal to zero
    setInterval(
      function () {
        let sessionLoader = sessionStorage.getItem("generalLoaderCounter");
        let counter = 0;
        if (sessionLoader) {
          counter = +sessionLoader;
        }

        var generalLoader = counter > 0 ? true : false;
        _this.setState({ generalLoaderCurrent: generalLoader });

        if (generalLoader) {
          if (loaderIncrease <= 0.9) {
            if (Math.random() > 0.8) {
              loaderIncrease =
                loaderIncrease + defaultAdder / (8 * loaderIncrease);
            }
            if (Math.random() > 0.65) {
              loaderIncrease =
                loaderIncrease + defaultAdder / (200 * loaderIncrease);
            } else {
              loaderIncrease =
                loaderIncrease + defaultAdder / (450 * loaderIncrease);
            }
            qprogress.set(loaderIncrease);
          } else {
            qprogress.inc(0.001);
          }
        } else {
          qprogress.finish();
          loaderIncrease = 0.1 * (Math.random() / 3);
        }
      }.bind(this),
      100
    );
  }
  render() {
    return (
      <span>
        <span className={"ToastContainer"}>
          <ToastContainer
            draggable={false}
            transition={Slide}
            autoClose={false}
            closeOnClick={true}
            hideProgressBar
          />
        </span>

        <div className="u-posRelative">
          <header className="MainHeader">
            <div className="js-MainHeader-topLine">
              <div className="u-flexExpand MainHeader-contentSizeWrapper">
                <ListWebSites />
                <UserInfo company={this.props.company} user={this.props.user} />
              </div>
            </div>

            <div className="js-MainHeader-bottomLine">
              <div className="u-flexExpand u-posRelative MainHeader-contentSizeWrapper">
                <div className="js-MainHeader-Logo">
                  <a href="/" className="logo">
                    <img
                      src={require("../../assets/svg/samskip-logo.svg")}
                      alt="Forsíða"
                    />
                  </a>
                </div>
                <Navigation />
              </div>
            </div>
          </header>
        </div>
      </span>
    );
  }
}
