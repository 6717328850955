import { observable, computed, action, toJS } from "mobx";
import { persist } from "Utilities/Store";

@persist("UserStore")
class BookingStore {
  @observable private bookingValue?: BookingViewModel;

  @computed get booking(): BookingViewModel | undefined {
    return toJS(this.bookingValue);
  }

  @action setBooking = (booking: BookingViewModel | undefined): void => {
    this.bookingValue = booking;
  };
}

export default new BookingStore();
