class ObjectUtilities {
  get = (obj: object, path: string | string[], defaultValue?: any): string => {
    const keys: any = Array.isArray(path) ? path : path.split(".");
    const object: any = obj[keys[0]];
    if (object && keys.length > 1) {
      return this.get(object, keys.slice(1), defaultValue);
    }
    return object ? object : defaultValue;
  };
}

export default new ObjectUtilities();
