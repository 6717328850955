import * as React from "react";
import * as ReactDOM from "react-dom";
import * as Autosuggest from "react-autosuggest";

import styles from "./index.modules.less";

export default class extends React.Component<TypeaheadProps, TypeaheadState> {
  state: TypeaheadState = { suggestions: [], value: "" };

  /* Clear Suggestions */
  clean = () => this.setState({ suggestions: [] });

  /* Call prop onChange */
  onChange = (
    event: React.FormEvent<any>,
    { newValue }: Autosuggest.ChangeEvent
  ) =>
    this.setState(
      { value: newValue },
      () => this.props.onChange && this.props.onChange(this.state.value)
    );

  /* Update suggestions */
  onValue = ({ value }: Autosuggest.SuggestionsFetchRequestedParams) =>
    this.setState({ suggestions: this.getValue(value) });

  /* Suggestions filter */
  getValue = (text: string) => {
    const value = text.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(`^${value}`, "i");

    return value
      ? this.props.list.filter((v: any) => regex.test(v.label))
      : this.props.list.map((l: any) => l.label);
  };

  /* Update Suggestionson on new props */
  componentWillReceiveProps(props: TypeaheadProps) {
    this.setState({ suggestions: props.list });
  }

  render() {
    const { suggestions, value } = this.state;
    const { placeholder, list } = this.props;

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onValue}
        onSuggestionsClearRequested={this.clean}
        getSuggestionValue={(s: any) => {
          return s;
        }}
        renderSuggestion={(s: any) => {
          return <a href="#">{s}</a>;
        }}
        shouldRenderSuggestions={() => true}
        inputProps={{
          value,
          placeholder,
          onChange: this.onChange
        }}
        theme={{
          container: "autosuggest",
          input: "form-control",
          suggestionsContainer: "dropdown open",
          suggestionsList: "dropdown-menu",
          suggestion: "",
          suggestionFocused: "active"
        }}
      />
    );
  }
}
