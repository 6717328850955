import * as React from "react";
import { Checkbox } from "react-bootstrap";

import styles from "./index.modules.less";
import Image from "./checkbox.png";

export default (props: any) => {
  const onClick = (e: any, value: string) => {
    const input = e.target.parentNode.childNodes[0];

    props.onChange(
      !input.checked
        ? props.values.concat([value])
        : props.values.filter((v: any) => v !== value)
    );
  };

  const isChecked = (values: string[], value: string) =>
    values && values.find(v => v === value);

  return (
    <>
      {props.data.map(({ value, text }: CheckBoxButton, i: number) => (
        <Checkbox
          id={value}
          name={props.name ? props.name : "group"}
          key={i}
          className={styles.checkbox}
          checked={!!isChecked(props.values, value)}
          value={value}
          onChange={(e: any) => {}}
        >
          <label
            htmlFor={value}
            style={{
              background: `url(${Image}) no-repeat`,
              backgroundSize: "15px 30px"
            }}
            onClick={(e: any) => onClick(e, value)}
          >
            {text}
          </label>
        </Checkbox>
      ))}
    </>
  );
};
