import { API_REQUEST_STATUSES } from "Constants/APIResponseConstants";
import SamskipResource from "SamskipResource";
import { ContainerStore } from "Stores";

class ContainerService {
  getContainer = (containerID: string): Promise<Container> => {
    return new Promise<Container>(async (resolve, reject) => {
      const res = await SamskipResource.post<APIResponse<Container>>(
        "SamskipAPI",
        `containers/${containerID.trim()}`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  getContainerLocation = (containerID: string): Promise<Location> => {
    return new Promise<Location>(async (resolve, reject) => {
      const res = await SamskipResource.post<APIResponse<Location>>(
        "SamskipAPI",
        `containers/${containerID.trim()}/location`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  getContainerTrackingHistory = (
    containerID: string
  ): Promise<ContainerTrackingHistory[]> => {
    return new Promise<ContainerTrackingHistory[]>(async (resolve, reject) => {
      const res = await SamskipResource.post<
        APIResponse<ContainerTrackingHistory[]>
      >("SamskipAPI", `containers/${containerID.trim()}/tracking/history`);
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  getContainerHistory = (
    containerID: string,
    partnerCode: string
  ): Promise<ContainerHistory[]> => {
    return new Promise<ContainerHistory[]>(async (resolve, reject) => {
      const res = await SamskipResource.post<APIResponse<ContainerHistory[]>>(
        "SamskipAPI",
        `partners/${partnerCode}/containers/${containerID.trim()}/history`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  getContainers() {
    return ContainerStore.container;
  }

  async getContainerList(company: string): Promise<Container[]> {
    if (!company)
      return new Promise<Container[]>((resolve, reject) =>
        reject("Company must be defined")
      );

    return new Promise<Container[]>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<Container[]>>(
        "SamskipAPI",
        `partners/${company}/containers`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  }

  async getContainersView(company: string): Promise<any | void> {
    if (!company) return Promise.reject("Company must be defined");

    return new Promise<any | void>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<any | void>>(
        "SamskipAPI",
        `containers/${company}/view`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  }

  getContainerPurchase = (
    containerID: string,
    partnerCode: string
  ): Promise<ContainerTrackingPurchase> => {
    return new Promise<ContainerTrackingPurchase>(async (resolve, reject) => {
      const res = await SamskipResource.get<
        APIResponse<ContainerTrackingPurchase>
      >(
        "SamskipAPI",
        `partners/${partnerCode}/containers/${containerID}/purchase`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  containerSearch = (
    company: string,
    searchString: string
  ): Promise<Container[]> => {
    return new Promise<Container[]>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<Container[]>>(
        "SamskipAPI",
        `partners/${company}/containers/${searchString}`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  containerSearchByNumber = (
    searchString: string,
    filteredStatuses?: string[]
  ): Promise<Container[]> => {
    return new Promise<Container[]>(async (resolve, reject) => {
      const res = await SamskipResource.post<APIResponse<Container[]>>(
        "SamskipAPI",
        `containers/search`,
        {},
        {
          SearchString: searchString.toUpperCase(),
          FilteredStatuses: filteredStatuses,
        }
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  containersInExcel = (
    partnerCode: string,
    containers: {}[]
  ): SamskipPromise<Blob> => {
    return SamskipResource.postBlob(
      "ShippingAPI",
      `containers/partners/${partnerCode}/excel`,
      {},
      containers
    );
  };

  getContainerTileInfo = (partnerCode: string): Promise<ContainerTileInfo> => {
    return new Promise(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<ContainerTileInfo>>(
        "SamskipAPI",
        `partners/${partnerCode}/containers/tile`
      );
      if (
        res &&
        res.RequestStatus === API_REQUEST_STATUSES.Ok &&
        res.ReturnItem &&
        res.ReturnItem.Data
      ) {
        resolve(res.ReturnItem.Data);
      } else {
        resolve();
      }
    });
  };
  async getContainerAsync(containerID: string): Promise<Container> {
    const container = await SamskipResource.get<Container>(
      "ShippingAPI",
      `containers/${containerID}`
    );

    ContainerStore.setContainer(container);

    return container;
  }

  /**
   * Look for containers in the collection of containers currently in service with Samskip,
   * whose container-number begins with a given string.
   * @param  {string}         searchString        Container-number to search by
   * @param  {string|Number}  containerLength     Optionally limit the search to containers
   *                                              of a given length (in feet)
   * @param  {string}         boxType             Optionally restrict the search to containers
   *                                              of a certain type. E.g. RF (reefer), HC
   *                                              (high-cube container), etc.
   * @param  {Number}         limit               Limit the number of results returned
   *                                              from the API.
   * @return {Promise}                            Returns a promise to be resolved.
   */
  searchUsableContainers = (
    searchString: string,
    containerLength: number,
    boxType: string,
    limit: number
  ): SamskipPromise<{}[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `containers/search/${searchString}`,
      {
        limit,
        length: containerLength,
      }
    );
  };

  /**
   * Get a list of values that are allowed for reefer-containers as
   * degrees celsius.
   * @return {Promise}
   */
  getAllowedTemperatures = (): SamskipPromise<number[]> => {
    return SamskipResource.get<number[]>(
      "ShippingAPI",
      "containers/temperatures"
    );
  };

  /**
   * Matches the input string with the 4-character form of container-types,
   * 2 digits and 2 uppercase letters, e.g. 40RF, 20HC, etc.
   * @param  {string}  testString     The string to check
   * @return {Boolean}                Returns true if the string matches the
   *                                  container-type pattern.
   */
  isContainerType = (testString: string): boolean => {
    return testString != null && /^\d{2}[A-Z]{2}$/.test(testString);
  };

  /**
   * Tests if a given string is a valid container-number.
   * @param  {String}  testString The string to test.
   * @return {Boolean}            Returns true if the string is a valid
   *                              container-number, false otherwise.
   */
  isValidContainerNumber = (testString: string): boolean => {
    // TODO: Implement a real check according to https://en.wikipedia.org/wiki/ISO_6346
    const containerRe = /^[A-Z]{4}\d{6}-?\d{1}$/;
    return containerRe.test(testString);
  };

  /**
   * Splits a string on the container-type form (2 digits + 2 uppercase letters)
   * to the container's length and box-type attributes.
   * @param  {string} containerType   Container-type string
   * @return {Object}                 Returns an object with properties for
   *                                  the container's length and box-type.
   */
  parseContainerAttributes = (containerType: string): any => {
    if (!this.isContainerType(containerType))
      throw new Error("Must be a valid container type");

    return {
      length: Number(containerType.substr(0, 2)),
      boxType: containerType.substr(2, 2),
    };
  };
}

export default new ContainerService();
