class GeneralLoader {
  increase() {
    let sessionLoader;
    let sessionLoaderNumber = 0;
    if (sessionStorage && sessionStorage.getItem("generalLoaderCounter")) {
      sessionLoader = sessionStorage.getItem("generalLoaderCounter");
      sessionLoaderNumber = sessionLoader ? +sessionLoader + 1 : 0;
    } else {
      sessionLoaderNumber = 1;
    }

    sessionStorage.setItem(
      "generalLoaderCounter",
      sessionLoaderNumber.toString()
    );
  }

  decrease() {
    let sessionLoader;
    let sessionLoaderNumber = 0;
    if (sessionStorage && sessionStorage.getItem("generalLoaderCounter")) {
      sessionLoader = sessionStorage.getItem("generalLoaderCounter");
      sessionLoaderNumber = sessionLoader ? +sessionLoader - 1 : 0;
    } else {
      sessionLoaderNumber = 0;
    }
    if (sessionLoaderNumber < 0) {
      sessionLoaderNumber = 0;
    }

    sessionStorage.setItem(
      "generalLoaderCounter",
      sessionLoaderNumber.toString()
    );
  }
}

export default new GeneralLoader();
