import * as React from "react";
import * as _ from "lodash";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import styles from "./index.less";

export default class DropdownSearchAsync extends React.Component<any, any> {
  state = {
    loading: false,
    maxItems: 0,
    minCharacters: 1,
    maxCharacters: 0,
    cancelPromise: false,
    value: "",
  };

  constructor(props: any) {
    super(props);
    if (props.maxitems) {
      this.state.maxItems = props.maxitems;
    }
    if (props.minCharacters) {
      this.state.minCharacters = props.minCharacters;
    }
    if (props.maxcharacters) {
      this.state.maxCharacters = props.maxcharacters;
    }
  }

  componentDidMount = () => {
    this.setState({ value: this.props.value ? this.props.value : "" });
  };

  componentDidUpdate = (prevProps: any) => {
    if (!this.props.value && prevProps.value) {
      this.setState({ value: "" });
    }
  };

  search = (value: string) => {
    if (this.state.cancelPromise) {
      return;
    }
    this.setState({ loading: true });
    return this.props.search(value);
  };

  asyncSearchFunc: Function = AwesomeDebouncePromise(this.search, 500);

  onSearchChange = async (value: string) => {
    this.setState({ value: "" });
    if (
      this.state.maxCharacters !== 0 &&
      value.length > this.state.maxCharacters
    ) {
      return;
    }
    // Clear dropdown value if not empty
    if (this.props.value !== "" && this.props.onClearValue) {
      this.props.onClearValue();
    }
    this.props.onSearchChange(value);
    if (value.length >= this.state.minCharacters) {
      this.setState({ cancelPromise: false });
      try {
        await this.asyncSearchFunc(value);
        this.setState({ open: true });
      } finally {
        this.setState({ loading: false });
      }
    } else {
      this.setState({ cancelPromise: true });
      // Clear dropdown options if value length is less than minCharacters
      if (this.props.onClearOptions) {
        this.props.onClearOptions();
      }
    }
  };

  render() {
    const {
      options,
      onChange,
      onClearOptions,
      onClearValue,
      className,
      ...rest
    } = this.props;
    const { loading, maxItems, value } = this.state;

    return (
      <Dropdown
        fluid
        selection
        upward={false}
        {...rest}
        selectOnBlur={true}
        selectOnNavigation={false}
        className={`${styles.dropdownSearch} ${styles.dropdown} ${
          loading ||
          this.props.loading ||
          (this.props.disabled && styles.disabled)
        }`}
        value={value}
        onChange={(e: any, data: any) => {
          this.setState({ value: data.value });
          onChange(data.value);
        }}
        onSearchChange={(e: any, data: any) => {
          this.onSearchChange(data.searchQuery);
        }}
        search={(data: DropdownItemProps[]) => {
          return data;
        }}
        options={maxItems > 0 ? _.take(options, maxItems) : options}
        loading={loading || this.props.loading}
        icon="search"
        disabled={loading || this.props.loading || this.props.disabled}
      />
    );
  }
}
