import React from "react";
import { Input } from "semantic-ui-react";
import styles from "./index.modules.less";

export default (props: any) => {
  const { value, onChange, label, innerLabel, id, ...rest } = props;

  const handleChange = (e: any, data: any): void => {
    onChange(data.value);
  };

  const renderLabel = () => {
    return (
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
    );
  };

  return (
    <>
      {label && renderLabel()}
      <Input
        className={styles.input}
        onChange={handleChange}
        fluid
        value={props.value === undefined ? "" : value}
        id={id}
        {...rest}
      />
    </>
  );
};
