import { $uibModal } from "Bootstrap/angular";
import { SamskipNotify } from "Services";

class AngularModalUtilities {
  private readonly dismissReasons = [
    UI_BOOTSTRAP_MODAL_DISMISS_REASON.cancel,
    UI_BOOTSTRAP_MODAL_DISMISS_REASON.backDropClick,
    UI_BOOTSTRAP_MODAL_DISMISS_REASON.escapeKeyPress
  ];

  showDefaultModal = (
    componentName: string,
    resolve?: object,
    options?: ng.ui.bootstrap.IModalSettings
  ): ModalInstance => {
    const modalData = this.createDefaultModalData(
      componentName,
      resolve,
      options
    );
    return this.showModal(modalData);
  };

  showSimpleModal = (
    template: string,
    options?: ng.ui.bootstrap.IModalSettings
  ): ModalInstance => {
    return this.showModal(
      Object.assign({ template, windowClass: "sam-modal", size: "lg" }, options)
    );
  };

  /**
   * Show modal and returns resolved promise if no error occurs.
   * @param modalObject   $uibModal object
   *                      if any error occurs.
   */
  showModal = (modalData: ng.ui.bootstrap.IModalSettings): ModalInstance => {
    const modalInstance: ng.ui.bootstrap.IModalInstanceService = $uibModal.open(
      modalData
    );
    const promise = new Promise((resolve, reject) => {
      modalInstance.result
        .then((results: any) => {
          resolve(results);
        })
        .finally(() => true)
        .then(() => {}, () => {});
    });

    return <ModalInstance>{
      promise,
      instance: modalInstance
    };
  };

  /**
   * Creates $uibModal object
   */
  createDefaultModalData = (
    componentName: string,
    inResolve?: object,
    options?: ng.ui.bootstrap.IModalSettings
  ): ng.ui.bootstrap.IModalSettings => {
    const data: any = {
      component: `${componentName}`,
      windowClass: "sam-modal",
      size: "md"
    };

    if (inResolve) {
      data.resolve = inResolve;
    }

    return Object.assign(data, options);
  };
}

const UI_BOOTSTRAP_MODAL_DISMISS_REASON = {
  cancel: undefined,
  backDropClick: "backdrop click",
  escapeKeyPress: "escape key press"
};

interface ModalInstance {
  instance: any;
  promise: Promise<any>;
}

export default new AngularModalUtilities();
