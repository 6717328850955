import SamskipResource from "SamskipResource";
import { API_REQUEST_STATUSES } from "Constants/APIResponseConstants";
import { AddressFormDto } from "../../shipments/createbooking/addresspopup/interfaces.d";

class CompanyService {
  companyInformation = (company: string): SamskipPromise<Company> => {
    if (!company) throw new Error("Company must be defined");

    return SamskipResource.get<Company>(
      "AuthAPI",
      `companies/doris/${company}`
    );
  };

  companyAddresses = (company: string): SamskipPromise<PartnerAddress[]> => {
    if (!company) throw new Error("Company must be defined");

    return SamskipResource.get<PartnerAddress[]>(
      "AuthAPI",
      `companies/doris/${company}/addresses`
    );
  };

  addCompanyAddress = (address: AddressFormDto): SamskipPromise<any> => {
    return SamskipResource.post<any>(
      "AuthAPI",
      `companies/doris/${address.PartnerCode}/addresses`,
      undefined,
      address
    );
  };

  companyUsers = (company: string): SamskipPromise<any[]> => {
    if (!company) throw new Error("Company must be defined");

    return SamskipResource.get("AuthAPI", `companies/doris/${company}/users`);
  };

  companyRepresentatives = (
    company: string
  ): SamskipPromise<PartnerAgent[]> => {
    if (!company) throw new Error("Company must be defined");

    return SamskipResource.get<PartnerAgent[]>(
      "AuthAPI",
      `companies/doris/${company}/representatives`
    );
  };

  companyConnectSystem = (
    partnerCode: string,
    companyId: string,
    subsystem: number
  ): SamskipPromise<any> => {
    return SamskipResource.post<any>(
      "SamskipAPI",
      `Company/${partnerCode}/${companyId}/${subsystem}`,
      undefined,
      companyId
    );
  };

  companyGetSystemConnections = (
    companyId: string
  ): Promise<CompanySystemConnections[]> => {
    return new Promise<CompanySystemConnections[]>(async (resolve, reject) => {
      const res = await SamskipResource.get<
        APIResponse<CompanySystemConnections[]>
      >("SamskipAPI", `Company/${companyId}/subsystems`);
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  searchCustomers = (search: string): SamskipPromise<Customer[]> => {
    if (!search) throw new Error("Search parameter must be defined");

    return SamskipResource.get("AuthAPI", `customers?searchString=${search}`);
  };

  /**
   * Get customers for a specific company/partner
   * @param  {String}   company       Company / partner
   * @param  {String[]} addressTypes  Address types
   * @return {Array}                  Array of consignee addresses connected to that partner (shipper)
   */
  companyCustomers = (
    company: string,
    addressTypes: string[]
  ): SamskipPromise<PartnerAddress[]> => {
    if (!company) throw new Error("Company must be defined");
    if (!addressTypes || (addressTypes && addressTypes.length === 0)) {
      throw new Error("AddressTypes must be defined");
    }

    return SamskipResource.get<PartnerAddress[]>(
      "AuthAPI",
      `companies/doris/${company}/customers`,
      { addressTypes }
    );
  };

  companyAddressesByType = (
    company: string,
    addressTypes: string[]
  ): SamskipPromise<PartnerAddress[]> => {
    if (!company) throw new Error("Company must be defined");
    if (!addressTypes || (addressTypes && addressTypes.length === 0)) {
      throw new Error("AddressTypes must be defined");
    }

    return SamskipResource.get<PartnerAddress[]>(
      "AuthAPI",
      `companies/doris/${company}/addresses`,
      { addressTypes }
    );
  };

  companyCustomersAndAddressByType = (
    company: string,
    addressTypes: string[]
  ): SamskipPromise<PartnerAddress[]> => {
    if (!company) throw new Error("Company must be defined");
    if (!addressTypes || (addressTypes && addressTypes.length === 0)) {
      throw new Error("AddressTypes must be defined");
    }

    return SamskipResource.get<PartnerAddress[]>(
      "AuthAPI",
      `companies/doris/${company}/addresses/customers`,
      { addressTypes }
    );
  };
}

export default new CompanyService();
