import { observe, action, observable, computed, toJS } from "mobx";
import { Object, Array } from "es6-shim";
import { persist } from "Utilities/Store";

@persist("UserStore")
class UserStore {
  @observable private isLoggedInValue: boolean = false;
  @observable private loginInProgressValue: boolean = false;
  @observable private logoutInProgressValue: boolean = false;

  @observable private userProfileValue?: UserProfile;
  @observable private webSettingsArray?: UserWebSettings[] = [];
  @observable private selectedCompanyValue: Company;
  @observable private openRefreshSessionModalValue: boolean = false;

  //
  // Auth
  //
  @computed get isLoggedIn(): boolean {
    return (
      !this.loginInProgressValue &&
      !this.logoutInProgressValue &&
      this.isLoggedInValue
    );
  }

  @computed get isLoginInProgress(): boolean {
    return this.loginInProgressValue;
  }

  @computed get isLogoutInProgress(): boolean {
    return this.logoutInProgressValue;
  }

  @computed get isRefreshSessionModalOpen(): boolean {
    return this.openRefreshSessionModalValue;
  }

  @action
  public loggedIn = (value: boolean): void => {
    this.isLoggedInValue = value;
  };

  @action
  public loginInProgress = (inProgress: boolean): void => {
    this.loginInProgressValue = inProgress;
  };

  @action
  public logoutInProgress = (inProgress: boolean): void => {
    this.logoutInProgressValue = inProgress;
  };

  @action
  public shouldShowrefreshSessionModal = (show: boolean): void => {
    this.openRefreshSessionModalValue = show;
  };

  //
  // User
  //

  @computed get userProfile(): UserProfile | undefined {
    return this.userProfileValue;
  }

  @computed get webSettings(): UserWebSettings[] | undefined {
    return this.webSettingsArray;
  }

  @computed get selectedCompany(): Company {
    return this.selectedCompanyValue;
  }

  @action
  public setUserProfile = (userProfile: UserProfile): void => {
    this.userProfileValue = JSON.parse(JSON.stringify(userProfile));
  };

  @action
  public clearUserProfile = (): void => {
    this.userProfileValue = undefined;
  };

  @action
  public setSelectedCompany = (newCompany: Company): void => {
    if (
      this.selectedCompanyValue &&
      this.selectedCompanyValue.CompanyRegNo === newCompany.CompanyRegNo &&
      this.selectedCompanyValue.PartnerCode === newCompany.PartnerCode
    ) {
      return;
    }

    this.selectedCompanyValue = newCompany;
    if (this.userProfileValue) {
      this.userProfileValue.SelectedCompany = newCompany;
    }
  };

  @action
  public setWebSettings = (webSettings: UserWebSettings[]): void => {
    (<any>this.webSettingsArray).replace(webSettings);
  };

  @action
  public setWebSetting = (field: string, value: string): void => {
    if (!this.webSettingsArray) return;

    const oldField = this.webSettingsArray.find(
      (item: UserWebSettings) => item.Field === field
    );
    if (!oldField) return;

    oldField.StringValue = value;
  };

  getUserProfile(): UserProfile | undefined {
    return this.userProfile;
  }

  getWebSetting(field: string): any {
    if (!this.webSettingsArray) return;

    return this.webSettingsArray.find(i => i.Field === field);
  }

  getWebSettings(): UserWebSettings[] {
    return this.webSettingsArray as UserWebSettings[];
  }

  getCleanSelectedCompany(): Company {
    return toJS(this.selectedCompanyValue);
  }

  getSelectedCompany(): Company {
    return this.selectedCompanyValue;
  }

  getCleanCompanies(): Company[] {
    return this.userProfile ? toJS(this.userProfile.Companies || []) : [];
  }
}

export default new UserStore();
