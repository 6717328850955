import * as React from "react";
import * as ReactDOM from "react-dom";
import * as ReactPaginate from "react-paginate";

import styles from "./index.modules.less";
import { UserService } from "Services";
import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";

export default (props: PageNumberProps) => {
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    if (!initialized) {
      const settingsItemsPerListPage = UserService.getLocalWebSettingValue(
        USERWEBSETTINGS.ItemsPerListPage
      );
      props.onClick(settingsItemsPerListPage);
      setInitialized(true);
    }
  });

  return (
    <div className={styles.list}>
      <span className={styles.title}>{props.title}</span>
      <ul>
        {props.list.map((item: number) => (
          <li
            key={item}
            className={item === Number(props.selected) ? styles.selected : ""}
          >
            <span
              onClick={() => {
                UserService.setWebSetting(
                  USERWEBSETTINGS.ItemsPerListPage,
                  item.toString()
                );
                props.onClick(item);
              }}
            >
              {item}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};
