import SamskipResource from "SamskipResource";

import { REQUEST_STATUSES } from "Constants/RequestConstants";
import { API_REQUEST_STATUSES } from "Constants/APIResponseConstants";

class RequestService {
  getRequestStatuses = (): Promise<any[]> => {
    return new Promise<any[]>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<any[]>>(
        "SamskipAPI",
        `requests/statuses`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  getRequestTypes = (): Promise<any[]> => {
    return new Promise<any[]>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<any[]>>(
        "SamskipAPI",
        `requests/types`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  getRequestVersion = (requestNumber: number | null): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<any>>(
        "SamskipAPI",
        `requests/${requestNumber}/version`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  /**
   * Get request by request id
   * @param {string} requestId   Request id
   * @param {string} partnerCode Doris partner code
   * @param {string[]} requestTypes List of request types to search with
   * @return {Promise}           Request object
   */
  getRequestByRequestID = (
    requestId: string,
    requestTypes: string[] = []
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      const res = await SamskipResource.post<APIResponse<any>>(
        "SamskipAPI",
        `requests/${requestId}`,
        undefined,
        { requestTypes }
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  getRequestList = (
    Partner?: string,
    DateFrom?: string,
    DateTo?: string,
    Limit?: number | null,
    Skip?: number | null,
    RequestID?: string,
    ContainerNumber?: string,
    RequestVersion?: string,
    RequestType?: string,
    RequestStatuses?: REQUEST_STATUSES[],
    Department?: string,
    SortBy?: string,
    IsDesc?: boolean
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      const res = await SamskipResource.post<APIResponse<any>>(
        "SamskipAPI",
        `requests`,
        undefined,
        {
          Partner,
          DateFrom,
          DateTo,
          Limit,
          Skip,
          RequestID,
          ContainerNumber,
          RequestVersion,
          RequestType,
          RequestStatuses,
          Department,
          SortBy,
          IsDesc
        }
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  getExcelForRequests = (requests: any[]): SamskipPromise<any> => {
    return SamskipResource.postBlob("SamskipAPI", "requests/excel", undefined, {
      requests
    });
  };
}

export default new RequestService();
