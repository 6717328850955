import TranslationStore from "Stores/TranslationStore";
import SamskipResource from "SamskipResource";
import { $translate } from "Bootstrap/angular";

class TranslationService {
  getTranslationStrategy = (language: string): Function => {
    return translationStrategy.getStrategy(language) || undefined;
  };

  isChanged = (): boolean => {
    return TranslationStore.isChanged();
  };

  isLoaded = (): boolean => {
    return TranslationStore.isLoaded();
  };

  getSelectedLanguage = (): string => {
    return TranslationStore.getSelectedLanguage();
  };

  switchLanguage = (key: string): void => {
    $translate.use(key).then(() => {
      TranslationStore.language(key);
    });
  };

  translate = (key: string, params?: any): string => {
    return $translate.instant(key, params);
  };

  getAvailableTranslations = (): SamskipPromise<Translation[]> => {
    return SamskipResource.get<Translation[]>(
      "AuthAPI",
      "translations/languages/"
    );
  };
}

export function languages(
  key: string | null,
  count?: number,
  text?: string
): string {
  if (!key) return "";

  const strategy = translationStrategy.getStrategy(
    TranslationStore.getSelectedLanguage()
  );
  return strategy(key, count, text);
}

const translationStrategy: TranslationStrategy = {
  getStrategy: (language: string): Function => {
    // Base translate function
    const createStrategy = (singularCheck: Function): Function => {
      return (key: string, count: number, text: string) => {
        const countValue = Number(count) || undefined;
        let strNew = "";

        if (!countValue && !text) {
          return $translate.instant(key);
        }

        if (singularCheck(countValue) || text) {
          strNew = $translate.instant(key);
        } else {
          strNew = $translate.instant(`${key}_PLURAL`);
        }

        // Replace parameter with count or text
        if (countValue) {
          return strNew.replace("{0}", countValue.toString());
        }
        return strNew.replace("{text}", text);
      };
    };

    const singularChecks = {
      is: (count: number) => {
        return count % 10 === 1 && count !== 11;
      },
      en: (count: number) => {
        return count === 1;
      }
    };

    return createStrategy(singularChecks[language]);
  }
};

export default new TranslationService();
