import * as moment from "moment";
import { $timeout, $sce } from "ngimport";
import { ModalUtilities, WindowUtilities } from "Utilities";
import { languages } from "Services";

declare var $: any;
declare var window: any;

const Spinner = require("spin.js");

class UtilityFunctions {
  largeSpinner = (id: string): void => {
    const opts: SpinnerOptions = {
      lines: 12, // The number of lines to draw
      length: 18, // The length of each line
      width: 4, // The line thickness
      radius: 24, // The radius of the inner circle
      corners: 1, // Corner roundness (0..1)
      rotate: 0, // The rotation offset
      direction: 1, // 1: clockwise, -1: counterclockwise
      color: "#002378", // #rgb or #rrggbb or array of colors
      speed: 0.7, // Rounds per second
      trail: 60, // Afterglow percentage
      shadow: false, // Whether to render a shadow
      hwaccel: false, // Whether to use hardware acceleration
      className: "spinner", // The CSS class to assign to the spinner
      zIndex: 2e9, // The z-index (defaults to 2000000000)
      top: "50%", // Top position relative to parent
      left: "50%", // Left position relative to parent
    };
    const target = document.getElementById(id);
    new Spinner(opts).spin(target || undefined);
  };

  mediumSpinner(id: string) {
    const opts: SpinnerOptions = {
      lines: 12, // The number of lines to draw
      length: 12, // The length of each line
      width: 3, // The line thickness
      radius: 18, // The radius of the inner circle
      corners: 1, // Corner roundness (0..1)
      rotate: 0, // The rotation offset
      direction: 1, // 1: clockwise, -1: counterclockwise
      color: "#002378", // #rgb or #rrggbb or array of colors
      speed: 0.7, // Rounds per second
      trail: 60, // Afterglow percentage
      shadow: false, // Whether to render a shadow
      hwaccel: false, // Whether to use hardware acceleration
      className: "spinner", // The CSS class to assign to the spinner
      zIndex: 2e9, // The z-index (defaults to 2000000000)
      top: "50%", // Top position relative to parent
      left: "50%", // Left position relative to parent
    };
    const target = document.getElementById(id);
    new Spinner(opts).spin(target || undefined);
  }

  smallSpinner(id: string) {
    const opts: SpinnerOptions = {
      lines: 8, // The number of lines to draw
      length: 4, // The length of each line
      width: 2, // The line thickness
      radius: 4, // The radius of the inner circle
      corners: 1, // Corner roundness (0..1)
      rotate: 0, // The rotation offset
      direction: 1, // 1: clockwise, -1: counterclockwise
      color: "#002378", // #rgb or #rrggbb or array of colors
      speed: 0.7, // Rounds per second
      trail: 60, // Afterglow percentage
      shadow: false, // Whether to render a shadow
      hwaccel: false, // Whether to use hardware acceleration
      className: "spinner", // The CSS class to assign to the spinner
      zIndex: 2e9, // The z-index (defaults to 2000000000)
      top: "50%", // Top position relative to parent
      left: "50%", // Left position relative to parent
    };
    const target = document.getElementById(id);
    new Spinner(opts).spin(target || undefined);
  }

  validateEmail = (email: string): boolean => {
    if (email === undefined) return false;
    // tslint:disable-next-line: max-line-length
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  getDateNowFormated = (): string => {
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth() + 1; // January is 0!
    const yyyy = today.getFullYear();

    return `${dd}.${mm}.${yyyy}`;
  };

  toggleArrayInfo = (array: any[], element: any): void => {
    const index = array.indexOf(element);
    if (index > -1) {
      array.splice(index, 1);
    } else {
      array.push(element);
    }
  };

  smallSpinnerPos(
    id: string,
    posTop: string,
    posLeft: string,
    spColor: string
  ): void {
    const opts: SpinnerOptions = {
      lines: 8, // The number of lines to draw
      length: 4, // The length of each line
      width: 2, // The line thickness
      radius: 4, // The radius of the inner circle
      corners: 1, // Corner roundness (0..1)
      rotate: 0, // The rotation offset
      direction: 1, // 1: clockwise, -1: counterclockwise
      color: spColor, // #rgb or #rrggbb or array of colors
      speed: 0.7, // Rounds per second
      trail: 60, // Afterglow percentage
      shadow: false, // Whether to render a shadow
      hwaccel: false, // Whether to use hardware acceleration
      className: "spinner", // The CSS class to assign to the spinner
      zIndex: 99, // The z-index (defaults to 2000000000)
      top: posTop, // Top position relative to parent
      left: posLeft, // Left position relative to parent
    };
    const target = document.getElementById(id);
    new Spinner(opts).spin(target || undefined);
  }

  catToggle = (): void => {
    // control fyrir toggle á földum filter items
    $(".js-Sidebar-catHeader").on("click", function click() {
      $(this).find(".js-Sidebar-hideToggle").toggleClass("makeMinus");
      $(this).next(".js-Sidebar-toggleFields").slideToggle("slow");
    });
  };

  removeCatToggle = (): void => {
    $(".js-Sidebar-catHeader").off("click");
  };

  checkSizes = (elmFollow: any, elmContent: any): void => {
    // control fyrir hæð á sidebar
    const sideh = elmFollow.height();
    const conth = elmContent.height();

    if (conth >= sideh) {
      elmFollow.css(`min-height${conth}px`);
    } else {
      elmFollow.css(`min-height${conth}px`);
    }
  };

  findElement = (
    arr: any[],
    propName: string,
    propValue: any,
    newValue?: any
  ): any => {
    let retVal;
    if (arr !== undefined) {
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i][propName] === propValue) {
          if (newValue !== undefined) {
            arr[i].StringValue = newValue;
            retVal = arr;
          }

          retVal = arr[i];
        }
      }

      if (newValue !== undefined) {
        retVal = arr;
      }
    }

    return retVal;
  };

  removeElement = (arr: any[], propName: string, propValue: any): any => {
    let retVal;
    if (arr !== undefined) {
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i][propName] === propValue) {
          retVal = arr.splice(i, 1);
        }
      }
    }

    return retVal;
  };

  getListViewSizeClass = (size: string): string => {
    switch (size) {
      case "1":
        return "";
      case "0":
        return "js-smaller";
      case "2":
        return "js-smallest";
      default:
        return "js-smaller";
    }
  };

  setListSizeSmall = (): void => {
    $(".js-DisplayList").addClass("js-smaller");
    setTimeout(() => {
      this.checkSizes($(".js-Sidebar"), $(".js-ListPage-box"));
    }, 600);
  };

  setListSizeSmaller = (): void => {
    $(".js-DisplayList").addClass("js-smallest");
    setTimeout(() => {
      this.checkSizes($(".js-Sidebar"), $(".js-ListPage-box"));
    }, 600);
  };

  setListSizes = (selectedListViewSize: string | undefined): void => {
    if (selectedListViewSize === undefined) return;

    if (selectedListViewSize === "0") {
      this.setListSizeSmall();
    } else if (selectedListViewSize === "2") {
      this.setListSizeSmaller();
    }
  };

  /**
   * Controlling slide control for Sidebar.
   * @param  {triggerClass}   triggerClass    [button trigger class]
   * @param  {sidebarClass}   sidebarClass    [sidebar class]
   * @param  {openText}       expandText      [text displayed when sidebar is collapsed]
   * @param  {closeText}      collapseText    [text displayed when sidebar is expanded]
   */
  filterSlideControl = (
    triggerClass: string,
    sidebarClass: string,
    expandText: string,
    collapseText: string
  ): void => {
    const trigger = document.getElementsByClassName(triggerClass);
    const sidebar = document.getElementsByClassName(sidebarClass);

    $(trigger).on("click", function click() {
      $(sidebar).toggleClass("slideOut hidden-sm");
      $(this).toggleClass("slideOut");
      if ($(this).hasClass("slideOut")) {
        $(this).text(languages(collapseText));
      } else {
        $(this).text(languages(expandText));
      }
    });
  };

  formatDate = (date: Date, dateFormat: any, format: any): string => {
    return moment(date, dateFormat).format(format);
  };

  popup = (
    url: string,
    newWindow: boolean,
    heightParam?: number,
    widthParam?: number,
    nameParam?: string
  ): void => {
    console.warn("Use WindowUtilities ESM instead");
    const height = heightParam || 750;
    const width = widthParam || 1020;
    const name = nameParam || "Popup";

    $timeout(() => {
      if (newWindow) {
        window.open(url, "_blank");
      } else {
        window.open(
          url,
          name,
          `height=${height},width=${width},scrollbars=yes`
        );
      }
      return false;
    });
  };

  iframeModal = (src: string, scope: any) => {
    scope.src = $sce.trustAsResourceUrl(src);

    return ModalUtilities.showSimpleModal(
      `<div class="modal-body" style="height:100%">
                <div class="rightControls IframeModal-iframeClose">
                    <a type="button" href="" class="rightControls-link close" ng-click="$dismiss();">&times;</a>
                </div>
                <i class="fa-li fa fa-circle-o-notch fa-spin"
                style="font-size: 100px;  position: absolute; left: 37%; top: 44%;z-index:0;"></i>
                <iframe src="${src}" style="width:100%;height:100%;z-index:1;position:relative;"></iframe>
            </div>`,
      {
        scope,
        windowClass: "sam-modal sam-modal-iframe",
      }
    );
  };

  confirmModal = (
    title: string,
    content: string,
    quitButton: string,
    proceedButton: string
  ) => {
    return ModalUtilities.showLargeModal("confirmModal", {
      modalInfo: function modalInfoFn() {
        const modalInfoObj: any = {};
        modalInfoObj.title = title;
        modalInfoObj.content = content;
        modalInfoObj.quit = quitButton;
        modalInfoObj.proceed = proceedButton;
        return modalInfoObj;
      },
    });
  };

  // add thousand seperator to number
  thousandFNumber = (num: number): string => {
    return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, ($1) => {
      return `${$1}.`;
    });
  };

  selectFromArray = (arr: any[], value: any): any[] => {
    const result = [];
    for (let i = 0, iLen = arr.length; i < iLen; i += 1) {
      result.push(arr[i][value]);
    }
    return result;
  };

  /**
   * Reformats a given string that contains line-breaks using newline
   * characters or carriage returns as stored in database replacing those
   * with html's <br/> element.
   * @param  {string} input   Input string containing newline characters
   * @return {string}         Resulting string containing <hr/> elements
   *                          instead of newline characters
   */
  htmlFormatText = (input: string): string => {
    if (input) {
      return input.replace(/\r?\n|\r/g, "<br>");
    }
    return "";
  };

  /**
   * Returns whether a given point code is an Icelandic point code or not
   * @param   {string}  pointCode   The point code to check
   * @returns {boolean}             True if the given point code is in Iceland and false otherwise
   */
  isIcelandicPointCode = (pointCode: string) => {
    return pointCode.startsWith("IS");
  };

  /**
   * Returns weather a given PartnerCode is a booking party on a given booking
   * @param   {string}  partnerCode   Doris partner code
   * @param   {Booking} booking       Booking object
   * @returns {boolean}               True if a given partner code is a booking party on the given booking and false otherwise
   */
  isBookingParty = (partnerCode: string, booking: BookingViewModel) => {
    if (!booking) {
      return false;
    }

    return (
      booking &&
      ((booking.Consignee && booking.Consignee.PartnerCode === partnerCode) ||
        (booking.Shipper && booking.Shipper.PartnerCode === partnerCode))
    );
  };
}

export default new UtilityFunctions();
