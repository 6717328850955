import { UserService } from "Services";
import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";

class TutorialService {
  /**
   * Checks if the user is viewing the tutorial group for the first time
   * @return {Boolean} If the user is viewing the tutorial group for the first time
   */
  tutorialRead = (group: string): boolean => {
    const tutorialObj = this.getTutorialObj();

    return Boolean(typeof tutorialObj === "object" && !!tutorialObj[group]);
  };

  /**
   * Marks the tutorial group as done (opened/viewed)
   */
  markTutorialDone = (group: string): void => {
    const tutorialObj = this.getTutorialObj();

    // Mark tutorial as read if it hasn't been read
    if (!tutorialObj[group]) {
      tutorialObj[group] = true;
    }

    // Store the updated value
    UserService.setWebSetting(
      USERWEBSETTINGS.Tutorial,
      JSON.stringify(tutorialObj)
    );
  };

  /**
   * Private function get tutorial object
   * @return {Object} the tutorial object
   */
  private getTutorialObj = (): any => {
    const tutorialWebSetting = UserService.getLocalWebSettingValue(
      USERWEBSETTINGS.Tutorial
    );

    if (!tutorialWebSetting) return {};

    let tutorialObj;
    if (tutorialWebSetting !== "") {
      tutorialObj = JSON.parse(tutorialWebSetting);
    } else {
      tutorialObj = {};
    }
    return tutorialObj;
  };
}

export default new TutorialService();
