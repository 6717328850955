import { ArrayUtilities } from "Utilities";
import { UserService } from "Services";
import { USER_ROLES } from "Constants/UserRoles";

declare var GlobalConfig: GlobalConfig;

/**
 * FeatureToggleRouter's role is to provide an abstraction and centralize the logic
 * involved with toggling features given a specific toggle configuration.
 *
 * The toggle configuration is an object where each key is the name of a
 * feature that is to be enabled/disabled. Each feature can be configured
 * with the following options:
 *      enabled : boolean (required)
 *         If this is set to false, the feature is disabled an no other
 *         options are considered. If true and other options are set, the
 *         feature is enabled/disabled based on the other config-options.
 *      roles : array[string|Number] (optional)
 *          An array of rolenames (strings) or role IDs (numbers) that the
 *          user is required to have in order for the feature to be enabled.
 *          NOTE: The user is only required to have at least one role from
 *              this array -- he/she does NOT have to have all of the roles
 *              specified.
 *      dateAdded : string|Number (optional)
 *          A date as a string (e.g. '2016-03-01') or a number (integer
 *          value representing the number of milliseconds since
 *          1 January 1970 00:00:00 UTC). This option is entirely optional
 *          and is only for developers' convenience. If more than 90 days
 *          have passed since the feature was introduced into the config,
 *          a warning is issued (in the dev console).
 *
 * Example configuration:
 *     // the configuration object
 *     {
 *         // this feature is always enabled
 *         myAwesomeFeature: {
 *             enabled: true,
 *             roles: ['TEST', 'PILOT'],
 *             dateAdded: '2016-03-02'
 *         },
 *         otherFeature: {
 *             enabled: false
 *         }
 *     }
 * TODO: Use dependency injection for the toggleconfig. Right now,
 *      FeatureToggleRouter has a hardcoded reference to GlobalConfig but we should
 *      inject it into the service as a dependency.
 */
class FeatureToggleRouter {
  thirtyDays: number = 7776000000; // 90 * 24 * 60 * 60 * 1000

  /**
   * Checks if feature-flags/feature-toggles are configured for a given
   * feature and if that feature should be enabled for the user.
   * NOTE: If there is no configuration for the given feature, that
   * 'feature' is considered enabled. It is therefore important that
   * that the featureName matches the configuration.
   * @param  {String}  featureName    Name of the feature to check.
   * @return {Boolean}                Returns false if feature of the
   *                                  given name is configured and it is
   *                                  disabled for the logged in user.
   *                                  Otherwise false.
   */
  isEnabled = (featureName: string): boolean => {
    const config = this.getFeatureConfig(featureName);
    if (config.enabled === false) {
      return false;
    }
    if (this.isRoleBased(config) && !this.userHasRequiredRole(config)) {
      return false;
    }
    return true;
  };

  /**
   * Returns the opposite of 'isEnabled'.
   * @param  {String}  featureName    Name of the feature to check.
   * @return {Boolean}                Returns true if the feature is
   *                                  disabled, false otherwise.
   */
  isDisabled = (featureName: string): boolean => {
    return !this.isEnabled(featureName);
  };

  /**
   * Gets the configuration for a given feature.
   * @param  {String} featureName Name of the feature which config to get.
   * @return {Object}             Returns the configuration for the given
   *                              feature if exists, otherwise an empty
   *                              object.
   */
  private getFeatureConfig = (featureName: string): any => {
    let config;
    try {
      config = GlobalConfig.features[featureName];
    } catch (e) {
      console.warn(`Could no load config for feature ${featureName}`, e);
    }
    if (config == null) {
      // Output warning to console (for developers, may this should
      // be a Sentry-notification?)
      console.warn(
        `Could not find any configuration for feature "${featureName}"!`
      );
    }
    this.checkFeatureFlagAge(featureName, config);
    return config || {};
  };

  /**
   * Checks if the config for a given feature depends on the user's
   * roles or not.
   * @param  {Object}  config A config-object (see getFeatureConfig)
   * @return {Boolean}        Returns true if feature depends on the user's
   *                          roles.
   */
  private isRoleBased = (config: any): boolean => {
    return Array.isArray(config.roles) && config.roles != null;
  };

  /**
   * Checks if the logged in user has a role that is required for a
   * given feature to be enabled for that user.
   * @param  {Object} config  A config-object (see getFeatureConfig)
   * @return {Boolean}        Returns true if the user has the required
   *                          roles for the feature to be enabled.
   */
  private userHasRequiredRole = (config: any): boolean => {
    const roleIds = config.roles.map((it: any) => {
      if (typeof it === "string" && USER_ROLES[it]) {
        return USER_ROLES[it];
      }
      return it;
    });
    return ArrayUtilities.intersect(this.getUserRoles(), roleIds).length > 0;
  };

  /**
   * Gets the roles that the currently logged in user has.
   * @return {Array}  Returns an array of roleIds for the current user.
   */
  private getUserRoles = (): number[] => {
    let user: any = {};
    let userRoles: number[] = [];
    try {
      user = UserService.getUserProfile();
      if (user.Roles != null) {
        userRoles = user.Roles.map((role: WebRole) => role.RoleID);
      }
    } catch (e) {
      console.warn("Could not get user from UserService", e);
    }
    return userRoles;
  };

  /**
   * Checks if a feature-flag was addad on a specific date and warns us
   * if its age is more than ninety days -- just in case we fall asleep
   * at the wheel. The idea is to notify developers of outdated
   * feature-flags (so maybe this should be a Sentry-notification?).
   * @param  {String} featureName  The name/id of the feature.
   * @param  {Object} config       Config-object (see getFeatureConfig).
   *                               dateAdded must be a valid datestring
   *                               of format 'yyyy-MM-dd' (e.g. 2016-03-01)
   *                               or a number (a date as the number of
   *                               milliseconds since January 1, 1970)
   */
  private checkFeatureFlagAge = (featureName: string, config: any) => {
    if (config == null) {
      return;
    }
    const recognizedDateRegex = /^\d{4}-\d{2}-\d{2}/;
    let dateAdded;
    const dateAddedRegexOutput = recognizedDateRegex.exec(config.dateAdded);
    if (dateAddedRegexOutput && recognizedDateRegex.test(config.dateAdded)) {
      dateAdded = new Date(dateAddedRegexOutput[0]);
    } else if (Number.isFinite(config.dateAdded)) {
      dateAdded = new Date(config.dateAdded);
    } else {
      return;
    }
    if (Date.now() - dateAdded.getTime() > this.thirtyDays) {
      console.warn(
        `Feature "${featureName}" was added more than three months ago!`
      );
    }
  };
}

export default new FeatureToggleRouter();
