declare var GlobalConfig: GlobalConfig;

class RestHelper {
  createRequest<T>(
    url: string,
    method: string,
    path?: string,
    params?: {},
    responseType?: any,
    data?: any
  ): Request {
    // Initialize HTTP request
    const requestUrl = this.buildUrlFromParams(`${url}/${path}`, params);
    const request: any = {
      url: requestUrl,
      data: data || "",
      responseType: responseType || "",
      method: method.toLowerCase()
    };

    // Attach payload if not a GET or HEAD request
    if (["get", "head"].indexOf(method) === -1) {
      request.body = JSON.stringify(data);
    }

    return request;
  }

  public getAPIUrl(api: string) {
    return GlobalConfig.ENV[api];
  }

  private buildUrlFromParams = (oldUrl: string, params?: object): string => {
    if (!params) return oldUrl;

    // Extra params
    let newUrl = oldUrl;
    const extraHttpParams: any[] = [];

    // Go through all properties in parameter object
    for (const o in params) {
      // If property for :someparam is not found
      if (oldUrl.indexOf(`:${o}`) === -1) {
        if (params[o] !== undefined && params[o] !== null) {
          extraHttpParams.push(`${o}=${encodeURIComponent(params[o])}`);
        }
      } else {
        // If property for :someparam is found, we replace it in the full path
        if (!params[o]) {
          params[o] = "";
        }

        newUrl = oldUrl.replace(`:'${o}`, params[o]);
      }
    }

    // Append parameters to the full path
    if (extraHttpParams.length > 0) {
      newUrl += `?${extraHttpParams.join("&")}`;
    }

    return newUrl;
  };
}

export default new RestHelper();
