export const NOTIFICATION_EVENTS = {
  newNotifications: "new-notifications",
  notificationRead: "notification-read"
};

export const NOTIFICATION_STATUS = {
  BatchFinished: "BATCH_FINISHED",
  BatchDelayed: "BATCH_DELAYED",
  BatchFailed: "BATCH_FAILED",
  BatchPending: "BATCH_PENDING",
  BatchProcessing: "BATCH_PROCESSING"
};
