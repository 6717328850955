import * as React from "react";
import * as ReactDOM from "react-dom";
import { MenuItem, Clearfix, Dropdown } from "react-bootstrap";

import { languages } from "Services/TranslationService.ts";
import { default as Icon } from "-!svg-react-loader?name=Icon!Assets/svg/world.svg";

export default class ListWebSites extends React.Component<
  {},
  ListWebSitesState
> {
  state = { open: false };
  toggle() {
    this.setState({ open: !this.state.open });
  }
  render() {
    return (
      <div className="MainHeader-Sites">
        <ul className="nav">
          <li className={`dropdown ${this.state.open ? "open" : ""}`}>
            <div className="MainHeader-Sites-link">
              <a className="dropdown-toggle" onClick={this.toggle.bind(this)}>
                <Icon className="world" />
                {languages("LABEL_OUR_SITES")} <b className="caret"></b>
              </a>
            </div>
            <ul className="dropdown-menu">
              <MenuItem href="http://www.samskip.is">SAMSKIP.IS</MenuItem>
              <MenuItem href="https://thjonusta.samskip.is">
                <strong>THJONUSTA.SAMSKIP.IS</strong>
              </MenuItem>
              <MenuItem href="http://www.samskip.com">
                SAMSKIP.COM - CORPORATE SITE
              </MenuItem>
              <MenuItem href="http://www.samskipmultimodal.com">
                EUROPEAN MULTIMODAL
              </MenuItem>
              <MenuItem href="http://www.samskipnorthatlantic.com">
                NORTH ATLANTIC INTEGRATED LOGISTICS
              </MenuItem>
              <MenuItem href="http://www.samskipicepak.com">
                WORLDWIDE TEMPERATURE CONTROLLED
              </MenuItem>
              <MenuItem href="http://www.samskipbulk.com">
                EUROPEAN BREAKBULK AND PROJECT CARGO
              </MenuItem>
              <MenuItem href="http://www.samskipforwarding.com">
                WORLDWIDE FORWARDING
              </MenuItem>
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}
