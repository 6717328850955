import { Object } from "es6-shim";
import { $timeout } from "ngimport";
import { ModalUtilities } from "Utilities";

/**
 * How to use loadingTextModal:
 *
 * There are two ways to use it
 * - Open modal that shuts down after X time
 * - Open modal, get modalInstance object and then close
 *     it later manually.
 *
 * Examples:
 *
 * openModal()
 * Open Modal using default text, that closes after
 * 'default' time.
 *
 * openModal("This is a modal", 2000, "fa fa-lock")
 * Open modal that closes after 2 sec and uses the text
 * "This is a modal" and lock icon.
 *
 * modalON()
 * Open modal using default text. modalOFF has to be
 * called to close it again.
 *
 * modalON("This is a modal")
 * Open modal using this specific text. modalOFF has to be
 * called to close it again.
 *
 * modalOFF(modalInstance object)
 * Close current modalInstance.
 *
 */

class LoadingTextModalService {
  // Default loading text modal options
  private readonly defaultOptions: LoadingTextModalOptions;
  private displayTime: number;

  private currentModalInstance?: ng.ui.bootstrap.IModalInstanceService;

  constructor() {
    // Set default options
    this.defaultOptions = {
      mainText: "TEXT_LOADING",
      icon: undefined
    };
    this.displayTime = 2000;
  }

  /**
   * Open modal without shuting it down,
   * using specific values.
   * To shut it down, modalOFF has to be executed.
   * @param  {string} mainText    [Text that will be displayed in modal]
   * @return {object}             [Modal Instance]
   */
  modalON = (mainText?: string, icon?: string, displayTime?: number): void => {
    const modalOptions = this.extendDefaults({
      mainText,
      icon
    });

    if (displayTime) this.displayTime = displayTime;

    this.displayModal(modalOptions);
  };

  /**
   * Close current modal.
   * @param  {object} modalInstance [Modal Instance]
   */
  modalOFF = (): SamskipPromise<void> => {
    if (!this.currentModalInstance)
      return <SamskipPromise<any>>Promise.reject("");

    this.currentModalInstance.close();
    return this.currentModalInstance.result;
  };

  private displayModal = (options: LoadingTextModalOptions): void => {
    const modalObj = ModalUtilities.showModalWithInstance(
      "loadingTextModal",
      {
        mainText: () => options.mainText,
        icon: () => options.icon
      },
      {
        windowClass: "loadingTextModal",
        backdrop: "static",
        keyboard: false
      }
    );

    this.currentModalInstance = modalObj.instance;
  };

  private shutDownModalCountDown() {
    if (!this.currentModalInstance) return;

    this.currentModalInstance.rendered.then(() => {
      $timeout(() => {
        if (!this.currentModalInstance) return;
        this.currentModalInstance.close();
      }, this.displayTime);
    });
  }

  private extendDefaults = (options: any): LoadingTextModalOptions => {
    const modalOptions: LoadingTextModalOptions = Object.assign(
      {},
      this.defaultOptions,
      options
    );

    // Because Object.assign doesn't throw away properties with null/undefined values,
    // we have to loop through it and throw them ourselves.
    Object.keys(modalOptions).forEach((opt: string) => {
      if (modalOptions[opt] == null) {
        delete modalOptions[opt];
      }
    });

    return modalOptions;
  };
}

interface LoadingTextModalOptions {
  mainText: string;
  icon?: string;
}

export default new LoadingTextModalService();
