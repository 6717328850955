import React from "react";
import styles from "../index.modules.less";
import {
  Popup,
  PopupProps,
  PopupHeader,
  PopupContent
} from "semantic-ui-react";

const PopupModal = (props: PopupProps) => {
  const { header, content, ...rest } = props;
  return (
    <Popup className={`${styles.popupmodal} ${styles.shared}`} {...rest}>
      <PopupHeader>{header}</PopupHeader>
      <PopupContent>{content}</PopupContent>
    </Popup>
  );
};

export default PopupModal;
