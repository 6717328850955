import * as React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ToolTipProps from "./interfaces.d";

const tooltip = (value: ToolTipProps["value"]) => (
  <Tooltip id="tooltip">{value}</Tooltip>
);

export default (props: ToolTipProps) => (
  <OverlayTrigger
    trigger={["hover", "focus"]}
    placement={props.placement}
    overlay={tooltip(props.value)}
  >
    {props.children}
  </OverlayTrigger>
);
