import { API_REQUEST_STATUSES } from "Constants/APIResponseConstants";
import SamskipResource from "SamskipResource";
import { ArrayUtilities, WindowUtilities } from "Utilities";

declare var GlobalConfig: GlobalConfig;

class ShipmentService {
  private shippingTypeShortToLongMap: any = {
    F: "FCL",
    L: "LCL",
    M: "MTY",
    B: "BULK",
  };

  // Array to store booking actions list so that we don't have to get the
  // list from server each time we have to use it. The array is populated
  // the first time that 'filterBookingActions' is called.
  private allBookingActions?: string[];

  companyShipments = (
    dateFrom: string,
    dateTo: string,
    company: string
  ): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/partners/${company}/total`,
      { dateFrom, dateTo }
    );
  };

  allShipments = (dateFrom: string, dateTo: string): SamskipPromise<any[]> => {
    return new Promise<any[]>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<any[]>>(
        "SamskipAPI",
        `bookings`,
        { dateFrom, dateTo }
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    }) as SamskipPromise<any[]>;
  };

  employeeShipments = (
    dateFrom: string,
    dateTo: string,
    employee: string
  ): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/employee/${employee}`,
      { dateFrom, dateTo }
    );
  };

  getShipmentsList(
    customerGroup: string,
    dateFrom: string,
    dateTo: string,
    adID: string,
    partnerCode: string
  ): SamskipPromise<any[]> {
    switch (customerGroup) {
      case "S":
        return this.companyShipmentsList(dateFrom, dateTo, partnerCode);
      case "A":
        return this.allShipments(dateFrom, dateTo);
      default:
        return this.companyShipmentsList(dateFrom, dateTo, partnerCode);
    }
  }

  companyShipmentsList = (
    dateFrom: string,
    dateTo: string,
    company: string
  ): SamskipPromise<any[]> => {
    return new Promise<any[]>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<any[]>>(
        "SamskipAPI",
        `bookings/partners/${company}`,
        { dateFrom, dateTo }
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    }) as SamskipPromise<any[]>;
  };

  /**
   * Gets a list of all unconfirmed Doris bookings for a specific shipper
   * That are bookings with job_status less than 20
   * @param  {String}     partnerCode     Doris Partner Code
   * @return {Promise}                    Promise with list of unconfirmed bookings
   */
  getAllUnconfirmedBookings = (partnerCode: string): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/partners/${partnerCode}/unconfirmed`
    );
  };

  companySearch = (
    company: string,
    searchString: string,
    customerGroup: string,
    searchType: string,
    dateFrom: string,
    dateTo: string
  ): SamskipPromise<any[]> => {
    let requestUrl: string;
    switch (customerGroup) {
      case "S":
        requestUrl = `bookings/partners/${company}/search/${searchString}`;
        break;
      case "A":
        requestUrl = `bookings/search/${searchString}`;
        break;
      default:
        requestUrl = `bookings/partners/${company}/search/${searchString}`;
        break;
    }
    return new Promise<any[]>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<any[]>>(
        "SamskipAPI",
        requestUrl,
        { searchType, dateFrom, dateTo }
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    }) as SamskipPromise<any[]>;
  };

  bookingsInExcel(
    partnerCode: string,
    bookings: string[]
  ): SamskipPromise<Blob> {
    return SamskipResource.postBlob(
      "ShippingAPI",
      "bookings/doris/excel",
      undefined,
      bookings
    );
  }

  acceptBookingTerms(
    termsType: string,
    reference: string
  ): SamskipPromise<any[]> {
    return SamskipResource.post(
      "SamskipAPI",
      `terms/accept/${termsType}/?reference=${reference}`
    );
  }

  updateTemplateLastUsedBy(templateId: number): SamskipPromise<any[]> {
    return SamskipResource.put(
      "SamskipAPI",
      `templates/bookings/${templateId}/used`
    );
  }

  getBookingDocumentTypes(
    jobReference: string | string[],
    partnerCode: string
  ): SamskipPromise<any[]> {
    if (Array.isArray(jobReference) && jobReference.length > 1) {
      return SamskipResource.post(
        "ShippingAPI",
        `bookings/doris/partners/${partnerCode}/documenttypes`,
        undefined,
        jobReference
      );
    }

    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/${jobReference}/partners/${partnerCode}/documenttypes`
    );
  }

  getBookingActionsList = (): SamskipPromise<any[]> => {
    return SamskipResource.get("ShippingAPI", "requests/types", undefined);
  };

  getBookingActionsListAsync = (): SamskipPromise<any[]> => {
    return SamskipResource.get("ShippingAPI", "requests/types", undefined);
  };

  getBookingTerms = (termsType: string): SamskipPromise<any[]> => {
    return SamskipResource.get("SamskipAPI", `terms/${termsType}`);
  };

  getBookingDocumentTypesList = (): SamskipPromise<any[]> => {
    return SamskipResource.get("ShippingAPI", "requests/printtypes");
  };

  /**
   * Gets a list of all active quotes for the user's selected partner.
   * @param  {String}     partnerCode     Partner whose quotes to get.
   * @return {Promise}    Returns a promise, resolved with a list active
   *                      quotes.
   */
  getActiveQuotes = (partnerCode: string): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `quotes/doris/partners/${partnerCode}`
    );
  };

  /**
   * Get addresses for a booking
   *
   * @param  string JobReference Doris Job Reference
   * @return array  Array with address objects for a Doris booking
   */
  getAddressesForBooking = (jobReference: string): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/${jobReference}/addresses`
    );
  };

  /**
   * Get requests for a booking
   *
   * @param  string JobReference Doris Job Reference
   * @return array  Array with request objects for a Doris booking
   */
  getRequestsForBooking = (jobReference: string): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/${jobReference}/requests`
    );
  };

  /**
   * Checks if the booking has buyers consol
   * @param  string JobReference Doris Job Reference
   * @return Returns an indicator whether or not the booking is "Buyers Consol"
   */
  getIsBc = (jobReference: string): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<any>>(
        "SamskipAPI",
        `bookings/${jobReference}/isBc`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  /**
   * Get buyers consol data for a specific booking.
   * That is, the bookings that are connected to the booking in question
   * with regards to buyers consol
   *
   * @param  string JobReference Doris Job Reference
   * @return array  Array with request objects for a Doris booking
   */
  getBcList = (jobReference: string): Promise<any[]> => {
    return new Promise<any[]>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<any[]>>(
        "SamskipAPI",
        `bookings/${jobReference}/buyersConsol`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  /**
   * Get requests for a booking by type and job reference
   * @param {string} jobReference   Doris job reference (e.g. 'SIS155716')
   * @param {string} requestTypes    Doris request types
   * @param {string[]} excludedStatuses Doris statuses
   * @return {array}                Array with request objects for a Doris booking by type and job request
   */
  getRequestsForBookingByRequestTypes = (
    jobReference: string,
    requestTypes: string[],
    excludedStatuses: string[]
  ): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/${jobReference}/requests`,
      { requestTypes, excludedStatuses }
    );
  };

  /**
   * Get commodities with temperatures
   * @return {array} Array with commodities objects
   */
  getCommodities = (): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      "registry/commodities/temperatures"
    );
  };

  /**
   * Get containertypes
   * @return {array} Array with containertypes objects
   */
  getContainerTypes = (): SamskipPromise<any[]> => {
    return SamskipResource.get("ShippingAPI", "registry/containertypes/iso");
  };

  /**
   * Get departments
   * @return {array} Array with department info objects
   */
  getDepartments = (): SamskipPromise<any[]> => {
    return SamskipResource.get("ShippingAPI", "registry/departments");
  };

  /**
   * Get the departments that the user has access to
   * @return {array} Array with users departments
   */
  getUserDepartments = (
    userId: number,
    subsys: number
  ): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `registry/departments/${userId}/${subsys}`
    );
  };

  /**
   * Get a list of temperatures for specific commodity
   * @return {array} Array of temperatures
   */
  getTemperatures = (min: string, max: string): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `registry/commodities/temperatures/${min}/${max}`
    );
  };

  /**
   * Get a list of all Harmonized Systems (HS) codes.
   */
  getHSCodes = (): Promise<HSCode[]> => {
    return new Promise<HSCode[]>(async (resolve: any, reject: any) => {
      const res = await SamskipResource.get<APIResponse<HSCode[]>>(
        "SamskipAPI",
        "Commodities/tariff/hsCodes"
      );

      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  /**
   * Get info for a booking
   *
   * @param  string JobReference Doris Job Reference
   * @return BookingVM object with  properties for a Doris booking
   */
  getBookingVM = (jobReference: string): SamskipPromise<any> => {
    return SamskipResource.get("ShippingAPI", `bookings/doris/${jobReference}`);
  };

  /**
   * Copy a Doris booking
   * Returns a Booking ViewModel with fields reset that should not be copied
   *
   * @param  string JobReference Doris Job Reference
   * @return BookingVM object with properties for a copied Doris booking
   */
  copyBooking = (jobReference: string): SamskipPromise<any> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/${jobReference}/copy`
    );
  };

  /**
   * Copy a Doris quote
   * Returns a Booking ViewModel with fields reset that should not be copied
   *
   * @param  string quoteReference Doris Quote Reference
   * @return BookingVM object with properties for a copied Doris quote
   */
  copyQuote = (quoteReference: string): SamskipPromise<any> => {
    return SamskipResource.get(
      "ShippingAPI",
      `quotes/doris/${quoteReference}/copy`
    );
  };

  /**
   * Get remarks for a booking
   *
   * @param  string JobReference Doris Job Reference
   * @return array with bookingNote object for a Doris booking
   */
  getBookingRemarks = (jobReference: string): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/${jobReference}/remarks`
    );
  };

  /**
   * Get history for a single Doris booking.
   * @param  {string} jobReference    Doris job reference (e.g. 'SIS155716')
   * @return {array}                  Array of history-entry-objects
   */
  getHistoryForBooking = (jobReference: string): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/${jobReference}/history`
    );
  };

  /**
   * Get units for a single Doris booking.
   * @param  {string} jobReference    Doris job reference (e.g. 'SIS155716')
   * @return {array}                  Array of unit-entry-objects
   */
  getUnitsForBooking = (jobReference: string): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/${jobReference}/units`
    );
  };

  /**
   * Search for list of bookings
   * @param  {[type]} partnerCode  User partner code
   * @param  {[type]} searchBy     Type of search value
   * @param  {[type]} searchValues List of values to search for
   * @return {[type]}              List of bookings related to input parameters
   */
  searchBulkByList(
    partnerCode: string,
    searchBy: string,
    searchValues: string[]
  ): SamskipPromise<any[]> {
    return new Promise<any[]>(async (resolve, reject) => {
      const res = await SamskipResource.post<APIResponse<any[]>>(
        "SamskipAPI",
        `bookings/partners/${partnerCode}/bulksearch`,
        {
          type: searchBy,
        },
        searchValues
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    }) as SamskipPromise<any[]>;
  }

  /**
   * Get all invoices for a single Doris booking.
   * @param  {string} jobReference    Doris job reference (e.g. 'SIS155716')
   * @return {array}                  Array of invoices
   */
  getBookingInvoices = (jobReference: string): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/${jobReference}/invoices`
    );
  };

  /**
   * Get metadata for documents attached to a given booking number.
   * @param  {string} jobReference    Doris job reference (e.g. 'SIS155716')
   * @param  {string} type            Should the list be filtered to a specific type?
   * @return {array}                  Array of medadata for attached documents.
   */
  getBookingDocuments = (
    jobReference: string,
    type?: string
  ): SamskipPromise<any[]> => {
    const documentType = type ? type.toUpperCase() : null;
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/${jobReference}/documents`,
      { type: documentType }
    );
  };

  getBookingDocumentsBatch = (
    jobReferences: string[]
  ): SamskipPromise<any[]> => {
    return SamskipResource.post(
      "ShippingAPI",
      `bookings/doris/documents/batchGet`,
      undefined,
      jobReferences
    );
  };

  /**
   * Open a popup for a booking's invoices. Currently opens a popup showing
   * the invoices page from the old service web.
   * @param  {string} jobReference JobReference (booking number) to get invoices for
   */
  openBookingInvoices = (jobReference: string): void => {
    const popupUrl = [
      GlobalConfig.ENV.OldServiceWeb,
      `/BookingInvoices.aspx?Popup=1&BookNo=${jobReference}`,
    ].join("");

    WindowUtilities.openPopup(popupUrl, jobReference, 900, 900);
  };

  /**
   * Get metadata for documents attached to a given booking number that
   * should be visible in print menus. I.e. documents marked with the
   * 'SHOW_IN_MENUS' attribute.
   * @param  {string} jobReference    Doris job reference (e.g. 'SIS155716')
   * @return {promise}                Promise which resolves to an array of
   *                                          the medadata for attached documents.
   */
  getBookingPrintMenuDocuments = (jobReference: string): any => {
    return new Promise((resolve, reject) => {
      this.getBookingDocuments(jobReference)
        .then((data: any) => {
          const docsForPrintMenu = data
            .filter((it: any) => it.ShowInMenus === "1")
            .map((it: any) => {
              it.translate = `DOCTYPE_${it.Type}`;

              // Default icon, can be overwritten if a
              // different icon is preferred
              it._icon = "fa fa-print fa-fw";

              return it;
            });

          const sortedDocsForPrintMenu = ArrayUtilities.sortBy(
            docsForPrintMenu,
            "Description"
          );
          resolve(sortedDocsForPrintMenu);
        })
        .catch((data: any) => {
          throw new Error(data);
        });
    });
  };

  /**
   * Opens specific type of documents for few bookings.
   * @param  {string} type Document type (SARN, SBCN, SDEL, SBOL)
   * @param  {string} jobReferences String containing booking job references
   *                  seperated with comma (RTM123123,RTM321321)
   */
  openBookingDocuments(type: string, jobReferences: string): void {
    const docUrl = `${GlobalConfig.ENV.ShippingAPI}/documents/doris/?type=${type}&bookings=${jobReferences}`;

    WindowUtilities.openPopup(docUrl, jobReferences, 900, 900);
  }

  /**
   * Opens a specific Doris document attached to a given job in a popup.
   * @param  {int} documentID Doris document ID
   */
  openBookingDocument = (documentID: number, docType?: string): void => {
    const urlRoute =
      docType !== "SDC" ? "documents/doris" : "proxy/documents/file";
    const docUrl = `${GlobalConfig.ENV.ShippingAPI}/${urlRoute}/${documentID}`;

    WindowUtilities.openPopup(docUrl, documentID.toString(), 900, 900);
  };

  /**
   * Remove a specific Doris document attached to a given job.
   * @param  {int} documentID Doris document ID
   */
  removeBookingDocument = (documentID: number): SamskipPromise<any[]> => {
    return SamskipResource.delete(
      "ShippingAPI",
      `proxy/documents/file/${documentID}`
    );
  };

  /**
   * Get allowed actions for a Doris booking.
   * @param  {string}      jobReference
   * @return {promise}     Promise which resolves to an object with
   *                       allowed actions for a specific booking
   */
  getBookingAllowedActions(
    jobReference: string
  ): SamskipPromise<AllowedActions> {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/${jobReference}/allowedactions`
    );
  }

  /**
   * Get allowed actions for multiple Doris bookings
   * @param jobReferences {string[]} List of Doris job references
   */
  getMultipleBookingAllowedActions(
    jobReferences: string[]
  ): SamskipPromise<AllowedActions[]> {
    return SamskipResource.post(
      "ShippingAPI",
      "bookings/doris/allowedactions/batchGet",
      undefined,
      jobReferences
    );
  }

  /**
   * Create a web remark for a Doris booking
   * @param  {string} jobReference    Doris Job Reference (e.g. 'SIS155716')
   * @param  {object} remark          Remark object
   * @param  {object} swvisible       Visible object
   * @return {promise}                Promise
   */
  createRemark = (
    jobReference: string,
    remark: string,
    swvisible?: string
  ): SamskipPromise<any> => {
    return SamskipResource.post(
      "ShippingAPI",
      `bookings/doris/${jobReference}/remarks`,
      undefined,
      { remark: remark, swvisible: swvisible }
    );
  };

  /**
   * Update a web remark for a Doris booking
   * @param  {string} jobReference    Doris Job Reference (e.g. 'SIS155716')
   * @param  {string} remarkID        Remark ID (sequence), which remark we're updating
   * @param  {object} remark          Updated remark object
   * @return {promise}                Promise
   */
  editRemark(
    jobReference: string,
    remarkID: number,
    remark: any
  ): SamskipPromise<any> {
    return SamskipResource.put(
      "ShippingAPI",
      `bookings/doris/${jobReference}/remarks/${remarkID}`,
      undefined,
      remark
    );
  }

  /**
   * Wrapper method to update jobdates for multiple bookings
   * @param  {Array[string]} jobReferences    List of bookings
   * @param  {string} type                    Which date to update for each booking
   * @param  {Date|string} newDate            A valida datetime to set for each booking
   * @return {Promise}                        The promise to be resolved
   */
  private bulkUpdateJobDates = (
    jobReferences: string[],
    type: string,
    newDate: string
  ): SamskipPromise<any> => {
    if (Array.isArray(jobReferences) && jobReferences.length > 0) {
      return SamskipResource.put(
        "ShippingAPI",
        "bookings/doris/dates",
        {
          newDate,
          dateType: type,
        },
        jobReferences
      );
    }
    throw new Error("jobReferences must be a non-empty array");
  };

  /**
   * Update BLSurrenderDate for multiple bookings at a time.
   * @param  {Array[string]} jobReferences    List of the bookings to update BLSurrenderDate for
   * @param  {Date|string} newDate            A valid BLSurrenderDate to set for each booking
   * @return {Promise}                        A promise to be resolved
   */
  updateBLSurrenderDate(
    jobReferences: string[],
    newDate: string
  ): SamskipPromise<any> {
    return this.bulkUpdateJobDates(jobReferences, "BLSurrenderDate", newDate);
  }

  /**
   * Update the customs-clearance date for multiple bookings at a time.
   * @param  {Array[string]} jobReferences    List of the bookings to update
   *                                          customs-clearance date for
   * @param  {Date|string} newDate            A valid customs-clearance date
   *                                          to set for each booking
   * @return {Promise}                        A promise to be resolved
   */
  updateCustomsClearanceDate = (
    jobReferences: string[],
    newDate: string
  ): SamskipPromise<any> => {
    return this.bulkUpdateJobDates(
      jobReferences,
      "CustomsClearanceDate",
      newDate
    );
  };

  /**
   * Create booking remarks for many bookings.
   * @param  {array}     jobReferences    JobReference list
   * @param  {string}     text            string to set remark for each jobReference
   * @return {Promise}                    The promise to be resolved
   */
  updateBookingsRemark = (
    jobReferences: string[],
    text: string
  ): SamskipPromise<any> => {
    if (Array.isArray(jobReferences) && jobReferences.length > 0) {
      return SamskipResource.post(
        "ShippingAPI",
        "bookings/doris/remarks",
        undefined,
        {
          Bookings: jobReferences,
          Remark: text,
        }
      );
    }
    throw new Error("jobReferences must be a non-empty array");
  };

  excelBookingInfo = (
    jobReference: string,
    reportType: string
  ): SamskipPromise<Blob> => {
    return SamskipResource.getBlob(
      "ShippingAPI",
      `bookings/doris/${jobReference}/${reportType}/excel`
    );
  };

  statusText = (status: number | string, realStatus?: boolean): string => {
    let resolvedStatus = Number(status);
    if (realStatus) {
      resolvedStatus = this.statusToTileStatus(resolvedStatus);
    }
    switch (resolvedStatus) {
      case 0:
      case 1:
        return "REQUESTTYPE_STATUS_BOOKED"; // status 1
      case 2:
        return "REQUESTTYPE_STATUS_RECEIVED_IN_POL"; // status 5
      case 3:
        return "REQUESTTYPE_STATUS_ONBOARD_OCEAN_GOING_VESSEL"; // status 20
      case 4:
        return "REQUESTTYPE_STATUS_DISCHARGED_IN_POD"; // status 55
      case 5:
        return "REQUESTTYPE_STATUS_READY_TO_BE_DELIVERED"; // status 60
      case 6:
        return "REQUESTTYPE_STATUS_DELIVERED"; // status 80
      case 9:
        return "REQUESTTYPE_STATUS_CANCELLED"; // status 99
      default:
        return "REQUESTTYPE_STATUS_BOOKED"; // status 1
    }
  };

  workflowStatusText = (status: number | string): string => {
    switch (Number(status)) {
      case 0:
      case 1:
        return "REQUESTTYPE_STATUS_BOOKED";
      case 2:
        return "REQUESTTYPE_STATUS_COLLECTION_REGISTERED";
      case 3:
        return "REQUESTTYPE_STATUS_COLLECTION_UNALLOCATED";
      case 4:
        return "REQUESTTYPE_STATUS_COLLECTION_IN_PROGRESS";
      case 5:
        return "REQUESTTYPE_STATUS_RECEIVED_IN_POL";
      case 15:
        return "REQUESTTYPE_STATUS_READY_TO_LOAD";
      case 20:
        return "REQUESTTYPE_STATUS_ONBOARD_OCEAN_GOING_VESSEL";
      case 55:
        return "REQUESTTYPE_STATUS_DISCHARGED_IN_POD";
      case 58:
        return "REQUESTTYPE_STATUS_WAREHOUSE_PROCESSING";
      case 60:
        return "REQUESTTYPE_STATUS_READY_TO_BE_DELIVERED";
      case 65:
        return "REQUESTTYPE_STATUS_DELIVERY_IN_PROGRESS";
      case 80:
        return "REQUESTTYPE_STATUS_DELIVERED";
      case 99:
        return "REQUESTTYPE_STATUS_CANCELLED";
      default:
        return "REQUESTTYPE_STATUS_BOOKED";
    }
  };

  statusClass = (tileStatus: number): string => {
    switch (tileStatus) {
      case 0:
      case 1:
        return "registered";
      case 2:
        return "load";
      case 3:
        return "transporting";
      case 4:
        return "disch";
      case 5:
        return "ready";
      case 6:
        return "delivered";
      default:
        return "";
    }
  };

  // Convert Status to tilestatus
  statusToTileStatus = (status: number): number => {
    let result;
    if (status === 1) result = 1;
    else if (status === 5) result = 2;
    else if (status === 20) result = 3;
    else if (status === 55) result = 4;
    else if (status === 60) result = 5;
    else if (status === 80) result = 6;
    else if (status === 99) result = 9;
    else throw new Error("No translation");
    return result;
  };

  toggleFavorite = (
    userId: number,
    jobReference: string
  ): SamskipPromise<any> => {
    return SamskipResource.put(
      "ShippingAPI",
      `bookings/doris/users/${userId}/favorites/${jobReference}`
    );
  };

  /**
   * Builds a url-query for the old service web
   * @param  {string} jobReference    a job reference
   * @param  {object} actionObject action to invoke in the old web
   */
  buildBookingActionQueryUrl = (jobReference: string, action: any): string => {
    /* globals GlobalConfig */
    const oldWebUrl = `${GlobalConfig.ENV.OldServiceWeb}/`;

    /**
     * Resolves conflicts that happen when the old service web functions expect different
     * parameters than what we would consider normal
     */
    const resolveParameter = (actionType: any): string => {
      const delimeter = "&";
      const assignment = "=";
      const defaultParameter = "BookNo";
      const handlers = {
        // Customs clearance report
        40: function type40() {
          return "";
        },
        // Claim report
        80: function type80() {
          return `${delimeter}BookingNo${assignment}${jobReference}`;
        },
      };
      if (handlers[actionType.TypeID]) {
        return handlers[actionType.TypeID]();
      }
      return `${delimeter}${defaultParameter}${assignment}${jobReference}`;
    };

    return [oldWebUrl, action.Site, resolveParameter(action)].join("");
  };

  /**
   * getFilteredBookingActions filters the actions applicable on the given booking to
   * determine which actions should be displayed in the dropdown
   * @param  {object} bookingObj      The booking that we want to filter actions for
   * @return {promise}                Returns a promise object that gets resolved
   *                                     when the complete non-filtered list of
   *                                     booking actions has been retrieved.
   */
  getFilteredBookingActions = (bookingObj: any, employee?: boolean): any => {
    return new Promise((resolve: Function, reject: Function) => {
      if (!bookingObj) {
        console.error('Argument "bookingObj" missing');
      }

      /**
       * Checks that a booking is within the range specified by the
       * booking action-type.
       * @return {bool}         true if the booking's status falls within
       *                             the actions's specified range.
       */
      const bookingStatusInRange = (booking: any, action: any): boolean => {
        return (
          booking.Status >= action.RangeFrom && booking.Status <= action.RangeTo
        );
      };

      /**
       * Checks that a booking's mode of transportation and the action are compatible
       * Returns true if the booking's transportation mode is FCL and action is FCL
       * or if the transportation mode is LCL and action is for LCL Insert Action
       * @return {boolean}
       */
      const bookingStatusAndActionMatch = (
        booking: any,
        action: any
      ): boolean => {
        const shippingTypePair = this.getShippingTypePair(booking.ShippingType);

        const likeFCL =
          shippingTypePair.second === "F" || shippingTypePair.second === "M";
        const isLCL = shippingTypePair.second === "L";
        return (likeFCL && action.ForFCL) || (isLCL && action.ForLCL);
      };

      // Booking objects for the single booking page and shipment list pages differ a little bit so we need to check for load/disch values in both objects
      const bookingExportImportMatch = (booking: any, action: any): boolean => {
        const isExport = booking.POL
          ? this.isLocalPort(booking.POL.PointCode)
          : booking.PointLoad
          ? this.isLocalPort(booking.PointLoad)
          : false;
        const isImport = booking.POD
          ? this.isLocalPort(booking.POD.PointCode)
          : booking.PointDisch
          ? this.isLocalPort(booking.PointDisch)
          : false;
        return (
          (isImport && action.ForImport === "1") ||
          (isExport && action.ForExport === "1")
        );
      };

      /**
       * The actual implementation of the filtering
       * @param  {object} booking The booking that we're filtering actions for
       * @return {array}          Array containing filtered actions, applicable
       *                               to the given booking.
       */
      const actualFilter = (booking: any): any[] => {
        const filteredActions: any = [];
        if (!this.allBookingActions) return [];

        // Filter
        this.allBookingActions.forEach((it: any) => {
          if (
            it.ForFreight &&
            it.ForFreight === "1" &&
            bookingStatusInRange(booking, it) &&
            bookingStatusAndActionMatch(booking, it) &&
            bookingExportImportMatch(booking, it)
          ) {
            it.translate = it.Key;

            // Action is either explicitly open to customers or user is employee
            if (
              (it.ForCustomer !== 0 || employee === true) &&
              !isFilterCurrentBooking(booking, it)
            ) {
              filteredActions.push(it);
            }
          }
        });
        return filteredActions;
      };

      /**
       * Check if current booking should be filtered
       * @param  {object} booking     [current booking]
       * @return {boolean}            [true if item should be filtered]
       */
      const isFilterCurrentBooking = (
        booking: any,
        bookingAction: any
      ): boolean => {
        const shippingTypePair = this.getShippingTypePair(booking.ShippingType);
        // If LCL booking, then don't display actions that
        // contains ForFCL : 0
        if (shippingTypePair.second === "L") {
          if (bookingAction.ForLCL === "1") {
            return false;
          }
        } else if (shippingTypePair.second === "F") {
          if (bookingAction.ForFCL === "1") {
            return false;
          }
        }
        return true;
      };

      // Check that 'allBookingActions' has been initialized,
      // and initialize if it has not.
      if (!this.allBookingActions) {
        this.getBookingActionsList().then((data: any) => {
          this.allBookingActions = data;
          resolve(actualFilter(bookingObj));
        });
      } else {
        resolve(actualFilter(bookingObj));
      }
    });
  };

  /**
   * Get a list of user's favorite bookings (list of Doris Job References)
   * @param  {int} userID     User ID
   * @return {promise}        Promise with list of Doris Job Reference
   */
  getUserFavoriteBookings = (
    userID: number
  ): SamskipPromise<UserFavorite[]> => {
    return SamskipResource.get<UserFavorite[]>(
      "ShippingAPI",
      `bookings/doris/users/${userID}/favorites`
    );
  };

  /**
   * Get a list of user's favorite bookings (list of Doris booking objects)
   * @param  {int} userID     User ID
   * @return {promise}        Promise with list of Doris booking objects
   */
  getUserFavoriteBookingsFull = (userID: number): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/users/${userID}/favorites/full`
    );
  };

  /**
   * Create new IFTMBF booking in Doris.
   * @param  {object}     bookingVM   Booking view model
   * @return {promise}                Promise with job reference
   */
  createBooking = (bookingVM: any): SamskipPromise<any> => {
    return SamskipResource.post(
      "ShippingAPI",
      "bookings/doris/create",
      undefined,
      bookingVM
    );
  };

  /**
   * Update IFTMBF booking in Doris.
   * @param  {object}     bookingVM   Booking view model
   * @param  {string}     jobRef      Job reference of current booking
   * @return {promise}                Promise with job reference
   */
  updateBooking = (bookingVM: any, jobRef: string): SamskipPromise<any> => {
    return SamskipResource.put(
      "ShippingAPI",
      `bookings/doris/create/${jobRef}`,
      undefined,
      bookingVM
    );
  };

  /**
   * Create IFTMIN booking in Doris.
   * @param  {object}     bookingVM   Booking view model
   * @return {promise}                Promise with job reference
   */
  addBookingInstructions = (bookingVM: any): SamskipPromise<any> => {
    return SamskipResource.post(
      "ShippingAPI",
      "bookings/doris/create/instructions",
      undefined,
      bookingVM
    );
  };

  /**
   * Parses a shipping-type to an object with its first and second modes
   * of transport (FCL, LCL or MTY).
   * @param  {string} shippingType    A valid shipping-type string using
   *                                  the Doris-notation;
   *                                  "L L", "F L", "F F", "M M", etc.
   *                                  Matches "L-L", "L L", "F F", "M M", etc.
   *                                  (spaced or hyphenated)
   * @return {Object}                 Returns the first and second modes
   *                                  of transport as an object using the
   *                                  standard notation.
   */
  getShippingTypePair = (shippingType: string): ShippingType => {
    if (!shippingType) return new ShippingType();

    const shortFormRegexp = /^([FLMB])([\s\-])([FLMB])$/;
    const match = shortFormRegexp.exec(shippingType);
    if (
      !match ||
      !match[1] ||
      !match[3] ||
      !shortFormRegexp.test(shippingType)
    ) {
      return new ShippingType();
    }

    return new ShippingType(match[1], match[3]);
  };

  /**
   * Converts a short Doris shipping type notation to a long version
   * For example conversion:
   *  F F -> FCL-FCL
   *  F   -> FCL
   * @param  {string} shippingType    A valid shipping-type string using
   *                                  the Doris-notation;
   *                                  "L L", "F L", "F F", "M M", etc.
   *                                  Also possible to use a single character.
   * @return {string}                 Returns the new shipping type in a
   *                                  long format
   */
  convertShippingTypeToLongFormat = (shippingType: string): string => {
    const shippingTypeObj = this.getShippingTypePair(shippingType);
    if (!shippingTypeObj.first || !shippingTypeObj.second) return "";

    const arr = [shippingTypeObj.first, shippingTypeObj.second];
    const shippingTypes = arr.map((item) => {
      return this.shippingTypeShortToLongMap[item];
    });

    return shippingTypes.join("-");
  };

  /**
   * Converts a short Doris BOL type notation to a long version
   * For example conversion:
   *  W -> SWB
   * @param  {string} bolType         A valid BOL type string using
   *                                  the Doris-notation;
   *                                  G, O, M
   * @return {string}                 Returns the new BOL type in a
   *                                  long format
   */
  convertBOLTypeToLongFormat = (bolType: string): string => {
    const bolTypeMap = {
      G: "B/L",
      W: "SWB",
      M: "Memo",
    };

    return bolTypeMap[bolType];
  };

  /**
   * Get Doris partner's draft bookings
   * @param  {string} partnerCode     Partner code to search by
   * @return {Array[objects]}         Booking objects
   */
  getDraftBookingsByPartnerCode = (
    partnerCode: string
  ): SamskipPromise<any[]> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/partners/${partnerCode}/drafts`
    );
  };

  /**
   * Get booking object from RethinkDB by DraftId
   * @param  {string}     draftId     [DraftId related to certain BookingVM]
   * @return {Promise}                [A promise with BookingVM object]
   */
  getDraftBooking = (draftId: string): SamskipPromise<any> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/drafts/${draftId}`
    );
  };

  /**
   * Save booking object to RethinkDB
   * @param  {object}     bookingVM   [Booking Object]
   * @return {Promise}                [A promise with result info]
   */
  saveDraft = (bookingVM: any): SamskipPromise<any> => {
    return SamskipResource.post(
      "ShippingAPI",
      "bookings/doris/drafts",
      undefined,
      bookingVM
    );
  };

  /**
   * Delete booking object in RethinkDB
   * @param  {object}     draftId     Draft id
   * @return {Promise}                A promise with result info
   */
  deleteDraft = (draftId: string): SamskipPromise<any> => {
    return SamskipResource.delete(
      "ShippingAPI",
      `bookings/doris/drafts/${draftId}`
    );
  };

  /**
   * Delete all unusable drafts that are included in 'draftIds' parameter.
   * @param {array|string}    draftIds    Array of Draft ids
   * @return {Promise}                    Result object containing AffectedId
   *                              which indicates which ids were deleted/tried
   *                              to delete. Also contains DbResult object that
   *                              contains result info for each AffectedId.
   */
  deleteUnusableDrafts = (draftIds: string[]): SamskipPromise<any> => {
    return SamskipResource.deleteJSON(
      "ShippingAPI",
      "bookings/doris/drafts",
      undefined,
      draftIds
    );
  };

  /*
   * Get the VGM-information for a given booking/shipment by its jobReference
   * @param  {string} jobReference The booking's jobReference
   * @return {Promise}              A promise wich resolves with the VGM-info
   *                                  for the booking.
   */
  getVgmInfo = (jobReference: string): SamskipPromise<any> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/${jobReference}/vgm`
    );
  };

  getVgmCutOffTime = (
    req: any
  ): Promise<ExposedBookingsGetVGMCutOffTimeRes> => {
    return new Promise(async (resolve, reject) => {
      const res = await SamskipResource.post<
        APIResponse<ExposedBookingsGetVGMCutOffTimeRes>
      >("SamskipAPI", `bookings/GetVgmCutOffTime`, {}, req);
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        resolve();
      }
    });
  };

  getVgmCutOffTimeJobRef = (
    req: any
  ): Promise<ExposedBookingsGetVGMCutOffTimeRes> => {
    return new Promise(async (resolve, reject) => {
      const res = await SamskipResource.post<
        APIResponse<ExposedBookingsGetVGMCutOffTimeRes>
      >("SamskipAPI", `bookings/GetVgmCutOffTimeJobRef`, {}, req);
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        resolve();
      }
    });
  };

  /**
   * Updates the VGM-information for a given booking/shipment.
   * @param  {string} jobReference    The shipment's jobreference
   * @param  {Array} items            Array of updated VGM-items
   * @return {Promise}                A promise which resolves to either a
   *                                    success or error-response
   */
  updateVgmInfo = (jobReference: string, items: any[]): SamskipPromise<any> => {
    return SamskipResource.put(
      "ShippingAPI",
      `bookings/doris/${jobReference}/vgm`,
      undefined,
      items
    );
  };

  /**
   * Negotiates the proper way of displaying a booking's voyagereference.
   * @param  {Object} booking A "booking" object (booking/draft/favorite)
   * @return {String}         Returns a proper representation of the
   *                          booking's voyagereference.
   */
  displayVoyageReference = (booking: any): string => {
    let result = booking.LoadVoyageReference;
    if (
      booking.DischVoyageReference &&
      booking.LoadVoyageReference !== booking.DischVoyageReference
    ) {
      result += ` - ${booking.DischVoyageReference}`;
    }
    return result;
  };

  /**
   * Creates a to-from string for a booking depending on which points
   * (PLR, POL, POD, PFD) are defined for the boooking.
   * @param  {Object} item    A booking viewmodel.
   * @return {String}         Returns the formatted string.
   */
  formatToFrom = (item: any): string | undefined => {
    let pointFrom;
    let pointTo;

    if (item.PointFrom && item.PointFromISOName) {
      pointFrom = item.PointFromISOName;
    } else if (item.PointLoadISOName) {
      pointFrom = item.PointLoadISOName;
    } else if (item.PLR && item.PLR.FullName) {
      pointFrom = item.PLR.FullName;
    } else if (item.POL && item.POL.FullName) {
      pointFrom = item.POL.FullName;
    }

    if (item.PointTo && item.PointToISOName) {
      pointTo = item.PointToISOName;
    } else if (item.PointDischISOName) {
      pointTo = item.PointDischISOName;
    } else if (item.PFD && item.PFD.FullName) {
      pointTo = item.PFD.FullName;
    } else if (item.POD && item.POD.FullName) {
      pointTo = item.POD.FullName;
    }

    if (pointFrom || pointTo) {
      return `${pointFrom || "??"} - ${pointTo || "??"}`;
    }

    return undefined;
  };

  createVoyageList(shipment: any): Voyage[] {
    const voyages: Voyage[] = [];
    if (
      shipment.JobVoyageReference &&
      !shipment.LoadVoyageReference &&
      !shipment.DischVoyageReference
    ) {
      voyages.push({
        Reference: shipment.JobVoyageReference,
        End: shipment.DateArrival,
      } as Voyage);
    }
    if (shipment.LoadVoyageReference) {
      voyages.push({
        Reference: shipment.LoadVoyageReference,
        End: shipment.LoadVoyageArrivalDate,
      } as Voyage);
    }
    if (
      shipment.DischVoyageReference &&
      shipment.DischVoyageReference !== shipment.LoadVoyageReference
    ) {
      voyages.push({
        Reference: shipment.DischVoyageReference,
        End: shipment.DischVoyageArrivalDate,
      } as Voyage);
    }
    return voyages;
  }

  isLocalPort(pointCode: string): boolean {
    return pointCode.startsWith("IS");
  }

  /**
   * Create new drive request in Doris.
   * @param  {object}     requestVM   Request view model
   * @param  {object}     units       Array of unit items
   * @return {promise}                Promise
   */
  createRequests = (requestVM: any, units: any[]) => {
    return SamskipResource.post("ShippingAPI", "requests", undefined, {
      requestVM,
      unitVMs: units,
    });
  };

  /**
   * Returns the latest requestid for a given container number and partner code
   * @param  {string}     containerNumber Container number
   * @param  {string}     partnerCode     Doris Partner Code
   * @return {string}                     Latest request id
   */
  getLatestRequestIDForContainer = (
    containerNumber: string,
    partnerCode: string
  ) => {
    return SamskipResource.get(
      "ShippingAPI",
      `requests/${partnerCode}/${containerNumber}/latest`
    );
  };

  /**
   * Returns true if Col address edit is allowed for booking
   */
  allowedColEdit = (jobReference: string): SamskipPromise<boolean> => {
    return SamskipResource.get(
      "ShippingAPI",
      `bookings/doris/${jobReference}/allowedColEdit`
    );
  };

  /**
   *
   * @param templateId
   * Fetch a BookingTemplate from the templateId
   * Will return a view-model containing a booking template
   */

  getBookingVMFromTemplate = (templateId: number): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const res = await SamskipResource.get<APIResponse<any>>(
          "SamskipAPI",
          `templates/${templateId}/view-model`
        );
        if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
          resolve(res.ReturnItem.Data);
        } else {
          reject();
        }
      } catch {
        reject();
      }
    });
  };

  getBookingVMFromQuote = (quoteReference: string): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<any>>(
        "SamskipAPI",
        `quotes/${quoteReference}/view-model`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };

  getQuoteCount = (): Promise<QuoteCount> => {
    return new Promise<QuoteCount>(async (resolve, reject) => {
      const res = await SamskipResource.get<APIResponse<QuoteCount>>(
        "SamskipAPI",
        `quotes/count`
      );
      if (res && res.RequestStatus === API_REQUEST_STATUSES.Ok) {
        resolve(res.ReturnItem.Data);
      } else {
        reject();
      }
    });
  };
}

class ShippingType {
  first?: string;
  second?: string;

  constructor(first?: string, second?: string) {
    this.first = first;
    this.second = second;
  }
}

export default new ShipmentService();
