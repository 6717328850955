import * as React from "react";
import * as ReactDOM from "react-dom";
import * as DebounceInput from "react-debounce-input";
import { reaction } from "mobx";
import { Overlay, Popover } from "react-bootstrap";
import { $uiRouter } from "Bootstrap/angular";

import { UserService } from "Services";
import { languages } from "Services/TranslationService";
import CompanyModal from "Masters/header/companyModal/CompanyModal";
import GeneralLoader from "Utilities/GeneralLoader";

export default class CompanyPicker extends React.Component<
  CompanyPickerProps,
  CompanyPickerState
> {
  state: CompanyPickerState = {
    companies: UserService.getCompanies(),
    loading: false,
    toggle: false,
    target: undefined,
  };

  toggle(e?: React.MouseEvent<HTMLElement>) {
    const target = e ? e.target : this.state.target;
    this.setState({
      target,
      loading: false,
      companies: UserService.getCompanies(),
      toggle: !this.state.toggle,
    });
  }
  search(e: any) {
    this.setState({ loading: true });

    UserService.searchUserCompanies(e.target.value)
      .then((c) =>
        this.setState({
          companies: c,
          loading: false,
        })
      )
      .catch(() =>
        this.setState({
          companies: [],
          loading: false,
        })
      );
  }
  openCompany() {
    GeneralLoader.increase();
    CompanyModal.openModal(this.props.company)
      .opened.then(() => this.toggle())
      .then(() => {
        GeneralLoader.decrease();
      });
  }
  switchCompany(company: Company) {
    UserService.switchCompany(company).then(() => {
      this.toggle();
      $uiRouter.stateService.reload();
    });
  }
  getResults() {
    const header = this.state.loading ? (
      <i className="fa fa-spinner fa-spin"></i>
    ) : (
      <span>Færslur: {this.state.companies.length}</span>
    );
    const items = this.state.companies.map((i: Company) => {
      return (
        <a
          href="#"
          className="list-group-item"
          key={i.PartnerCode}
          onClick={() => this.switchCompany(i)}
        >
          {i.FullName}{" "}
          <span className="u-floatRight">
            {i.PartnerCode}{" "}
            <span>{`${i.Currency ? `(${i.Currency})` : ""}`}</span>
          </span>
        </a>
      );
    });

    return (
      <div className="CompanyPicker-searchResults list-group CompanySearch-Container">
        <a href="#" className="list-group-item list-head">
          {" "}
          {languages("TEXT_NAME")} <span>{header}</span>{" "}
        </a>
        <div className="CompanyPicker-searchResults-companyList">{items}</div>
      </div>
    );
  }
  onKey(event: any) {
    // Only Enter and company exists
    if (event.keyCode !== 13) return;

    event.preventDefault();

    if (!this.state.companies[0]) return;

    this.switchCompany(this.state.companies[0]);
  }
  getSearch() {
    return (
      <div className="CompanyPicker-searchBody">
        <form className="CompanyPicker-searchBody-form form-inline">
          <DebounceInput
            className="form-control search-query"
            minLength={2}
            debounceTimeout={800}
            onKeyDown={(e: any) => this.onKey(e)}
            onChange={(e: any) => this.search(e)}
            placeholder={languages("LABEL_COMPANYSEARCH_INPUT")}
          />
          <span className="u-cursorPointer"></span>
        </form>
      </div>
    );
  }
  render() {
    const { company, user } = this.props;
    const isEmployee = (user ? user.Access.UserType : "") !== "C";
    const isActive = this.state.loading || !!this.state.companies.length;

    return (
      <div className="CompanyPicker-wrapper">
        <span
          className="MainHeader-UserInfo-companyName"
          onClick={(e: any) => {
            this.toggle(e);
          }}
        >
          ( {company.FullName}
          {company.Currency ? ` (${company.Currency})` : ""}{" "}
          <i className="fa fa-caret-down"></i> )
        </span>
        <Overlay
          placement="bottom"
          show={this.state.toggle}
          target={this.state.target}
          rootClose={true}
          onHide={(e: any) => this.state.toggle && this.toggle()}
        >
          <Popover id="CompanyPicker">
            <div className="CompanyPicker-title">
              <div className="row">
                <div className="col-sm-6">
                  <h3>{languages("LABEL_COMPANYSEARCH")}</h3>
                </div>
                <div className="col-sm-6 u-textRight">
                  <span onClick={() => this.openCompany()}>
                    {languages("LABEL_VIEWCURRENTCOMPANY")}
                  </span>
                </div>
              </div>
            </div>
            <div className="CompanyPicker-body">
              {isEmployee && this.getSearch()}
              {isActive && this.getResults()}
            </div>
          </Popover>
        </Overlay>
      </div>
    );
  }
}
