import * as React from "react";
import * as ReactDOM from "react-dom";
import { Row, Col, Button } from "react-bootstrap";

import styles from "./index.modules.less";

export default class extends React.Component<MenuTileProps, {}> {
  menu: HTMLDivElement | any;
  state = { hidden: true };
  constructor(props: MenuTileProps) {
    super(props);

    this.onOpenMenu = this.onOpenMenu.bind(this);
    this.onCloseMenu = this.onCloseMenu.bind(this);
  }
  onOpenMenu(e: React.MouseEvent<Button>) {
    e.preventDefault();

    this.setState({ hidden: false }, () =>
      document.addEventListener("click", this.onCloseMenu)
    );
  }
  onCloseMenu(e: any) {
    if (!this.menu.contains(e.target)) {
      this.setState({ hidden: true }, () =>
        document.removeEventListener("click", this.onCloseMenu)
      );
    }
  }
  render() {
    const { hidden } = this.state;
    const { items } = this.props;
    const isActive = items && items.length;

    return (
      <div className={styles.menu}>
        <Button
          bsSize="xs"
          disabled={!isActive}
          onClick={e => this.onOpenMenu(e)}
        >
          <i className="fa fa-bars"></i>
        </Button>
        <ul
          className={`${styles.dropdown} ${hidden && styles.hidden}`}
          ref={e => (this.menu = e)}
        >
          {isActive &&
            items &&
            items.map((c, i) => (
              <li key={i} onClick={() => c.onClick && c.onClick()}>
                {c.label}
              </li>
            ))}
        </ul>
      </div>
    );
  }
}
