import { Set, Map } from "es6-shim";

import * as _ from "lodash";

class ArrayUtilities {
  /**
   * Checks that an array only contains non-empty strings.
   * @param  {Array} array    Array to check.
   * @return {Boolean}        Returns true if each element in the array is
   *                          a non-empty string.
   */
  onlyContainsNonEmptyStrings = (
    array: (string | null | undefined)[]
  ): boolean => {
    if (!array) {
      return false;
    }
    return array.every((it: any) => {
      return it && typeof it === "string";
    });
  };

  /**
   * Checks that an array only contains at least N non-empty strings.
   * @param  {Array}  array    Array to check.
   * @param  {Number} N        How many non-empty strings the array
   *                           must contain.
   * @return {Boolean}         Returns true if array contains at least
   *                           N non-empty strings.
   */
  containsAtLeastNonEmptyStrings = (array: string[], N: number): boolean => {
    // Get only elements that are a non-empty string
    const nonEmptyStringsArray = array.filter((it: any) => {
      return it && typeof it === "string";
    });

    // Check if the array fulfills the
    // required amount of non-empty strings
    return nonEmptyStringsArray.length >= N;
  };

  /**
   * Some handy functional ES6/ES7 array methods below
   */

  copy = (array: any[]): any[] => {
    return array.slice(0);
  };

  combine = (...arrays: any[]): any[] => {
    return [].concat(...arrays);
  };

  compact = (array: any[]): any[] => {
    return array.filter(Boolean);
  };

  includes = (array: any, value: any): boolean => {
    return (<any>Array).prototype.includes
      ? array.includes(value)
      : array.some((el: any) => el === value);
  };

  difference = (array: any[], ...others: any[]): any[] => {
    const combined = [].concat(...others);
    return array.filter(
      (el: any) => !combined.some((exclude) => el === exclude)
    );
  };

  intersect = (...arrays: any[]): any[] => {
    const set: any = new Set([].concat(...arrays));
    return Array.from(set).filter((toFind) =>
      arrays.every((arr) => arr.some((el: any) => el === toFind))
    );
  };

  union = (...arrays: any[]): any[] => {
    const set: any = new Set([].concat(...arrays));
    return [...set];
  };

  unique = (array: any[]): any[] => {
    const set: any = new Set(array);
    return [...set];
  };

  /**
   * Make collection unique by a property or a function
   * @param array Collection
   * @param prop Property to make collection unique
   */
  uniqueBy = (array: any, prop: string): any[] => {
    if (!Array.isArray(array)) return array;

    const arrayReversed = array.slice().reverse();
    return arrayReversed.filter((e: any, i: number) => {
      return arrayReversed.findIndex((a) => a[prop] === e[prop]) === i;
    });
  };

  without = (array: any[], ...values: any[]): any[] => {
    return array.filter((el) => !values.some((exclude) => el === exclude));
  };

  /**
   * Sort a collection by a property or a function
   * @param array Collection to be sorted
   * @param prop Property or function to sort by
   */
  sortBy = (array: any[], prop: string | Function): any[] => {
    if (!array || array.length === 0) return array;

    return array.sort((a, b) => {
      const aVal = typeof prop === "function" ? prop(a) : a[prop];
      const bVal = typeof prop === "function" ? prop(b) : b[prop];

      if (typeof aVal === "string" && typeof bVal === "string") {
        return aVal.localeCompare(bVal);
      }

      return aVal - bVal;
    });
  };

  /**
   * Sum a collection by a property
   * @param array Collection
   * @param prop Property to sum by
   */
  sumBy = (array: any[], prop: string): number => {
    if (!Array.isArray(array)) return 0;

    const sum = array
      .filter((item: any) => Number.isFinite(item[prop]))
      .map((item: any) => item[prop])
      .reduce((sum: number, value: number) => sum + value, 0);

    return sum;
  };

  /**
   * Count a collection by a property or a function
   * @param array Collection
   * @param prop Property or function to count collection by
   */
  countBy = (array: any, prop: string | Function): {} => {
    if (!Array.isArray(array)) return array;

    const mapArray: any = array.map((o: any) => {
      let key: any;
      let countFn: (i: any) => boolean;
      if (typeof prop === "function") {
        key = prop(o);
        countFn = (i: any) => prop(i) === key;
      } else {
        key = o[prop];
        countFn = (i: any) => i[prop] === key;
      }

      return [key, array.filter(countFn).length];
    });

    const newObj = {};

    new Map(mapArray).forEach((v: any, k: any) => {
      newObj[k] = v;
    });

    return newObj;
  };

  /**
   * Aggregate collection based on a key/property
   * @param array Collection
   * @param prop Property or function to aggregate on
   */
  keyBy = (array: any, prop: string): {} | undefined => {
    if (!Array.isArray(array)) return array;

    const mapArray: any = array
      .filter((i) => i.hasOwnProperty(prop))
      .map((o: any) => {
        return [o[prop], o];
      });

    if (mapArray.length === 0) return undefined;

    const values: any = new Map(mapArray);
    const newObj = {};
    values.forEach((v: any, k: any) => {
      newObj[k] = v;
    });

    return newObj;
  };

  isEmptyArray = (arr: any[]) => {
    if (Array.isArray(arr)) {
      if (arr.length) {
        return false;
      }
    }
    return true;
  };

  /**
   * Boolean function that checks if value exist in an array.
   * Not case sensitive for string.
   * @param array         Array to search in
   * @param value         Value to search for (can be object)
   * @param propertyName  Name of an object property in the array
   *                          to search for
   */
  existInArray = (array: any[], value: any, propertyName?: string): boolean => {
    if (!Array.isArray(array)) return false;
    if (!value) return false;

    if (propertyName) {
      return this.propertyExistInArray(array, value, propertyName);
    }

    if (typeof value === "string" || value instanceof String) {
      return array.some((v: any) => v.toUpperCase() === value.toUpperCase());
    }

    return array.some((v: any) => v === value);
  };

  private propertyExistInArray = (
    array: any[],
    value: any,
    propertyName: string
  ): boolean => {
    if (typeof value === "object" || value instanceof Object) {
      return array.some(
        (v: any) => v[propertyName] && v[propertyName] === value[propertyName]
      );
    }
    return array.some((v: any) => v[propertyName] && v[propertyName] === value);
  };

  toggleValueInArray = (array: any[], value: any): any[] => {
    if (_.includes(array, value)) {
      _.pull(array, value);
    } else {
      array.push(value);
    }
    return array;
  };

  /**
   * Searches for the first object in a list based on a value of a property.
   * @param list The list of objects
   * @param value The value based on we can identify the searched object
   * @param propertyName The property that contains the value
   * @returns {T | undefined} If found in the list returns the full object
   */
  getObject = <T>(
    list: T[],
    value: string | number,
    propertyName: string
  ): T | undefined => {
    return list.find((element) => element[propertyName] === value);
  };
}

export default new ArrayUtilities();
