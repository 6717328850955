import * as React from "react";
import * as ReactDOM from "react-dom";
import { Row, Col, Button } from "react-bootstrap";

import Menu from "./Menu";
import styles from "./index.modules.less";

export default (props: TileProps) => (
  <div className={`${styles.tile} ${styles[props.size]}`}>
    <Row className={styles.upper}>
      {props.column.upper.map(c => (
        <Col className={styles.col} key={c.title} xs={c.xs} sm={c.sm} md={c.md}>
          <span>{c.title}</span>
          {c.value}
          <a href={`/containers/${c.link}`}>{c.link}</a>
          {!(!c.value && !c.link) || "-"}
        </Col>
      ))}
      <Menu items={props.column.menu} />
    </Row>
    <Row className={styles.lower}>
      {props.column.lower.map(c => (
        <Col className={styles.col} key={c.title} xs={c.xs} sm={c.sm} md={c.md}>
          <span>{c.title}</span>
          {c.value}
          <a href={`/shipments/${c.link}`}>{c.link}</a>
          {!(!c.value && !c.link) || "-"}
          <span className={styles.subtitle}>{c.subtitle}</span>
        </Col>
      ))}
    </Row>
  </div>
);
