import React from "react";
import {
  Modal as SemanticUIModal,
  ModalContent,
  ModalHeader,
  ModalProps as SemanticUIModalProps
} from "semantic-ui-react";
import styles from "./index.modules.less";

interface ModalProps extends SemanticUIModalProps {}

const Modal = (props: ModalProps) => {
  const { header, content, additionalStyles, ...rest } = props;
  return (
    <SemanticUIModal
      className={`${styles.modal} ${styles.shared} ${additionalStyles}`}
      size={rest.size}
      open={rest.open}
      onClose={rest.onClose}
      {...rest}
    >
      <ModalHeader>{header}</ModalHeader>
      <ModalContent>{content}</ModalContent>
    </SemanticUIModal>
  );
};

export default Modal;
