import { observable, computed, action } from "mobx";

class RouteStore {
  @observable private currentStateValue?: string = undefined;
  @observable private previousStateValue?: string = undefined;
  @observable private inStateTransitionValue: boolean = false;

  @computed get currentState(): string | undefined {
    return this.currentStateValue;
  }

  @computed get previousState(): string | undefined {
    return this.previousStateValue;
  }

  @computed get isInStateTransition(): boolean {
    return this.inStateTransitionValue;
  }

  @action
  setCurrentState = (stateName: string): void => {
    this.currentStateValue = stateName;
  };

  @action
  setPreviousState = (stateName: string): void => {
    this.previousStateValue = stateName;
  };

  @action
  inStateTransition = (value: boolean): void => {
    this.inStateTransitionValue = value;
  };
}

export default new RouteStore();
