import { autorun, extendObservable, toJS } from "mobx";

export function persist(name: string) {
  return function(target: any) {
    const original = target;

    function construct(constructor: any, args: any) {
      const c: any = function() {
        return constructor.apply(this, args);
      };

      c.prototype = constructor.prototype;

      const instance = new c();
      const key = `Samskip_${name}`;

      extendObservable(instance, JSON.parse(localStorage.getItem(key) || "{}"));
      autorun(() => localStorage.setItem(key, JSON.stringify(toJS(instance))));

      return instance;
    }

    const f: any = function(...args: any[]) {
      return construct(original, args);
    };

    f.prototype = original.prototype;

    return f;
  };
}
