exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index-modules__labels___3Q_zV {\n  display: inline-block;\n  vertical-align: top;\n}\n.index-modules__labels___3Q_zV .index-modules__label___3LOdg {\n  position: relative;\n  display: inline-block;\n  margin: 5px 0 1px 5px;\n  padding: 3px 5px;\n  border: 1px solid #ccd3da;\n  max-width: 100%;\n  border-radius: 1px;\n  background-color: #f6f7f9;\n  background-size: 100% 19px;\n  background-repeat: repeat-x;\n  background-clip: padding-box;\n  box-shadow: inset 0 0 2px #fff, 0 1px 0 rgba(0, 0, 0, 0.05);\n  color: #748998;\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 12px;\n  cursor: default;\n}\n.index-modules__labels___3Q_zV .index-modules__label___3LOdg i {\n  display: inline-block;\n  position: relative;\n  top: 0;\n  height: 8px;\n  width: 8px;\n  margin-left: 5px;\n  background-size: 8px 16px;\n  cursor: pointer;\n}\n.index-modules__labels___3Q_zV .index-modules__label___3LOdg i:hover {\n  background-position: 0 -8px !important;\n}\n.index-modules__labels___3Q_zV .index-modules__clear___3QN3f {\n  display: inline-block;\n  position: relative;\n  top: 3px;\n  height: 14px;\n  width: 65px;\n  margin-left: 5px;\n  background-size: 14px 28px;\n  cursor: pointer;\n}\n.index-modules__labels___3Q_zV .index-modules__clear___3QN3f:hover {\n  background-position: 0 -14px !important;\n}\n.index-modules__labels___3Q_zV .index-modules__clear___3QN3f span {\n  position: relative;\n  left: 16px;\n  top: -4px;\n  font-size: 11px;\n  color: #748998;\n  text-transform: uppercase;\n}\n", ""]);

// exports
exports.locals = {
	"labels": "index-modules__labels___3Q_zV",
	"label": "index-modules__label___3LOdg",
	"clear": "index-modules__clear___3QN3f"
};